import { useState, useEffect } from "react";
import fetchPartDetails from "../../../utils/graphql/fetches/fetchPartDetails";

const PartCard = ({ partId }) => {
  const [item, setItem] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchPartDetails(partId);
        setItem(response.data.part[0]);
      } catch (error) {
        console.error("Error fetching part data:", error);
      }
    };
    fetchData();
  }, [partId]);

  if (!item) {
    return <></>;
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{item.name}</h5>
        <p className="card-text">
          <strong>ID:</strong> {item.id} <br />
          <small className="text-muted">
            <strong>Descrição:</strong> {item.description}
          </small>{" "}
          <br />
          <small className="text-muted">
            <strong>Apelido:</strong> {item.nickname}
          </small>{" "}
          <br />
          <small className="text-muted">
            <strong>Data:</strong> {item.date}
          </small>{" "}
          <br />
          <small className="text-muted">
            <strong>Versão:</strong> {item.version}
          </small>{" "}
          <br />
          <small className="text-muted">
            <strong>Tipo:</strong> {item.Type.category} - {item.Type.type} -{" "}
            {item.Type.subType}
          </small>
          <br />
          <small className="text-muted">
            <strong>Unidade:</strong> {item.Unit.name} ({item.Unit.symbol})
          </small>
          <br />
          <small className="text-muted">
            <strong>Fornecimento:</strong> {item.Supply.supply}
          </small>
          <br />
        </p>
      </div>
    </div>
  );
};

export default PartCard;
