import { useState, useEffect } from "react";
import UuidText from "../Text/UuidText";
import UserTypeHead from "../TypeHead/UserTypeHead";
import BomPartIdTypeHead from "../TypeHead/BomPartIdTypeHead";
import BomInfo from "../../Output/BomInfo/BomInfo";
import fetchItemPart from "../../../utils/graphql/fetches/fetchItemPart";
import testRequired from "./utils/testRequired";

const EventManufacturingForm = ({ onSubmit, itemId }) => {
  const [resetForm, setResetForm] = useState(false);
  const [part, setPart] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    bomId: "",
    creatorId: "",
    itemId: itemId,
  });

  const clearForm = () => {
    setFormData({
      id: "",
      bomId: "",
      creatorId: "",
      itemId: itemId,
    });
    setResetForm(!resetForm);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchItemPart(itemId);
        setPart(response.data.part);
      } catch (e) {
        setPart();
      }
    };
    fetchData();
  }, [itemId]);

  const onChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const innerOnSubmit = (e) => {
    e.preventDefault();
    if (testRequired(formData)) {
      alert("Por favor, Preencha todos os campos.");
      return;
    }
    onSubmit(formData);
    clearForm();
  };

  return (
    <form onSubmit={innerOnSubmit}>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre o Evento</h2>
        <div className="mb-3">
          <UuidText
            id="id"
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label className="form-label"> Responsável: </label>
          <UserTypeHead
            typeHeadOptions={{id: "creatorId", placeholder: "Quem é o responsável por esse evento."}}
            changeToClearSelection={resetForm}
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="partId" className="form-label">
            Peça:
          </label>
          <input
            name={"partId"}
            className="form-control"
            placeholder={part.name}
            disabled={true}
          />
        </div>
        <label htmlFor="eventCreatorId" className="form-label">
            UUID do Item:
          </label>
          <input
            name={"id"}
            className="form-control"
            placeholder={itemId}
            disabled={true}
          />
        <div className="mb-3">
          <label className="form-label"> Bom: </label>
          <BomPartIdTypeHead
            typeHeadOptions={{
              id: "bomId",
              placeholder: "Escolha a BOM do utilziada no evento",
            }}
            fetchOptionsArguments={{ partId: part.id }}
            changeToClearSelection={resetForm}
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
          />
        </div>
        <div className="mb-3">
          <BomInfo bomId={formData.bomId} sideBySide={false} />
        </div>
        <div className="mt-5 row">
          <button type="submit" className="btn btn-primary btn-lg">
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
};

export default EventManufacturingForm;
