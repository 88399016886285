import React from 'react';
import BomTable from './BomTable';

const MultipleBomTables = ({ multipleBomStructures }) => {
  if (!multipleBomStructures || !Array.isArray(multipleBomStructures)) return null;
  
  const lastIndex = multipleBomStructures.length - 1;
  
  return (
    <>
      <div className="p-3 rounded bg-light">
        <h2 id="results-header">BOMs</h2>
        {multipleBomStructures.map((bomStructure, index) => (
          <div key={index}>
            <BomTable bomSingleTableData={bomStructure} />
            {index !== lastIndex && <hr />}
          </div>
        ))}
      </div> 
    </>
  );
};

export default MultipleBomTables;
