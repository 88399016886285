import React, { useState, useEffect } from "react";
import getItemHistory from "../../../utils/graphql/controllers/getItemHistory";

const ItemStatusCard = ({ itemId, nRecords }) => {
  const [history, setHistory] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getItemHistory(itemId, nRecords);
        setHistory(response.data.Histories);
      } catch (error) {
        console.error("Error fetching history data:", error);
      }
    };
    fetchData();
  }, [itemId, nRecords]);

  if (!history) {
    return <></>;
  }

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (timeString) => {
    if (!timeString) {
      return "";
    }
    const options = { hour: "numeric", minute: "numeric", second: "numeric" };
    return new Date(timeString).toLocaleTimeString(undefined, options);
  };

  const formatDateTime = (dateString) => {
    if (!dateString) {
      return "";
    }
    const date = formatDate(dateString);
    const time = formatTime(dateString);
    return date + " as " + time;
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Histórico Recente do Item</h5>
      </div>
      <div className="card-body">
        {history.map((record, index) => (
          <div key={index} className="row">
            <div className="col-md-6">
              <p className="card-text">
                <strong>Comentário:</strong> {record.comment} <br />
                <strong>Data e Hora:</strong> {formatDateTime(record.date)} <br />
                <strong>Ator:</strong> {record.Person.firstName}{" "}
                {record.Person.lastName} <br />
                <strong>Status:</strong> {record.Status.name} <br />
              </p>
            </div>
            {/* Divider */}
            {index !== history.length - 1 && <hr />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemStatusCard;
