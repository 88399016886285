import config from '../../config/config';
import fetchController from '../fetchController';

// Function to fetch data from GraphQL API
async function getItemLastAssembly(itemId, like) {
  try {
    // initialize variables
    const variables = {itemId: itemId}

    // Make a GET request
    const response = await fetchController(variables, config.graphQLServerHost, config.getItemLastAssemblyUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller getItemLastAssemblyUrl:', error);
  }
}

export default getItemLastAssembly;
  