import { useState } from "react";
import UuidText from "./UuidText";
import CameraModal from "../Modal/CameraModal";
import ErrorOutput from "../../Output/ErrorOutput";

const UuidTextQrCode = ({
  id,
  onChange,
  changeToClearSelection,
  label = "UUID:",
}) => {
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uuidValue, setUuuidValue] = useState("");

  const innerOnChange = (e) => {
    setError(null);
    setUuuidValue(e.value);
    onChange(e);
  };

  return (
    <>
      <div className="row">
        <div className="col-9">
          <UuidText
            id={id}
            onChange={innerOnChange}
            changeToClearSelection={changeToClearSelection}
            label={label}
            setUuuidValue={uuidValue}
          />
        </div>
        <div className="mx-3 col-2 d-flex align-items-end">
          <button
            type="button"
            className="btn btn-info"
            onClick={() => {
              setShowModal(true);
            }}
          >
            QRCode
          </button>
        </div>
      </div>

      <CameraModal
        id={id + "CameraModal"}
        onError={(e) => setError(e.message || e || "Ocorreu um erro na leitura da QRCode")}
        onScan={(e) => {
          setShowModal(false);
          setUuuidValue(e.value);
          innerOnChange({name: id, value: e.value});
        }}
        showModal={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        title={"Posicione o QRCode do Item na Tela"}
      />

      <div className="mb-3"/>

      <ErrorOutput error={error} />

    </>
  );
};

export default UuidTextQrCode;
