import config from '../../config/config';
import fetchController from '../fetchController';

// Function to fetch data
async function backupData() {
  try {
    // Make a GET request
    const response = await fetchController({}, config.graphQLServerHost, config.backupDataUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller backupData:', error);
  }
}

export default backupData;
