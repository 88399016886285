import config from '../../../config/config';
import postController from '../../postController';

// Function to fetch data from GraphQL API
async function createEvent(eventInput) {
  try {
    // initialize variables
    const variables = eventInput

    // Make a post request
    const response = await postController(variables, config.graphQLServerHost, config.createEventUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller createEvent:', error.message);
    throw(error)
  }
} 

export default createEvent;
