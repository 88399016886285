import TypeHeadMultiSelectionGeneric from "./TypeHeadMultiSelectionGeneric";
import TypeHeadFetchOptions from "../TypeHead/TypeHeadFetchOptions";

const TypeHeadMultiSelection = ({
  name,
  placeholder,
  table,
  labelColumn,
  valueColumn,
  auxiliarColumn = "",
  separator = " - ",
  idSize = 6,
  columns,
  onChange,
  changeToClearSelection,
}) => {

  return (
    <TypeHeadMultiSelectionGeneric
      name={name}
      fetchOptionsFunction={TypeHeadFetchOptions}
      fetchOptionsArguments={{table: table, labelColumn: labelColumn, valueColumn: valueColumn, auxiliarColumn: auxiliarColumn, separator: separator, idSize: idSize}}
      typeHeadOptions={{id: name, placeholder: placeholder}}
      columns={columns}
      onChange={onChange}
      changeToClearSelection={changeToClearSelection}
    />
  );
};

export default TypeHeadMultiSelection;
