import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Head from "../../../../../../components/Header.js";
import Navbar from "../../../../../../components/NavBar.js";
import Footer from "../../../../../../components/Footer.js";
import ItemManufacturingForm from "../../../../../../components/Input/Forms/ItemManufacturingForm";
import CreateItemForInput from "../../../../../../utils/graphql/crud/item/createItemForInput";
import ErrorOutput from "../../../../../../components/Output/ErrorOutput.js";

const CreateItemManufacturingPage = () => {
  const navigate = useNavigate();
  const { personId, eventId, partId, quantityIndex } = useParams();
  const [error, setError] = useState(null);

  const onSubmit = async (input) => {
    setError(null);
    try {
      const variables = {
        itemId: input["id"],
        itemDescription: input["description"],
        itemBomId: input["eventBomId"],
        partId: input["partId"],
        personId: input["eventCreatorId"],
        itemHistoryId: input["historyId"],
        itemManufacturingEventId: input["eventId"],
        itemConsumptionEventId: eventId,
        itemConsumptionQuantityIndex: quantityIndex,
      };

      await CreateItemForInput(variables);

      const nextPageUrl = `/usage/manufacturing/selectItem/selectevent/selecteventmanufacturing/${input.id}`;
      navigate(nextPageUrl);
    } catch (error) {
      setError(error.message || "Ocorreu um erro");
    }
  };

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div>
          <h1>Inserir Item</h1>
        </div>
        <ItemManufacturingForm
          onSubmit={onSubmit}
          urlPartId={partId}
          urlPersonId={personId}
        />
        <ErrorOutput error={error} />
      </div>
      <Footer />
    </div>
  );
};

export default CreateItemManufacturingPage;
