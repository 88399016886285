import React, { useState } from 'react';
import FileSelect from './utils/FileSelect'; // Replace with your component path
import FileInterpreter from './utils/FileInterpreter'; // Replace with your component path

const FileInput = ({label, accept, handleFileSelection}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  return (
    <>
      <FileSelect
        id="fileInput"
        label={label}
        accept={accept}
        onFileSelect={handleFileSelect}
      />
      <hr />
      <h3>Resultado:</h3>
      <FileInterpreter selectedFile={selectedFile} onFileContentChange={handleFileSelection} />
    </>
  );
};

export default FileInput;
