import React, { useState, useEffect } from "react";
import fetchItemDetails from "../../../utils/graphql/fetches/fetchItemDetails";

const ItemCardDetailed = ({ itemId }) => {
  const [item, setItem] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchItemDetails(itemId);
        setItem(response.data.item[0]);
      } catch (error) {
        console.error("Error fetching part data:", error);
      }
    };
    fetchData();
  }, [itemId]);

  if (!item) {
    return <></>;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">{item.Part.name}</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <p className="card-text">
              <strong>ID:</strong> {item.Part.id} <br />
              <strong>Descrição:</strong> {item.Part.description} <br />
              <strong>Apelido:</strong> {item.Part.nickname} <br />
              <strong>Data:</strong> {item.Part.date} <br />
              <strong>Versão:</strong> {item.Part.version} <br />
              <strong>Tipo:</strong> {item.Part.Type.category} - {item.Part.Type.type} - {item.Part.Type.subType} <br />
              <strong>Unidade:</strong> {item.Part.Unit.name} ({item.Part.Unit.symbol}) <br />
              <strong>Fornecimento:</strong> {item.Part.Supply.supply} <br />
            </p>
          </div>
          <div className="col-md-6">
            <p className="card-text">
              <strong>ID do Item:</strong> {item.id} <br />
              <strong>Descrição do Item:</strong> {item.description} <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemCardDetailed;
