import { useState } from "react";
import Head from "../../../components/Header";
import Navbar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import ErrorOutput from "../../../components/Output/ErrorOutput";
import SuccessOutput from "../../../components/Output/SuccessOutput";
import ProtectedPage from "../../../components/PageElements/directAcess/ProtectedPage";

import "../../../styles/homepage.css";
import backupData from "../../../utils/graphql/controllers/backupData";

const BackupPage = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleBackup = async () => {
    setError(null);
    try {
      const result = await backupData();
      if (result && result.data) {
        setSuccess("Back up criado com sucesso");
      } else {
        throw error;
      }
    } catch (error) {
      setError(error.message || "Ocorreu um erro");
    }
  };

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div style={{ height: "70vh" }} className="container fill-height">
        <div className="text-center mt-4">
          <h1>WR4.0 - Sua interface de produção</h1>
        </div>
        <div className="h-75"></div>
        <div className="mt-3 mb-3 rounded bg-light p-4">
          <div className="text-center">
            <h3>O backup será feito in loco no servidor do banco de dados.</h3>
          </div>
          <div className="mt-3 text-center btn-container">
            <button className="btn btn-danger btn-lg" onClick={handleBackup}>
              Faça o Backup
            </button>
          </div>
          <div className="m-3" />
          <ErrorOutput error={error} />
          <SuccessOutput success={success} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

// Wrap MyProtectedPage with withPasswordProtection to protect it.
const ProtectedMyProtectedPage = ProtectedPage(BackupPage);

export default ProtectedMyProtectedPage;