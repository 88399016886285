import React, { useState, useEffect } from "react";
import ErrorOutput from "../../../Output/ErrorOutput";
import SuccessOutput from "../../../Output/SuccessOutput";

const FileInterpreter = ({ selectedFile, onFileContentChange }) => {
  const [error, setError] = useState(null);
  const [jsonData, setJsonData] = useState(null);

  const parseCSVToJSON = (csvContent) => {
    const lines = csvContent.split(/\r?\n/);

    const headers = lines[0].split(";");
    const jsonData = [];

    for (let i = 1; i < lines.length; i++) {
      const data = lines[i].split(";");
      if (data.length === 1 && data[0] === "") {
        continue; // Skip empty rows
      }

      const entry = {};

      for (let j = 0; j < headers.length; j++) {
        const header = headers[j];
        let value = data[j] ? data[j].trim() : ""; // Handle empty cells

        // Attempt to convert to number
        const numericValue = Number(value);
        if (!isNaN(numericValue)) {
          value = numericValue;
        }

        // Attempt to convert to boolean
        if (typeof value === "string") {
          if (
            value.toLowerCase() === "true" ||
            value.toLowerCase() === "false"
          ) {
            value = value.toLowerCase() === "true";
            entry[header] = value;
            continue;
          }

          // Attempt to convert to array
          if (value.startsWith("[") && value.endsWith("]")) {
            try {
              // Try to parse the value as a JSON array
              value = JSON.parse(value);
            } catch (error) {
              // If parsing fails, treat it as a comma-separated array
              value = value
                .substring(1, value.length - 1)
                .split(",")
                .map((item) => item.trim());
            }
          }
        }
        entry[header] = value;
      }

      jsonData.push(entry);
    }

    return jsonData;
  };

  const handleFileRead = (event) => {
    setError(null);
    const content = event.target.result;

    // Basic CSV check - if there are line breaks indicating separate rows, it's likely a CSV
    const isCSV = content.includes("\n");

    if (!isCSV) {
      setError("Arquivo não apresenta a formatação desejada");
      setJsonData(null);
      onFileContentChange(null, "Arquivo não apresenta a formatação desejada");
      return;
    }

    const parsedData = parseCSVToJSON(content);
    setJsonData(parsedData);
    setError(null);
    onFileContentChange(parsedData);
  };

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = handleFileRead;
      reader.readAsText(selectedFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  if (!selectedFile) {
    return <div>Nenhum arquivo selecionado</div>;
  }

  return (
    <>
      <ErrorOutput error={error} />
      {!error && jsonData && (
        <SuccessOutput success={JSON.stringify(jsonData, null, 2)} />
      )}
      {!error && !jsonData && <div>Interpreting file...</div>}
    </>
  );
};

export default FileInterpreter;
