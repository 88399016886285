import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchInputDetails = async (inputId) => {
  try {
    const query = `{
      input(id: "${inputId}") {
        id
        quantityIndex
        Item {
          id
          description
          Part {
            id
            name
            description
            Unit {
              id
              symbol
            }
          }
        }
      }
    }`;

    const response = await fetchGraphQLQuery(query);
    return response;
  } catch(error) {
    console.error('Error fetching input details:', error);
  }
};

export default fetchInputDetails;
