import { useState } from "react";

import fetchGraphQLQuery from "../../../utils/graphql/fetchGraphQLQuery";

const QueryForm = ({ onSubmit }) => {
  const [query, setQuery] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetchGraphQLQuery(query);
    onSubmit(response);
  };

  return (
    <div className="mb-4">
      <form id="query-form" className="row g-3" onSubmit={handleSubmit}>
        <div className="col-12">
          <textarea
            className="form-control"
            id="query"
            name="query"
            rows="10"
            placeholder="Insira sua Query em GraphQL aqui:"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          ></textarea>
        </div>
        <div>
          <button type="submit" className="btn btn-primary ">
            Enviar Query
          </button>
        </div>
      </form>
    </div>
  );
};

export default QueryForm;
