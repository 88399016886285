import { useState, useEffect } from "react";
import fetchEventInputs from "../../../../../../utils/graphql/fetches/fetchEventInputs";
import initiateEvent from "../../../../../../utils/graphql/controllers/initiateEvent";
import endEvent from "../../../../../../utils/graphql/controllers/endEvent";
import ErrorOutput from "../../../../../Output/ErrorOutput";
import ManufacturingBodyExecutionElement from "./ManufacturingBodyExecutionElement";
import config from "../../../../../../utils/config/config";

const ManufacturingBodyExecutionTab = ({ eventId, personId, eventStateId }) => {
  const [error, setError] = useState(null);
  const [eventInputs, setEventInputs] = useState(null);
  const [newHistoryData, setNewHistoryData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchEventInputs(eventId);
        setEventInputs(response.data.event[0].Inputs);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };
    fetchData();
  }, [eventId]);

  const initEventOnClick = async () => {
    try {
      if (personId === null || personId === "") {
        setError("Selecione quem você é.");
        return;
      }
      const response = await initiateEvent({
        eventId: eventId,
        personId: personId,
      });
      if (response.status.code === 200) {
        window.location.reload();
      }
    } catch (error) {
      setError(error.message || "Ocorreu um erro");
    }
  };

  const endEventOnClick = async () => {
    try {
      if (personId === null || personId === "") {
        setError("Selecione quem você é.");
        return;
      }
      const response = await endEvent({
        eventId: eventId,
        personId: personId,
        newHistoryData: newHistoryData,
      });
      if (response.status.code === 200) {
        window.location.reload();
      }
    } catch (error) {
      setError(error.message || "Ocorreu um erro");
    }
  };

  const onInputChange = async (e) => {
    setNewHistoryData((prevNewHistoryData) => ({
      ...prevNewHistoryData,
      [e.target.inputId]: e.target.value,
    }));
  };

  if (!eventInputs) {
    return <></>;
  }

  return (
    <div className="container mt-3 mb-3">
      <h1> Execução do Evento </h1>
      <div className="container mt-3 mb-3">
        <strong>Instruções: </strong>
        <br></br>
        Esta aba irá iniciar o seu evento, certifique-se de que a aba de
        entradas e a aba de saída estão devidamente preenchidas com todos os
        itens os itens relacionados a este evento.
        <br></br>
        <br></br>
        <strong>O que irá acontecer ao Iniciar o Evento?</strong>
        <br></br>
        Ao decidir iniciar este evento todos os itens de entrada e saída terão
        seu status atualizado e serão travados para o seu uso, não esqueça de
        finalizar o evento quando terminar o processo.
        <br></br>
        Lembre-se de checar os documentos relacionados para executar o evento
        corretamente como recomendado pelas diretivas do grupo.
        <br></br>
        <br></br>
        <strong>O que irá acontecer ao Finalizar o Evento?</strong>
        <br></br>
        Ao decidir finalizar este evento todos os itens de entrada e saída terão
        novamente seu status atualizado. Lembre-se de marcar aqueles que não são
        consumidos durante o processo para que o sistema saiba quais fazem parte
        da nova montagem e quais retornarão ao estoque.
        <br></br>
        Uma pré seleção baseada na grandeza de unidade das peças foi
        selecionado, verifique-se de ela esta correta.
      </div>

      {eventStateId === config.fixedIds.state.idle && (
        <div>
          <button
            type={"button"}
            onClick={initEventOnClick}
            className="btn btn-lg btn-success mb-2 md-2 w-100"
          >
            Iniciar Evento
          </button>
        </div>
      )}

      {eventStateId === config.fixedIds.state.executing && (
        <div>
          <div>
            {eventInputs.map((input) => {
              return (
                <div className="m-3" key={input.id + ".div"}>
                  <ManufacturingBodyExecutionElement
                    inputId={input.id}
                    onChange={onInputChange}
                  />
                </div>
              );
            })}
          </div>

          <button
            type={"button"}
            onClick={endEventOnClick}
            className="btn btn-lg btn-success mb-2 md-2 w-100"
          >
            Finalizar Evento
          </button>
        </div>
      )}

      <ErrorOutput error={error} />
    </div>
  );
};

export default ManufacturingBodyExecutionTab;
