import { useState } from "react";
import Head from "../../../components/Header";
import Navbar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import PersonForm from "../../../components/Input/Forms/PersonForm";
import ErrorOutput from "../../../components/Output/ErrorOutput";
import SuccessOutput from "../../../components/Output/SuccessOutput";
import CreatePerson from "../../../utils/graphql/crud/person/create";

const CreatePersonPage = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const onSubmit = async (input) => {
    setError(null);
    setSuccess(null);
    try {
      const response = await CreatePerson(input);
      setSuccess(`Pessoa: ${response.data.id} criada.`);
    } catch (error) {
      setError(error.message || "Ocorreu um erro");
    }
  };

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="m-5 text-center">
          <h1>Inserir Pessoa</h1>
        </div>
        <PersonForm onSubmit={onSubmit} />
        <ErrorOutput error={error} />
        <SuccessOutput success={success} />
      </div>
      <Footer />
    </div>
  );
};

export default CreatePersonPage;
