import React, { useState } from "react";
import UuidText from "../Text/UuidText";
import UuidTextQrCode from "../Text/UuidTextQrCode";
import TypeHead from "../TypeHead/TypeHead";
import NextValidStatusTypeHead from "../TypeHead/NextValidStatusTypeHead";
import InputSelector from "../../PageElements/crud/item/create/InputSelector";
import testRequired from "./utils/testRequired";
import BomSelector from "../../PageElements/crud/item/create/BomSelector";

const ItemForm = ({ onSubmit }) => {
  const [resetForm, setResetForm] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    description: "",
    partId: "",
    eventId: "",
    eventInitDate: "",
    eventEndDate: "",
    createEmptyBom: true,
    eventBomId: "",
    eventCreatorId: "",
    eventActorId: "",
    inputItemId: [],
    historyId: "",
    historyStatusId: "",
    historyDate: "",
    historyPersonId: "",
    historyComment: "",
  });

  const clearForm = () => {
    setFormData({
      id: "",
      description: "",
      partId: "",
      eventId: "",
      eventInitDate: "",
      eventEndDate: "",
      createEmptyBom: true,
      eventBomId: "",
      eventCreatorId: "",
      eventActorId: "",
      inputItemId: [],
      historyId: "",
      historyStatusId: "",
      historyDate: "",
      historyPersonId: "",
      historyComment: "",
    });
    setResetForm(!resetForm);
  };

  const onChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const innerOnSubmit = (e) => {
    e.preventDefault();
    let notRequired = [
      "description",
      "createEmptyBom",
      "inputItemId",
      "historyComment",
    ];
    if (formData.createEmptyBom) {
      notRequired = [
        "description",
        "createEmptyBom",
        "eventBomId",
        "inputItemId",
        "historyComment",
      ];
    }
    if (testRequired(formData, notRequired)) {
      alert("Por favor, preencha todos os campos.");
      return;
    }
    onSubmit(formData);
    clearForm();
  };

  return (
    <form onSubmit={innerOnSubmit}>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre o Item</h2>
        <div className="mb-3">
          <strong>Intruções:</strong>
          <p>
            Itens são objetos físicos que devem ser associados a sua
            representação conceitual (projetos, produtos, etc) para que saibamos
            como caracterizar este objeto.
          </p>
        </div>
        <div className="mb-3">
          <UuidTextQrCode
            id="id"
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Descrição:
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            placeholder="Descreva os atributos que sejam específicos deste item, não do projeto/produto, como: 'pintado de vermelho', 'Peça reserva', 'Item de reposição'"
            value={formData.description}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label"> Produto: </label>
          <TypeHead
            name={"partId"}
            placeholder={"Qual produto representa seu item"}
            table={"part"}
            labelColumn={"name"}
            valueColumn={"id"}
            onChange={(e) => onChange(e.id, e.value)}
            auxiliarColumn={"description"}
            changeToClearSelection={resetForm}
          />
        </div>
      </div>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre o Histórico</h2>
        <div className="mb-3">
          <strong>Intruções:</strong>
          <p>
            Todo item físico possui um histórico, este histórico representa as
            diversas situações pelas quais passou tais como; 'Uso',
            'Manutençao', 'Estoque', 'Consumido' e etc.
          </p>
          <p>
            Como este item não existe no banco de dados iremos também criar a
            primeira entrada em seu histórico.
          </p>
        </div>
        <div className="mb-3">
          <UuidText
            label={"ID da entrada no Histórico"}
            id="historyId"
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label className="form-label"> Estado: </label>
          <NextValidStatusTypeHead
            typeHeadOptions={{
              id: "historyStatusId",
              placeholder: "Em qual estado se encontra este item.",
            }}
            fetchOptionsArguments={{ itemId: formData.id }}
            changeToClearSelection={resetForm}
            onChange={(e) => onChange(e.id, e.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label"> Responsável: </label>
          <TypeHead
            name={"historyPersonId"}
            placeholder={"Quem é o responsável pela Última troca de estado."}
            table={"person"}
            labelColumn={"firstName"}
            valueColumn={"id"}
            onChange={(e) => onChange(e.id, e.value)}
            auxiliarColumn={"lastName"}
            separator={" "}
            idSize={0}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label>Data de ínicio do Estado: </label>
          <input
            type="datetime-local"
            className="form-control"
            id="date"
            name="historyDate"
            value={formData.historyDate}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="historyComment" className="form-label">
            Descrição:
          </label>
          <textarea
            className="form-control"
            id="historyComment"
            name="historyComment"
            placeholder="Descreva Particularidade relativas a última troca de estado, i.e 'Item Será utilizado na feira da FEPUSP 2020'"
            value={formData.historyComment}
            onChange={(e) => onChange(e.target.id, e.target.value)}
          />
        </div>
      </div>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre o Evento</h2>
        <div className="mb-3">
          <strong>Intruções:</strong>
          <p>
            Todo item físico é oriundo de um evento. Este evento é a instância
            de uma BOM com seu determinado processo, esse evento pode ser tanto
            uma simples compra como pode ser uma montagem complexa.
          </p>
          <p>
            Caso esteja inserindo um item, sem nenhum tipo de rastreio anterior
            saiba que evento inicial do seu item será dado como finalizado, você
            pode ainda definir quem o executou e quando ocorreu.
          </p>
          <p>
            Caso esteja criando um item que ainda não foi fabricado dedique-se a
            página{" "}
            <a href="/usage/manufacturing/selectItem/createitemmanufacturing">
              'Criação de Item Para Fabricação'
            </a>
          </p>
        </div>
        <div className="mb-3">
          <UuidText
            id="eventId"
            label={"ID do Evento"}
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label className="form-label"> Responsável: </label>
          <TypeHead
            name={"eventCreatorId"}
            placeholder={"Quem é o responsável por esse evento."}
            table={"person"}
            labelColumn={"firstName"}
            valueColumn={"id"}
            onChange={(e) => onChange(e.id, e.value)}
            auxiliarColumn={"lastName"}
            separator={" "}
            idSize={0}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label className="form-label"> Ator: </label>
          <TypeHead
            name={"eventActorId"}
            placeholder={"Quem é o Executou este evento."}
            table={"person"}
            labelColumn={"firstName"}
            valueColumn={"id"}
            onChange={(e) => onChange(e.id, e.value)}
            auxiliarColumn={"lastName"}
            separator={" "}
            idSize={0}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="eventStateId" className="form-label">
            Estado do Evento:
          </label>
          <input
            name={"eventStateId"}
            className="form-control"
            placeholder={"Finalizado"}
            disabled={true}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="date" className="form-label">
            Duração do Evento:
          </label>
          <div className="mb-3 row">
            <div className="col-md-1 mb-3">
              <label>Início: </label>
            </div>
            <div className="col-md-5 mb-3">
              <input
                type="datetime-local"
                className="form-control"
                id="date"
                name="eventInitDate"
                value={formData.eventInitDate}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-md-1 mb-3">
              <label>Fim: </label>
            </div>
            <div className="col-md-5 mb-3">
              <input
                type="datetime-local"
                className="form-control"
                id="date"
                name="eventEndDate"
                value={formData.eventEndDate}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre a Montagem</h2>
        <div className="mb-3">
          <strong>Instruções: </strong>
          <p>
            O Evento além de suas características básicas possui entradas e
            saídas, associadas à uma das BOMs de montagem do produto.
          </p>
          <p>
            Defina a BOM utilizada para construir este item, e caso queira, os
            itens (instâncias) utilizadas para preencher esta BOM.
          </p>
          <p>
            Caso selecione criar BOM vazia será gerada uma BOM para o seu item
            sem entradas, ou seja, Será considerado que o item foi obtido a
            partir de um processo de compra genérico e apareceu na oficina. Esta
            opção deve ser utilizada em último caso, pois é possível selecionar
            uma bom e processo sem determinar cada item individualmente.
          </p>
          <p>
            Lembre-se de não utilizar itens duplicados para entrada. A caixa de
            seleção apresentará apenas itens no estado de armazenamento e com a
            parte correspondente a desejada pela BOM.
          </p>
          <p>
            Caso não saiba quais itens exatamente estão dentro deste seu robô
            (ou objeto) apenas não selecione nenhuma entrada.
          </p>
        </div>
        <div className="mb-3">
          <label className="form-label">BOM: </label>
          <BomSelector
            partId={formData.partId}
            name={"eventBomId"}
            checBoxName={"createEmptyBom"}
            label={"BOM: "}
            placeholder={"Escolha sua BOM"}
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <InputSelector
            name={"inputItemId"}
            bomId={formData.eventBomId}
            onChange={(e) => onChange(e.id, e.value.inputItemId)}
          />
        </div>
      </div>
      <div className="m-0 mt-4 mb-4 rounded p-4 row">
        <button type="submit" className="mb-3 btn btn-primary btn-lg">
          Enviar
        </button>
      </div>
    </form>
  );
};

export default ItemForm;
