import { useState } from "react";
import UuidText from "../Text/UuidText";
import TypeHead from "../TypeHead/TypeHead";
import testRequired from "./utils/testRequired";
import BomElementSelector from "../../PageElements/crud/bom/create/BomElementSelector";

const BomForms = ({ onSubmit }) => {
  const [formData, setFormData] = useState({ id: "", processId: "" });
  const [resetForm, setResetForm] = useState(false);

  const clearForm = () => {
    setFormData({ processId: "" });
    setResetForm(!resetForm);
  };

  const onChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const innerOnSubmit = (e) => {
    e.preventDefault();
    if (testRequired(formData)) {
      alert("Por favor, preencha ao menos o campo de Processo.");
      return;
    }
    onSubmit(formData);
    clearForm();
  };

  return (
    <form onSubmit={innerOnSubmit}>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre a BOM</h2>
        <div className="mb-3">
          <strong>Instruções: </strong>
          <p>
            BOMs, ou <strong>Bill of Materials</strong>, são a representação das
            peças contidas em um projeto.
          </p>
          <p>
            Neste sistema, as BOMs são utilizadas como representação tanto das
            peças contidas quanto das peças resultantes de um processo.
          </p>
          <p>
            Dessa forma, atente-se não somente a quais subsistemas estão
            contidos no sistema que você está descrevendo, mas também nos
            resíduos e peças colaterais ocasionantes quando constrói-se seu
            sistema com esta BOM e processo.
          </p>
          <p>
            BOMs são representadas de diversas formas nas engenharias. Aqui,
            opta-se por BOMs comuns em manufatura que incluem os processos
            utilizados na construção do item. Também opta-se por possuir um
            único processo associado a cada BOM, a fim de facilitar o fluxo de
            trabalho do usuário mais adiante.
          </p>
          <p>
            Ao montar sua BOM, não tente determinar todos os subsistemas do seu
            projeto recursivamente. Ao invés disso, construa uma árvore.
            Iniciando-se pela raiz, inclua os subsistemas íntegros que levam à
            construção de seu sistema. Num próximo passo, construa BOMs para
            estes subsistemas e aprofunde-se até que sobrem apenas itens não
            processados nas folhas da árvore.
          </p>
        </div>
        <div className="mb-3">
          <UuidText
            id="id"
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label className="form-label"> Processo: </label>
          <TypeHead
            name="processId"
            placeholder="Processo associado a essa BOM"
            table="process"
            labelColumn="name"
            valueColumn="id"
            auxiliaryColumn="description"
            changeToClearSelection={resetForm}
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
          />
        </div>
      </div>

      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre as Saídas</h2>
        <div className="mb-3">
          <strong>Instruções: </strong> Atente-se em selecionar as peças que
          serão consideradas as saídas deste processo, como a montagem final,
          peças colaterais, resíduos, etc.
          <br />
          <br />
          Não esqueça de selecionar as quantidades.
        </div>
        <div className="mb-3">
          <BomElementSelector
            name="outputs"
            placeholder="Escolha a próxima peça de saída"
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
      </div>

      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre as Entradas</h2>
        <div className="mb-3">
          <strong>Instruções: </strong> Atente-se em selecionar as peças que
          serão consideradas entradas deste sistema, atentando-se à pergunta
          "Quais seriam os materiais de entrada caso eu desfizesse somente a
          minha última ação nesta montagem?". <br />
          <br />
          Não esqueça de selecionar as quantidades.
        </div>
        <div className="mb-3">
          <BomElementSelector
            name="inputs"
            placeholder="Escolha a próxima peça de entrada"
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
      </div>
      <div className="m-0 mt-4 mb-4 rounded p-4 row">
        <button type="submit" className="mb-3 btn btn-primary btn-lg">
          Enviar
        </button>
      </div>
    </form>
  );
};

export default BomForms;
