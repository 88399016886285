const BomTableBody = ({ parentsTable, childrenTable, sideBySide }) => {

  const shouldRender = (table) => table.props.children.props.children[1].props && table.props.children.props.children[1].props.children.length > 0;
  sideBySide = shouldRender(childrenTable) && shouldRender(parentsTable) && sideBySide;

  return (
    <div className="row">
      {sideBySide ? (
        <>
          {shouldRender(childrenTable) && (
            <div className="col-lg-6 mb-4">
              <h3>{'Materiais'}</h3>
              {childrenTable}
            </div>
          )}
          {shouldRender(parentsTable) && (
            <div className="col-lg-6 mb-4">
              <h3>{'Produtos'}</h3>
              {parentsTable}
            </div>
          )}
        </>
      ) : (
        <>
          {shouldRender(childrenTable) && (
            <div className="col-lg-12 mb-4">
              <h3>{'Materiais'}</h3>
              {childrenTable}
            </div>
          )}
          {shouldRender(parentsTable) && (
            <div className="col-lg-12 mb-4">
              <h3>{'Produtos'}</h3>
              {parentsTable}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BomTableBody;
