import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const UuidText = ({ id, onChange, changeToClearSelection, label = 'UUID:', setUuuidValue = ""}) => {
  const [uuid, setUuid] = useState(uuidv4());
  const [prevUuid, setPrevUuid] = useState(uuid);
  const [uuidEditable, setUuidEditable] = useState(false);

  useEffect(() => {
    if (uuid !== prevUuid) {
      onChange({ id: id, value: uuid});
      setPrevUuid(uuid);
    }
  }, [onChange, id, uuid, prevUuid]);

  useEffect(() => {
    setUuidEditable(false);
    setUuid(uuidv4());
  }, [changeToClearSelection]);

  useEffect(() => {
    if(setUuuidValue !== "") {
      setUuid(setUuuidValue);
    }
  }, [setUuuidValue]);

  const handleChange = (e) => {
    setUuid(e.target.value);
  };

  const handleCheckboxChange = () => {
    setUuidEditable(!uuidEditable);
    const newId = uuidv4();
    setUuid(uuidEditable ? newId : '');
    onChange({ id: id, value: uuidEditable ? newId : '' });
  };

  return (
    <div className='row'>
      <div className='col-10 col-lg-11'>
        <label htmlFor="UUID" className="form-label">
          {label}
        </label>
        <input
          type="text"
          className="form-control"
          id="UUID"
          name={id}
          value={uuid}
          placeholder={uuid}
          disabled={!uuidEditable}
          onChange={handleChange}
          readOnly={!uuidEditable}
        />
      </div>
      <div className='col-2 col-lg-1 d-flex align-items-center'>
        <div className="mt-5 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="editableCheckbox"
            checked={uuidEditable}
            onChange={handleCheckboxChange}
          />
          <label className="form-check-label" htmlFor="editableCheckbox">
            Editar
          </label>
        </div>
      </div>
    </div>
  );
};

export default UuidText;
