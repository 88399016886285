import React, { useState, useEffect } from 'react';

const ErrorMessage = ({ error }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if error exists and toggle visibility
    setIsVisible(error != null);
  }, [error]);

  // Function to dismiss the error message
  const dismissError = () => {
    setIsVisible(false);
  };

  return (
    <div className={`alert alert-danger${isVisible ? ' d-block' : ' d-none'}`} role="alert">
      <div className="d-flex justify-content-between align-items-center">
        <div>{error}</div>
        {isVisible && (
          <button type="button" className="btn-close" aria-label="Close" onClick={dismissError}></button>
        )}
      </div>
    </div>
  );
};

export default ErrorMessage;
