import React, { useState, useEffect } from 'react';
import QueryOutput from '../QueryOutput';
import fetchPartItems from '../../../utils/graphql/fetches/fetchPartItems';

const PartItemsTable = ({ partId }) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
        const data = await fetchPartItems(partId);
        setResult(data);
      }
    fetchData();
  }, [partId]);

  return (
    <div>
      <QueryOutput
        queryResult={result}
        containerTitles={[]}
        MajorContainerTitle={"Instâncias"}
      />
    </div>
  );
};

export default PartItemsTable;
