import GenericSelectionBox from "../../../Input/SelectionBox/GenericSelectionBox";

const ImportTableSelectionBox = ({
  onChange,
  name,
  changeToClearSelection,
}) => {

  const options = [
    { label: "Unidade", value: "unit", auxLabel: "" },
    { label: "Peça", value: "part", auxLabel: "" },
    { label: "CreateItem", value: "createItem", auxLabel: "" },
  ];

  const fetchOptionsFunction = async () => { return options; }

  return (
    <>
      <GenericSelectionBox
        name={name}
        fetchOptionsFunction={fetchOptionsFunction}
        fetchOptionsArgs={{}}
        optionArgs={{ separator: " - ", placeholder: "" }}
        onChange={onChange}
        changeToClearSelection={changeToClearSelection}
      />
    </>
  );
};

export default ImportTableSelectionBox;
