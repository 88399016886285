import React from 'react';
import config from '../../../utils/config/config';

const BomTableHeader = (bomData) => {
  const tableData = Object.entries(bomData);

  return (
    <>
      <div className="card">
        <div className="card-header bg-dark text-light">
          <h5 className="mb-0">Detalhes da BOM</h5>
        </div>
        <div className="card-body">
          {tableData.map(([key, value]) => (
            <div key={key} className="mb-3">
              <strong>{config.displayHeaders[key]}:</strong> {value}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BomTableHeader;
