import { useState, useEffect } from "react";

const GenericSelectionBox = ({
  name,
  fetchOptionsFunction,
  fetchOptionsArgs,
  selectArgs,
  optionArgs,
  onChange,
  changeToClearSelection,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setSelectedValue("");
  }, [changeToClearSelection]);

  const innerOnChange = (e) => {
    setSelectedValue(e.target.value);
    onChange({ id: e.target.name, value: e.target.value });
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const fetchedOptions = await fetchOptionsFunction(fetchOptionsArgs);
        setOptions(fetchedOptions);
      } catch (error) {
        console.error(
          "Error fetching options for the generic selection box: ",
          error
        );
        throw error;
      }
    };
    fetchOptions();
  }, [fetchOptionsArgs, fetchOptionsFunction]);

  return (
    <select
      {...selectArgs}
      name={name}
      className="form-control"
      onChange={innerOnChange}
      value={selectedValue}
    >
      <option value="">{optionArgs.placeholder}</option>
      {options.map(({ label, value, auxLabel }, index) => (
        <option key={index} value={value}>
          {auxLabel ? `${label}${optionArgs.separator}${auxLabel}` : label}
        </option>
      ))}
    </select>
  );
};

export default GenericSelectionBox;
