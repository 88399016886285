import React from 'react';
import Head from '../../components/Header';
import Navbar from '../../components/NavBar';
import Footer from '../../components/Footer';
import '../../styles/homepage.css';

const HomePage = () => {
  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card text-center">
              <div className="card-body">
                <h1 className="card-title">WR4.0 - Interface de Produção</h1>
                <p className="card-text">Bem-vindo(a) à nossa plataforma de produção.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-4 mb-4">
            <div className="card h-100 d-flex flex-column">
              <div className="card-body">
                <h5 className="card-title">Manufatura</h5>
                <p className="card-text">Irá participar da manufatura de algum item? Comece por aqui.</p>
              </div>
              <div className="mt-auto">
                <a href="/usage/manufacturing/selectItem/selectitemmanufacturing" className="btn btn-primary btn-block m-3 d-flex justify-content-center">Fabricar</a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card h-100 d-flex flex-column">
              <div className="card-body">
                <h5 className="card-title">Acompanhamento de Processos</h5>
                <p className="card-text">Acompanhe o progresso de qualquer processo do grupo filtrando pelo seu atual estado.</p>
              </div>
              <div className="mt-auto">
                <a href="/usage/overview/event" className="btn btn-secondary btn-block m-3 d-flex justify-content-center">Processos</a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card h-100 d-flex flex-column">
              <div className="card-body">
                <h5 className="card-title">Acompanhamento dos Robôs</h5>
                <p className="card-text">Acompanhe em tempo real a disponibilidade de nossos robôs.</p>
              </div>
              <div className="mt-auto">
                <a href="/usage/robots/status" className="btn btn-info btn-block m-3 d-flex justify-content-center">Robôs</a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card h-100 d-flex flex-column">
              <div className="card-body">
                <h5 className="card-title">Operações</h5>
                <p className="card-text">Utilize ou devolva os robôs através desse menu dedicado as operações.</p>
              </div>
              <div className="mt-auto">
                <a href="/usage/operation/selectItem/selectitemoperation" className="btn btn-warning btn-block m-3 d-flex justify-content-center">Operar</a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card h-100 d-flex flex-column">
              <div className="card-body">
                <h5 className="card-title">Compras</h5>
                <p className="card-text">Chegou uma encomenda? associe ids a todos os items e crie seus primeiros históricos no banco.</p>
              </div>
              <div className="mt-auto">
                <a href="/usage/purchase" className="btn btn-success btn-block m-3 d-flex justify-content-center">Compras</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
