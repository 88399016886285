import fetchChildItems from "../../../utils/graphql/fetches/fetchChildItems";

async function ItemChildIdTypeHeadFecthOptions({ childId, input }) {
  try {
    if (!childId) {
      return [];
    }
    const response = await fetchChildItems(childId, input);
    let options = [];
    for (const item of response.data.Items) {
      options.push({
        label: item["id"],
        value: item["id"],
        auxLabel: item["description"],
      });
    }
    return options;
  } catch (error) {
    console.error("Error fetching options:", error);
  }
}

export default ItemChildIdTypeHeadFecthOptions;