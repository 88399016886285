import React, { useState, useEffect } from 'react';

const SuccessMessage = ({ success }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(success != null);
  }, [success]);

  const dismissSuccess = () => {
    setIsVisible(false);
  };

  return (
    <div className={`alert alert-success${isVisible ? ' d-block' : ' d-none'}`} role="alert">
      <div className="d-flex justify-content-between align-items-center">
        <div>{success}</div>
        {isVisible && (
          <button type="button" className="btn-close" aria-label="Close" onClick={dismissSuccess}></button>
        )}
      </div>
    </div>
  );
};

export default SuccessMessage;
