import config from '../../config/config';
import fetchController from '../fetchController';

// Function to fetch data from GraphQL API
async function getNextValidStatus(itemId, statusId, like) {
  try {
    // initialize variables
    const variables = {itemId: itemId, statusId: statusId, like: like || null}

    // Make a GET request
    const response = await fetchController(variables, config.graphQLServerHost, config.getNextValidStatusUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller getNextValidStatus:', error);
  }
}

export default getNextValidStatus;
