import { useState } from "react";
import TypeHeadMultiSelection from "../TypeHeadMultiSelection/TypeHeadMultiSelection";
import TypeHead from "../TypeHead/TypeHead";
import testRequired from "./utils/testRequired";
import UuidText from "../Text/UuidText";

const ProcessForm = ({ onSubmit }) => {
  const [resetForm, setResetForm] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    description: "",
    date: "",
    version: "",
    authorId: "",
    isNewDocument: false,
    firstDocumentId: "",
    firstDocumentTitle: "",
    firstDocumentDescription: "",
    firstDocumentAuthors: "",
    firstDocumentVersion: "",
    firstDocumentUrl: "",
    firstDocumentDate: "",
    firstDocumentParts: [],
    documents: [],
  });

  const clearForm = () => {
    setFormData({
      id: "",
      name: "",
      description: "",
      date: "",
      version: "",
      authorId: "",
      isNewDocument: false,
      firstDocumentId: "",
      firstDocumentTitle: "",
      firstDocumentDescription: "",
      firstDocumentAuthors: "",
      firstDocumentVersion: "",
      firstDocumentUrl: "",
      firstDocumentDate: "",
      firstDocumentParts: [],
      documents: [],
    });
    setResetForm(!resetForm);
  };

  const onChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const innerOnSubmit = (e) => {
    e.preventDefault();
    let notRequiredFileds = ["date", "documents", "firstDocumentParts"];
    if (!formData.isNewDocument) {
      notRequiredFileds.push("firstDocumentTitle");
      notRequiredFileds.push("firstDocumentDescription");
      notRequiredFileds.push("firstDocumentAuthor");
      notRequiredFileds.push("firstDocumentVersion");
      notRequiredFileds.push("firstDocumentUrl");
      notRequiredFileds.push("firstDocumentDate");
    }

    if (testRequired(formData, notRequiredFileds)) {
      alert("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    onSubmit(formData);
    clearForm();
  };

  return (
    <form onSubmit={innerOnSubmit}>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2 className="mb-4">Sobre o Processo</h2>
        <div className="mb-3">
          <strong>Intruções:</strong>
          <p>
            Esta página tem objetivo de possibilitar a inserção de novos
            processos. Data de Publicação do Processo, Versionamento, e
            documentos relacionados não são campos obrigatórios.
          </p>
        </div>
        <div className="mb-3">
          <UuidText
            id={"id"}
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Nome:
          </label>
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder="Nome do processo, lembre-se de ser descritivo e preciso evitando nomes como 'processo de montagem'."
            value={formData.name}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Descrição:
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            placeholder="Descreva brevemente do que se trata o processo de forma que qualquer um possa identifica-lo."
            value={formData.description}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="date" className="form-label">
            Data de Publicação:
          </label>
          <input
            type="date"
            className="form-control"
            id="date"
            name="date"
            value={formData.date}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="version" className="form-label">
            Versão:
          </label>
          <input
            type="text"
            className="form-control"
            id="version"
            name="version"
            placeholder="Versionamento do processo i.e '1.2.1'."
            value={formData.version}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="version" className="form-label">
            Autor:
          </label>
          <TypeHead
            name="authorId"
            idSize={0}
            placeholder="Autor do processo."
            table="person"
            labelColumn="firstName"
            valueColumn="id"
            auxiliarColumn="lastName"
            separator=" "
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
      </div>

      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2 className="mb-4">Sobre o Documento de Descrição do Processo</h2>

        <div className="mb-3">
          <strong>Instruções:</strong>
          <p>
            Caso esteja criando seu processo, muito provavelmente, ainda não há
            um documento de descrição do mesmo no banco. Para criar um documento
            de descrição do zero utilize esta partedo formulário. Caso já exista
            este docuento no banco, você precisa apenas seleciona-lo Ao criar um
            documento os campos de versão, data
          </p>
        </div>
        <div className="mb-3">
          <input
            type="radio"
            id="existexiingItem"
            name="itemSelection"
            checked={!formData.isNewDocument}
            onChange={() => onChange("isNewDocument", false)}
          />
          <label className="mx-3 mb-3" htmlFor="existingItem">
            Selecionar Existente:
          </label>
          <input
            type="radio"
            id="newDocument"
            name="itemSelection"
            checked={formData.isNewDocument}
            onChange={() => onChange("isNewDocument", true)}
          />
          <label className="mx-3 mb-3" htmlFor="newDocument">
            Criar Novo
          </label>

          {formData.isNewDocument ? (
            <div>
              <div className="mb-3">
                <UuidText
                  id="firstDocumentId"
                  onChange={(e) => onChange(e.id, e.value)}
                  required={true}
                  changeToClearSelection={resetForm}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Título:
                </label>
                <textarea
                  className="form-control"
                  id="firstDocumentTitle"
                  name="firstDocumentTitle"
                  placeholder="Título do Documento"
                  value={formData.firstDocumentTitle}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Descrição:
                </label>
                <textarea
                  className="form-control"
                  id="firstDocumentDescription"
                  name="firstDocumentDescription"
                  placeholder="Descreva o conteúdo do documento e sua finalidade."
                  value={formData.firstDocumentDescription}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="mb-3">
              <label className="form-label"> Autores: </label>
                <TypeHeadMultiSelection
                  name={"firstDocumentAuthors"}
                  placeholder={"Quem são os o autores deste documento."}
                  table={"person"}
                  labelColumn={"firstName"}
                  valueColumn={"id"}
                  onChange={(e) => onChange(e.id, e.value)}
                  auxiliarColumn={"lastName"}
                  separator={" "}
                  idSize={6}
                  columns={["UUID", "Nome", "Sobrenome"]}
                  changeToClearSelection={resetForm}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="version" className="form-label">
                  Versão:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstDocumentVersion"
                  name="firstDocumentVersion"
                  placeholder="Versionamento do documento i.e '1.2.1'"
                  value={formData.firstDocumentVersion}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="url" className="form-label">
                  Link de Acesso:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstDocumentUrl"
                  name="firstDocumentUrl"
                  placeholder="Link de acesso para o documento."
                  value={formData.firstDocumentUrl}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="date" className="form-label">
                  Data de Publicação:
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="firstDocumentDate"
                  name="firstDocumentDate"
                  value={formData.firstDocumentDate}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="part" className="form-label">
                  Projetos e Peças Relacionados ao documento desciritvo deste processo:
                </label>
                <TypeHeadMultiSelection
                  name="firstDocumentParts"
                  placeholder="Projetos e peças que se relacionam ao documento e ao processo indiretamente."
                  table="part"
                  labelColumn="name"
                  valueColumn="id"
                  columns={["UUID", "Nome"]}
                  onChange={(e) => onChange(e.id, e.value)}
                  changeToClearSelection={resetForm}
                />
              </div>
            </div>
          ) : (
            <TypeHead
              name="firstDocumentId"
              placeholder="Documentos relacionados ao processo."
              table="document"
              labelColumn="title"
              auxiliarColumn="version"
              valueColumn="id"
              onChange={(e) => {
                onChange(e.id, e.value);
              }}
              changeToClearSelection={resetForm}
            />
          )}
        </div>
      </div>

      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2 className="mb-4">Sobre os Documentos Relacionados</h2>

        <strong>Instruções:</strong>
        <p>
          Selecione os documentos do banco de dados que você julga estarem
          relacionados a este, foram citados no mesmo por exemplo.
        </p>
        <div className="mb-3">
          <label htmlFor="documents" className="form-label">
            Documentos Relacionados
          </label>
          <TypeHeadMultiSelection
            name="documents"
            placeholder="Documentos relacionados ao processo."
            table="document"
            labelColumn="title"
            auxiliarColumn="version"
            valueColumn="id"
            columns={["UUID", "Título", "Versão"]}
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
      </div>

      <div className="mx-0 mt-5 row">
        <button type="submit" className="btn btn-primary btn-lg">
          Enviar
        </button>
      </div>
    </form>
  );
};

export default ProcessForm;
