import config from '../../config/config';
import postController from '../postController';

// Function to fetch data from GraphQL API
async function depositProduct(depositProductInput) {
  try {
    // initialize variables
    const variables = depositProductInput;

    // Make a GET request
    const response = await postController(variables, config.graphQLServerHost, config.depositProductUrl);
    return response;

  } catch (error) {
    console.error('Error posting data to controller depositProduct:', error);
    throw(error);
  }
}

export default depositProduct;