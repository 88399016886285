import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Head from "../../../../../components/Header";
import Navbar from "../../../../../components/NavBar";
import Footer from "../../../../../components/Footer";
import EventManufacturingForm from "../../../../../components/Input/Forms/EventManufacturingForm"; // Import the PartForm component here
import CreateEventWithOutput from "../../../../../utils/graphql/crud/event/createWithOutput";
import ErrorOutput from "../../../../../components/Output/ErrorOutput";

const CreateEventPage = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const onSubmit = async (input) => {
    setError(null);
    try {
      const response = await CreateEventWithOutput(input);
      const nextPageUrl = `/usage/manufacturing/selectItem/selectevent/event/eventpagemanufacturing/${response.data.id}`;
      navigate(nextPageUrl);
    } catch (error) {
      setError(error.message || "Ocorreu um erro.");
    }
  };

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="m-5 text-center">
          <h1>Inserir Evento</h1>
        </div>
        <EventManufacturingForm onSubmit={onSubmit} itemId={itemId} />
        <ErrorOutput error={error} />
      </div>
      <Footer />
    </div>
  );
};

export default CreateEventPage;
