import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Head from "../../../../../../components/Header";
import Navbar from "../../../../../../components/NavBar";
import Footer from "../../../../../../components/Footer";
import UserTypeHead from "../../../../../../components/Input/TypeHead/UserTypeHead";
import EventCard from "../../../../../../components/Output/Card/EventCard";
import fetchEventDetails from "../../../../../../utils/graphql/fetches/fetchEventDeepDetails";
import ManufacturingBodyInputTab from "../../../../../../components/PageElements/usage/event/eventpagemanufacturing/inputTab/ManufacturingBodyInputTab";
import ManufacturingBodyOutputTab from "../../../../../../components/PageElements/usage/event/eventpagemanufacturing/outputTab/ManufacturingBodyOutputTab";
import ManufacturingBodyDocumentsTab from "../../../../../../components/PageElements/usage/event/eventpagemanufacturing/documentsTab/ManufacturingBodyDocumentsTab";
import ManufacturingBodyExecutionTab from "../../../../../../components/PageElements/usage/event/eventpagemanufacturing/executionTab/ManufacturingBodyExecutionTab";
import config from "../../../../../../utils/config/config";

const ToBeDevelopedPage = () => {
  const { eventId } = useParams();
  const [personId, setPersonId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [showEditOptions, setShowEditOptions] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchEventDetails(eventId);
        setShowEditOptions(false);
        if (
          response.data.event[0] &&
          response.data.event[0].State &&
          response.data.event[0].State.id
        ) {
          setStateId(response.data.event[0].State.id);
        }
        if (response.data.event[0].State.id === config.fixedIds.state.idle) {
          setShowEditOptions(true);
        }
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };
    fetchData();
  }, [eventId]);

  return (
    <div className="wr-home">
      <Head />
      <Navbar />

      <div className="container mt-5 bg-light rounded">
        <div className="container">
          <h1> Evento </h1>
        </div>
        <div className="container">
          <EventCard eventId={eventId} />
          {stateId !== config.fixedIds.state.finalized && (
            <div className="mt-5">
              <h5>Não esqueça de selecionar quem você é:</h5>
              <UserTypeHead
                typeHeadOptions={{
                  id: "peopleId",
                  placeholder: "Quem é Voce?",
                }}
                onChange={(e) => {
                  setPersonId(e.value);
                }}
              />
            </div>
          )}
          <div className="row mt-3"></div>
        </div>
      </div>

      <div className="container bg-light">
        <ul className="nav nav-tabs mt-3 bg-light" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="tab1-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab1"
              type="button"
              role="tab"
              aria-controls="tab1"
              aria-selected="true"
            >
              Entradas
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab2-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab2"
              type="button"
              role="tab"
              aria-controls="tab2"
              aria-selected="false"
            >
              Saídas
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="tab3-tab"
              data-bs-toggle="tab"
              data-bs-target="#tab3"
              type="button"
              role="tab"
              aria-controls="tab3"
              aria-selected="false"
            >
              Documentos
            </button>
          </li>
          {stateId !== config.fixedIds.state.finalized && (
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tab4-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab4"
                type="button"
                role="tab"
                aria-controls="tab4"
                aria-selected="false"
              >
                Execução
              </button>
            </li>
          )}
        </ul>

        <div className="tab-content bg-light" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="tab1"
            role="tabpanel"
            aria-labelledby="tab1-tab"
          >
            <ManufacturingBodyInputTab
              eventId={eventId}
              personId={personId}
              showEditOptions={showEditOptions}
            />
            <div className="row mb-3"></div>
          </div>
          <div
            className="tab-pane fade"
            id="tab2"
            role="tabpanel"
            aria-labelledby="tab2-tab"
          >
            <ManufacturingBodyOutputTab
              eventId={eventId}
              personId={personId}
              showEditOptions={showEditOptions}
            />
            <div className="row mb-3"></div>
          </div>
          <div
            className="tab-pane fade"
            id="tab3"
            role="tabpanel"
            aria-labelledby="tab3-tab"
          >
            <ManufacturingBodyDocumentsTab eventId={eventId} />
            <div className="row mb-3"></div>
          </div>
          {stateId !== config.fixedIds.state.finalized && (
            <div
              className="tab-pane fade"
              id="tab4"
              role="tabpanel"
              aria-labelledby="tab4-tab"
            >
              <ManufacturingBodyExecutionTab
                eventId={eventId}
                personId={personId}
                eventStateId={stateId}
              />
              <div className="row mb-3"></div>
            </div>
          )}
        </div>
      </div>

      <div className="container">.</div>

      <Footer />
    </div>
  );
};

export default ToBeDevelopedPage;
