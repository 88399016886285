import config from '../../../config/config';
import postController from '../../postController';

// Function to fetch data from GraphQL API
async function createEventWithOutput(eventInput) {
  try {
    // initialize variables
    const variables = eventInput

    // Make a post request
    const response = await postController(variables, config.graphQLServerHost, config.createEventWithOutputUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller createEventWithOutput:', error.message);
    throw(error)
  }
} 

export default createEventWithOutput;
