import config from '../../config/config';
import fetchController from '../fetchController';

// Function to fetch data from GraphQL API
async function getCurrentInputForManufacturingBody(eventId, partId, quantityIndex) {
  try {
    // initialize variables
    const variables = {eventId: eventId, partId: partId, quantityIndex: quantityIndex}

    // Make a GET request
    const response = await fetchController(variables, config.graphQLServerHost, config.getCurrentInputForManufacturingBodyUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller getCurrentInputForManufacturingBody:', error);
  }
}

export default getCurrentInputForManufacturingBody;
