import { useState } from "react";
import Head from "../../components/Header";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import CameraInput from "../../components/Input/CameraInput/CameraInput";
import TypeHead from "../../components/Input/TypeHead/TypeHead";
import ErrorOutput from "../../components/Output/ErrorOutput";
import fetchPersonDetails from "../../utils/graphql/fetches/fetchPersonDetails";

const LoginPage = () => {
  const [cameraError, setCameraError] = useState(null);

  const setUser = async (personId) => {
    try {
      const response = await fetchPersonDetails(personId);
      if (response.data.person[0].id === personId){
        localStorage.setItem("userId", response.data.person[0].id);
        localStorage.setItem("userFirstName", response.data.person[0].firstName);
        localStorage.setItem("userLastName", response.data.person[0].lastName);
        localStorage.setItem("isLoggedIn", true);
        window.location.reload();
      }
      else {
        setCameraError("Usuário Não encontrado");
      }
    } catch (e) {
      setCameraError(e.message || e);
    }
  };

  const exit = () => {
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("person", null);
    window.location.reload();
  };

  // Check if the user is logged in
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  // If user is logged in, render the welcome page
  if (isLoggedIn) {
    return (
      <div className="wr-home">
        <Head />
        <Navbar />
        <div
          style={{
            height: "100vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="container-fluid d-flex justify-content-center align-items-center"
        >
          <div
            style={{ backgroundColor: "rgba(255, 255, 255, 0.8)", width: "100vw" }}
            className="text-center"
          >
            <h1>Bem Vindo, {localStorage.getItem("userFirstName")}!</h1>
            <button onClick={exit} className="m-5 btn btn-danger">
              Logout
            </button>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  // If user is not logged in, render the login page
  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="mt-5 mb-3 rounded bg-light p-4">
          <div className="text-center">
            <h1>Aponte o seu QRCode para a Camera</h1>
          </div>
          {/* Render CameraInput only if cameraError is null */}
          {cameraError === null ? (
            <CameraInput
              id={"TesteId"}
              showImage={true}
              title={""}
              onError={(e) => {
                setCameraError(e);
              }}
              onScan={(e) => {
                setCameraError(null);
                setUser(e.value);
              }}
            />
          ) : (
            <div className="m-5">
              <ErrorOutput error={cameraError} />
            </div>
          )}
          <label className="form-label"> Quem é você: </label>
          <TypeHead
            name={"userId"}
            placeholder={"Caso não tenha uma câmera selecione seu nome"}
            table={"person"}
            labelColumn={"firstName"}
            valueColumn={"id"}
            onChange={(e) => setUser(e.value)}
            auxiliarColumn={"lastName"}
            separator={" "}
            idSize={0}
            // changeToClearSelection={resetForm}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
