import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchEventDocuments = async (eventId) => {
  try {
    const query = `{
        event(id: "${eventId}") {
          id
          Bom {
            Process {
              Documents {
                id
                title
                url
              }
            }
            Parents {
              Part {
                Documents {
                  id
                }
              }
            }
            Children {
              Part {
                Documents {
                  id
                }
              }
            }
          }
        }
      }`;
      
    const response = await fetchGraphQLQuery(query);
    return response;
  } catch(error) {
    console.error('Error fetching event documents:', error);
  }
};

export default fetchEventDocuments;
