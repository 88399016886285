import { useState, useEffect } from 'react';
import QueryOutput from '../QueryOutput'
import getItemLastUsageInAssembly from '../../../utils/graphql/controllers/getItemLastUsageInAssembly'

const columnsToRemove = [
  '__typename',
  'Partid',
  'Partdescription',
  'Partdate',
  'Partversion',
  'PartcreatedAt',
  'PartupdatedAt',
  'PartunitId',
  'ParttypeId',
  'PartsupplyId',
  'PartpeopleId',
  'Outputid',
  'OutputquantityIndex',
  'OutputcreatedAt',
  'OutputupdatedAt',
  'OutputeventId',
  'OutputoutputId',
  'OutputItemcreatedAt',
  'OutputItemupdatedAt',
  'OutputItemPartid',
  'OutputItempartId',
  'OutputItemPartname',
  'OutputItemPartdescription',
  'OutputItemPartcreatedAt',
  'OutputItemPartupdatedAt',
  'OutputItemPartunitId',
  'OutputItemParttypeId',
  'OutputItemPartsupplyId'
];

const ItemEvent = ({ itemId }) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getItemLastUsageInAssembly(itemId);
      setResult(response);
    };
    fetchData();
  }, [itemId]);


  if (!itemId) {
    return (<></>);
  }

  return (
    <> 
      <QueryOutput
        queryResult={result}
        containerTitles={''}
        MajorContainerTitle={'Seu item esta sendo utilizado Na Montagem Destes Itens'}
        columnsToRemove={columnsToRemove}
      />
    </>
  );
};

export default ItemEvent;