import { useState, useEffect } from "react";
import BomPartIdTypeHead from "../../../../Input/TypeHead/BomPartIdTypeHead";

const BomSelector = ({
  partId,
  name,
  checBoxName,
  placeholder,
  onChange,
  changeToClearSelection,
}) => {
  const [clearSelection, setClearSelection] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);

  useEffect((clearSelection) => {
    setClearSelection(!clearSelection);
    setIsCheckboxChecked(true);
  }, [changeToClearSelection]);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setClearSelection(!isChecked);
    onChange({ id: checBoxName, value: isChecked });
    setIsCheckboxChecked(isChecked);
  };

  return (
    <div className="row">
      <div className="col-8 col-lg-9">
        <BomPartIdTypeHead
          typeHeadOptions={{
            id: name,
            placeholder: placeholder,
            disabled: isCheckboxChecked,
          }}
          fetchOptionsArguments={{ partId: partId }}
          changeToClearSelection={clearSelection}
          onChange={onChange}
        />
      </div>
      <div className="col-4 col-lg-3 d-flex align-items-center">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="emptyBomCheckbox"
            checked={isCheckboxChecked}
            onChange={handleCheckboxChange}
          />
          <label className="form-check-label" htmlFor="emptyBomCheckbox">
            Criar Bom Vazia
          </label>
        </div>
      </div>
    </div>
  );
};

export default BomSelector;
