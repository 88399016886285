import { useNavigate } from "react-router-dom";
import Head from "../../../../components/Header";
import Navbar from "../../../../components/NavBar";
import Footer from "../../../../components/Footer";
import ItemsFromPartIdTypeHeadQrCode from "../../../../components/Input/TypeHeadQRCode/ItemsFromPartIdTypeHeadQrCode";

const SelectItemManufacturingPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Head />
      <Navbar />
      <div className="wr-home container-fluid d-flex align-items-center justify-content-center">
        <div className="mt-4">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card w-100">
                <div className="text-center card-body">
                  <h1 className="mb-4"> Selecione o UUID do seu Item , ou utilize o QRCode </h1>
                    <ItemsFromPartIdTypeHeadQrCode
                      typeHeadOptions = {{id: "qrCodeSelection", placeholder: "UUID do item que utilizará em sua montagem"}}
                      fetchOptionsArguments = {{partId: ''}}
                      changeToClearSelection = {{}}
                      onChange={(e) => {
                        if (e && e.value !== '' && e.value !== null) {
                          navigate(`/usage/manufacturing/selectItem/selectevent/selecteventmanufacturing/${e.value}`);
                        }
                      }}
                    />
                </div>
                <div className="bg-white m-2">
                  <div>
                    Se você não consegue selecionar o seu item, ele não existe
                    no banco de dados.{" "}
                  </div>
                  <a href="/usage/manufacturing/selectItem/createitemmanufacturing/">
                    Clique Aqui
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SelectItemManufacturingPage;
