import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Head from "../../../components/Header";
import Navbar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import ErrorOutput from "../../../components/Output/ErrorOutput";
import TypeHead from "../../../components/Input/TypeHead/TypeHead";
import EventCardDetailedSelectionList from "../../../components/Input/Mixed/EventCardDetailedSelectionList";
import fetchEventIdByState from "../../../utils/graphql/fetches/fetchEventIdByState";
import fetchEventIdByStateTotalRecords from "../../../utils/graphql/fetches/fetchEventIdByStateTotalRecords";

const EventOvervViewPage = () => {
  const limit = 5;
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [itemEvents, setItemEvents] = useState([]);
  const [eventState, setEventState] = useState(null);
  const [resetTypehead, setResetTypehead] = useState(null);
  const [offset, setOffset] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (eventState) {
          const response = await fetchEventIdByStateTotalRecords(eventState);
          setTotalRecords(response.data.countEvent);
        }
      } catch (error) {
        setError(error.message || "Ocorreu um erro");
      }
    };
    fetchData();
  }, [eventState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchEventIdByState(eventState, limit, offset);
        setItemEvents(response.data?.event?.map((event) => event.id));
      } catch (error) {
        setError(error.message || "Ocorreu um erro");
      }
    };
    fetchData();
  }, [eventState, limit, offset]);

  const handlePreviousClick = () => {
    if (offset - limit >= 0) {
      setOffset(offset - limit);
    }
  };

  const handleNextClick = () => {
    if (offset + limit < totalRecords) {
      setOffset(offset + limit);
    }
  };

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="m-5 text-center">
          <h1>Acompanhar Processos</h1>
        </div>

        <div className="mt-3 mb-3 rounded bg-light p-4">
          <h2>Filtro</h2>
          <div className="mb-3"></div>
          <div className="mb-3">
            <label className="form-label"> Estágio: </label>
            <TypeHead
              name="stateIdTypehead"
              placeholder="Estágio dos processos que deseja visualizar."
              table="state"
              labelColumn="state"
              valueColumn="id"
              auxiliaryColumn=""
              idSize={0}
              changeToClearSelection={resetTypehead}
              onChange={(e) => {
                setResetTypehead(!resetTypehead);
                setEventState(e.value);
              }}
            />
          </div>
        </div>

        <div className="mt-3 mb-3 rounded bg-light p-4">
          <EventCardDetailedSelectionList
            eventIds={itemEvents}
            noEventsMessage="Não há eventos em aguardo ou execução para este item"
            onSelect={(eventId, e) =>
              navigate(
                `/usage/manufacturing/selectItem/selectevent/event/eventpagemanufacturing/${eventId}`
              )
            }
          />
        </div>

        <ErrorOutput error={error} />

        <div className="m-0 mt-4 mb-4 rounded p-4 row justify-content-center">
          <button
            type="button"
            className="col-5 m-3 btn btn-primary btn-lg"
            onClick={handlePreviousClick}
            disabled={offset === 0}
          >
            Anterior
          </button>
          <button
            type="button"
            className="col-5 m-3 btn btn-primary btn-lg"
            onClick={handleNextClick}
            disabled={offset + limit >= totalRecords}
          >
            Próximo
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EventOvervViewPage;
