import React, { useState, useEffect } from "react";
import getBomStructure from "../../../../../utils/graphql/controllers/getBomStructure";
import ChildrenInputRelationList from "./ChildrenInputRelationList";

const InputSelector = ({ name, bomId, onChange }) => {
  const [childList, setChildList] = useState([]);
  const [displayChildList, setdisplayChildList] = useState(false);

  useEffect(() => {
    const AsyncFetchBomData = async () => {
      try {
        const { data } = await getBomStructure(bomId);
        if (data && data.children) {
          setChildList(data.children);
          setdisplayChildList(true);
        }
      } catch (error) {
        console.error("Error fetching part data:", error);
      }
    };
    if (bomId) {
      AsyncFetchBomData();
    } else {
      if (displayChildList) {
        onChange({ id: name, value: { inputItemId: "" }});
        setdisplayChildList(false);
      }
    }
  }, [bomId, name, onChange, displayChildList]);

  function onSelectedValuesChange(relationList) {
    const inputToCreate = [];
    Object.entries(relationList).forEach(([key, value]) => {
      let inputIdObj = {};
      if (value.selectedValues) {
        inputIdObj = value.selectedValues;
      }

      if (inputIdObj) {
        Object.keys(inputIdObj).forEach((innerKey) => {
          const inputID = inputIdObj[innerKey];
          if (inputID !== "") {
            inputToCreate.push(inputID);
          }
        });
      }
    });
    onChange({ id: name, value: { inputItemId: inputToCreate } });
  }

  return (
    <>
      {displayChildList && (
        <div>
          <ChildrenInputRelationList
            children={childList}
            onSelectedValuesChange={onSelectedValuesChange}
          />
        </div>
      )}
    </>
  );
};

export default InputSelector;
