import { useState, useEffect } from "react";
import DocumentCard from "../../../../../Output/Card/DocumentCard";
import fetchEventDocuments from "../../../../../../utils/graphql/fetches/fetchEventDocuments";

const ManufacturingBodyDocumentsTab = ({ eventId }) => {
  const [processDocuments, setProcessDocuments] = useState([]);
  const [parentDocuments, setParentDocuments] = useState([]);
  const [childrenDocuments, setChildrenDocuments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setProcessDocuments([]);
      setParentDocuments([]);
      setChildrenDocuments([]);
      try {
        const response = await fetchEventDocuments(eventId);
        let processDocumentsAux = new Set();
        for (const document of response.data.event[0].Bom.Process.Documents) {
          processDocumentsAux.add(document.id);
        }
        setProcessDocuments(Array.from(processDocumentsAux));

        let ParentDocumentsAux = new Set();
        for (const parent of response.data.event[0].Bom.Parents) {
          for (const document of parent.Part.Documents) {
            ParentDocumentsAux.add(document.id);
          }
        }
        setParentDocuments(Array.from(ParentDocumentsAux));

        let ChildrenDocumentsAux = new Set();
        for (const child of response.data.event[0].Bom.Children) {
          for (const document of child.Part.Documents) {
            ChildrenDocumentsAux.add(document.id);
          }
        }
        setChildrenDocuments(Array.from(ChildrenDocumentsAux));
      } catch (error) {
        console.error("Error fetching output data:", error);
      }
    };

    fetchData();
  }, [eventId]);

  if (
    processDocuments.length < 1 &&
    parentDocuments.length < 1 &&
    childrenDocuments.length < 1
  ) {
    return (
      <div className="m-5 text-center">
        <h3>Este Evento Não Possui Documentos disponíveis para consulta.</h3>
      </div>
    );
  }

  return (
    <div className="mt-3">
      <h1>Relacionados ao Processo: </h1>
      <div>
        {processDocuments.map((doc, index) => {
          const key = `${"process"}${index}${doc.id}`;
          return (
            <div className="m-3" key={key + ".div"}>
              <DocumentCard key={key} documentId={doc} />
            </div>
          );
        })}
      </div>

      <h1>Relacionados as Saídas: </h1>
      <div>
        {parentDocuments.map((doc, index) => {
          const key = `${"parents"}${index}${doc}`;
          return (
            <div className="m-3" key={key + ".div"}>
              <DocumentCard key={key} documentId={doc} />
            </div>
          );
        })}
      </div>

      <h1>Relacionados as Entradas: </h1>
      <div>
        {childrenDocuments.map((doc, index) => {
          const key = `${"children"}${index}${doc}`;
          return (
            <div className="m-3" key={key + ".div"}>
              <DocumentCard key={key} documentId={doc} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ManufacturingBodyDocumentsTab;
