import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchItemAsInputEvents = async (itemId) => {
  try {    
    const query = `{
      item(id: "${itemId}") {
        Inputs {
          Event {
            id
            initDate
            endDate
            creator {
              id
              firstName
              lastName
              email
            }
            actor {
              id
              firstName
              lastName
              email
            }
            State {
              id
              state
            }
          }
        }
      }
    }`;
    const response = await fetchGraphQLQuery(query);
    const inputs = response.data.item[0].Inputs || [];
    const events = inputs.map(input => input.Event);
    return { ...response, data: { events }};
  } catch(error) {
    console.error('Error fetching events for itemId:', itemId);
  }
};

export default fetchItemAsInputEvents;
