import config from '../config/config';

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

const httpLink = createHttpLink({
  uri: config.graphQLEndpoint,
});

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});






