const YesNoAlert = ({
  message,
  title,
  showModal,
  handleYes,
  handleNo,
  handleClose,
}) => {
  return (
    <div>
      {showModal && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">{title}</h2>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <strong>{message}</strong>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleNo}
                >
                  Não
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleYes}
                >
                  Sim
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default YesNoAlert;
