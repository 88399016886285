import React, { useState, useEffect, useCallback } from 'react';
import Head from "../../../components/Header";
import Navbar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import TypeHead from "../../../components/Input/TypeHead/TypeHead";
import BomPartIdTypeHead from "../../../components/Input/TypeHead/BomPartIdTypeHead";
import ErrorOutput from "../../../components/Output/ErrorOutput";
import fetchPartBomIds from "../../../utils/graphql/fetches/fetchPartBomIds";
import LinkableBom from "../../../components/PageElements/crud/bom/read/LinkableBom";

const ReadBomPage = () => {
  const [error, setError] = useState(null);
  const [partId, setPartId] = useState(null);
  const [bomId, setbomId] = useState(null);
  const [changeToClearBomIdTypeHead, setChangeToClearBomIdTypeHead] = useState(null);
  const [changeToClearPartIdTypeHead, setChangeToClearPartIdTypeHead] = useState(null);
  const [bomNavigationHistory, setBomNavigationHistory] = useState([]);

  const navigateBomid = useCallback((id) => {
    if (bomId === id) {
      return;
    }
    if (bomId) {
      setBomNavigationHistory((prevHistory) => [...prevHistory, bomId]);
    }
    setbomId(id);
  }, [bomId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ids = await fetchPartBomIds(partId);
        if (ids && ids.length === 1) {
          navigateBomid(ids[0]);
          setChangeToClearBomIdTypeHead(prev => !prev);
          setChangeToClearPartIdTypeHead(prev => !prev);
          setPartId("");
        }
      } catch (error) {
        setError(error.message || "ocorreu um erro.");
      }
    };
    fetchData();
  }, [partId, navigateBomid]);

  useEffect(
    (changeToClearBomIdTypeHead, changeToClearPartIdTypeHead) => {
      setChangeToClearBomIdTypeHead(!changeToClearBomIdTypeHead);
      setChangeToClearPartIdTypeHead(!changeToClearPartIdTypeHead);
    },
    [bomId]
  );

  const onSelect = (clickedPartId) => {
    navigateBomid("");
    setPartId(clickedPartId);
  };

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="m-5 text-center">
          <h1>Verificar BOM</h1>
        </div>
        <div className="mt-3 mb-3 rounded bg-light p-4">
          <h3>Selecione sua Bom </h3>
          <label className="form-label"> Filtre por Peça: </label>
          <TypeHead
            name="partId"
            table="part"
            placeholder={"Filtre as BOMS pela peça"}
            labelColumn="name"
            valueColumn="id"
            auxiliarColumn="description"
            changeToClearSelection={changeToClearPartIdTypeHead}
            onChange={(e) => {
              setPartId(e.value);
              navigateBomid("");
              setChangeToClearBomIdTypeHead(!changeToClearBomIdTypeHead);
            }}
          />
          <div className=" row m-2" />
          <BomPartIdTypeHead
            typeHeadOptions={{
              id: "itemId",
              placeholder: "UUID da BOM",
            }}
            fetchOptionsArguments={{ partId: partId }}
            changeToClearSelection={changeToClearBomIdTypeHead}
            onChange={(e) => {
              navigateBomid(e.value);
            }}
          />
        </div>
        <div className="mt-3 mb-3 rounded bg-light p-4">
          <h3>Resultado: </h3>
          {bomId ? (
            <>
              <strong>instruções: </strong>
              <p>
                Clique nas linhas da BOM para verificar as montagems de níveis
                mais baixos.
              </p>
              <LinkableBom bomId={bomId} onSelect={onSelect} />
              <div className="d-flex justify-content-end m-3">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setbomId(
                      bomNavigationHistory[bomNavigationHistory.length - 1]
                    );
                    setBomNavigationHistory((prev) => prev.slice(0, -1));
                  }}
                >
                  Voltar
                </button>
              </div>
            </>
          ) : (
            <>Há mais de uma bom para este conjunto de possibilidades</>
          )}
        </div>
        <ErrorOutput error={error} />
      </div>
      <Footer />
    </div>
  );
};

export default ReadBomPage;
