import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchItemDetails = async (itemId) => {
  try {
    const query = `{
      item(id: "${itemId}") {
          id
          description
          Part {
            id
            name
            nickname
            description
            date
            version
            Type {
              category
              type
              subType
            }
            Unit {
              symbol
            }
            Supply {
              supply
            }
          }
        }
      }`;
      
    const response = await fetchGraphQLQuery(query);
    return response;
  } catch(error) {
    console.error('Error fetching item details:', error);
  }
};

export default fetchItemDetails;
