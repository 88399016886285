import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchItemPart = async (itemId) => {
  try {
    const query = `{
      item(id: "${itemId}") {
        Part {
          id
          name
          description
        }
      }
    }`;
      
    let response = await fetchGraphQLQuery(query);
    response = {...response, data:{part: response.data.item[0].Part}}
    return response;
  } catch(error) {
    console.error('Error fetching item part:', error);
  }
};

export default fetchItemPart;
