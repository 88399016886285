import { useState } from "react";

import UuidText from "../Text/UuidText";
import testRequired from "./utils/testRequired";
import TypeHeadMultiSelection from "../TypeHeadMultiSelection/TypeHeadMultiSelection";

const DocumentForm = ({ onSubmit }) => {
  const [resetForm, setResetForm] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    description: "",
    authors: [],
    version: "",
    url: "",
    date: "",
    part: [],
    process: [],
  });

  const clearForm = () => {
    setFormData({
      id: "",
      title: "",
      description: "",
      authors: [],
      version: "",
      url: "",
      date: "",
      part: [],
      process: [],
    });
    setResetForm(!resetForm);
  };

  const onChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const innerOnSubmit = (e) => {
    e.preventDefault();
    if (testRequired(formData, ["part", "process"])) {
      alert("Por favor Preencha todos os campos");
      return;
    }
    onSubmit(formData);
    clearForm();
  };

  return (
    <form onSubmit={innerOnSubmit}>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre o Documento</h2>
        <div className="mb-3">
          <strong>Instruções:</strong>
          <p>
            Documentos são registros com informações básicas sobre manuais,
            tutoriais, videos e outros formatos de documentaçao sobre os
            projetos e processos grupo. Além disso é interessante manter-se um
            link de acesso para que o arquivo possa ser encontrado através deste
            sistema.
          </p>
        </div>
        <div className="mb-3">
          <UuidText
            id="id"
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Título:
          </label>
          <textarea
            className="form-control"
            id="title"
            name="title"
            placeholder="Título do Documento"
            value={formData.title}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Descrição:
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            placeholder="Descreva o conteúdo do documento e sua finalidade."
            value={formData.description}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label"> Autores: </label>
          <TypeHeadMultiSelection
            name={"authors"}
            placeholder={"Quem são os o autores deste documento."}
            table={"person"}
            labelColumn={"firstName"}
            valueColumn={"id"}
            onChange={(e) => onChange(e.id, e.value)}
            auxiliarColumn={"lastName"}
            separator={" "}
            idSize={6}
            columns={["UUID", "Nome", "Sobrenome"]}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="version" className="form-label">
            Versão:
          </label>
          <input
            type="text"
            className="form-control"
            id="version"
            name="version"
            placeholder="Versionamento do documento i.e '1.2.1'"
            value={formData.version}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="url" className="form-label">
            Link de Acesso:
          </label>
          <input
            type="text"
            className="form-control"
            id="url"
            name="url"
            placeholder="Link de acesso para o documento."
            value={formData.url}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="date" className="form-label">
            Data de Publicação:
          </label>
          <input
            type="date"
            className="form-control"
            id="date"
            name="date"
            value={formData.date}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="part" className="form-label">
            Projetos e Peças Relacionados:
          </label>
          <TypeHeadMultiSelection
            name="part"
            placeholder="Projetos e peças que se relacionam ao documento"
            table="part"
            labelColumn="name"
            valueColumn="id"
            columns={["UUID", "Nome"]}
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="process" className="form-label">
            Processos Relacionados:
          </label>
          <TypeHeadMultiSelection
            name="process"
            placeholder="Processos aos quais se relacionam o documento"
            table="process"
            labelColumn="name"
            valueColumn="id"
            columns={["UUID", "Nome"]}
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mt-5 row">
          <button type="submit" className="btn btn-primary btn-lg">
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
};

export default DocumentForm;
