import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchItemHistory = async (itemId) => {
  try {    
    const query = `{
      item(id: "${itemId}") {
        Histories {
          id
          date
          comment
          Status {
            name
          }
          Person {
            email
          }
        }
      }
    }`;
    const response = await fetchGraphQLQuery(query);
    return ({...response, data: {Histories: response.data.item[0].Histories}});
  } catch(error) {
    console.error('Error fetching item history:', error);
  }
};

export default fetchItemHistory;
