import { useState } from "react";
import UuidText from "../Text/UuidText";
import TypeHead from "../TypeHead/TypeHead";
import BomPartIdTypeHead from "../TypeHead/BomPartIdTypeHead";
import BomInfo from "../../Output/BomInfo/BomInfo";
import testRequired from "./utils/testRequired";

const EventForm = ({ onSubmit }) => {
  const [resetForm, setResetForm] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    bomId: "",
    creatorId: "",
    partId: "",
  });

  const clearForm = () => {
    setFormData({
      id: "",
      bomId: "",
      creatorId: "",
      partId: "",
    });
    setResetForm(!resetForm);
  };

  const onChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const innerOnSubmit = (e) => {
    e.preventDefault();
    if (testRequired(formData)) {
      alert("Por favor, preencha todos os campos obrigatórios.");
      return;
    }
    onSubmit(formData);
    clearForm();
  };

  return (
    <form onSubmit={innerOnSubmit}>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre o Evento</h2>
        <div className="mb-3">
          <strong>Instruções:</strong>
          <p>
            Eventos serão criados no estado de
            <strong>'Aguardando'</strong>, de forma que podem a qualquer momento
            ser executados desde que sejam selecionadas suas entradas e saídas.
          </p>
          <p>
            Não será determinado até a execução as entradas, saídas, atores ou
            datas de início e fim do evento.
          </p>
        </div>
        <div className="mb-3">
          <UuidText
            id="id"
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label className="form-label"> Responsável: </label>
          <TypeHead
            name={"creatorId"}
            placeholder={"Quem é o responsável por esse evento."}
            table={"person"}
            labelColumn={"firstName"}
            valueColumn={"id"}
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            auxiliarColumn={"lastName"}
            separator={" "}
            idSize={0}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            {" "}
            Filtre por Parte Para encontrar Sua Bom:{" "}
          </label>
          <TypeHead
            name={"partId"}
            placeholder={"Comece digitando o nome da parte."}
            table={"part"}
            labelColumn={"name"}
            valueColumn={"id"}
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            auxiliarColumn={"description"}
            separator={" "}
            idSize={0}
            required={true}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label className="form-label"> Bom: </label>
          <BomPartIdTypeHead
            typeHeadOptions={{
              id: "bomId",
              placeholder: "Escolha a BOM do utilizada no evento",
            }}
            fetchOptionsArguments={{ partId: formData.partId }}
            changeToClearSelection={resetForm}
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
          />
        </div>
        <div className="mb-3">
          <BomInfo bomId={formData.bomId} sideBySide={false} />
        </div>
        <div className="mt-5 row">
          <button type="submit" className="btn btn-primary btn-lg">
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
};

export default EventForm;
