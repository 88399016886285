import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchDocumentDetails = async (documentId) => {
  try {
    const query = `{
        document (id: "${documentId}") {
          id
          description
          title
          url
          People {
            id
            firstName
            lastName
            email
          }
          Parts  {
            id
            name
          }
          Processes {
            id
            name
          }
        }
      }`;
      
    const response = await fetchGraphQLQuery(query);
    return response;
  } catch(error) {
    console.error('Error fetching event details:', error);
  }
};

export default fetchDocumentDetails;
