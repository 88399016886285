import config from '../../config/config';
import postController from '../postController';

// Function to fetch data from GraphQL API
async function withdrawProduct(withdrawProductInput) {
  try {
    // initialize variables
    const variables = withdrawProductInput;

    // Make a GET request
    const response = await postController(variables, config.graphQLServerHost, config.withdrawProductUrl);
    return response;

  } catch (error) {
    console.error('Error posting data to controller withdrawProduct:', error);
    throw(error);
  }
}

export default withdrawProduct;