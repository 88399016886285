import fetchGraphQLQuery from '../../../../../utils/graphql/fetchGraphQLQuery';

async function getTypeHeadOptionsCreateBom({input, idSize=6, separator=' - '}) {
  // Modify the GraphQL query to include a filter based on the input
  const query = `
  {
    part( where: { name: { like: "%${input}%" } } ) {
      id
      name
      description
      Unit {
        symbol
      }
    }
  }
`;

  try {
    // Fetch query
    const options = await fetchGraphQLQuery(query);

    if (options && options.data && options.data.part) {
      // Extract options and create an array of objects
      const optionsArray = options.data.part.map(option => {
        let label = option.name;

        if (idSize !== 0 && idSize < option.id.length) {
          label = option.id.substr(option.id.length - idSize) + separator + label;
        }

        label = label + separator + option.description;

        return {
          value: option.id,
          mainLabel: option.name,
          auxiliarColumn: option.description,
          idSize: idSize,
          valueEnd: option.id.substr(option.id.length - idSize),
          label: label,
          unit: option.Unit.symbol
        };
      });

      return optionsArray; // Return the array of options
    }
    
    return null;

  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default getTypeHeadOptionsCreateBom;
