import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchItemAsOutputEvents = async (itemId) => {
  try {    
    const query = `{
      item(id: "${itemId}") {
        Outputs {
          Event {
            id
            initDate
            endDate
            creator {
              id
              firstName
              lastName
              email
            }
            actor {
              id
              firstName
              lastName
              email
            }
            State {
              id
              state
            }
            Bom {
              Process {
                name
              }
            }
          }
        }
      }
    }`;
    const response = await fetchGraphQLQuery(query);
    const outputs = response.data.item[0].Outputs || [];
    const events = outputs.map(output => output.Event);
    return { ...response, data: { events }};
  } catch(error) {
    console.error('Error fetching events for itemId:', itemId);
  }
};

export default fetchItemAsOutputEvents;
