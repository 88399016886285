import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchEventIdByState = async (stateId, limit = 50, offset = 0) => {
    try {
      const query = `{
          event(where: {stateId: "${stateId}"}, limit: ${limit}, offset: ${offset}) {
            id
          }
      }`;
  
      const response = await fetchGraphQLQuery(query);
      return response;
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  
  export default fetchEventIdByState;
