// EventCardDetailedSelectable.js
import React, { useState, useEffect } from 'react';
import fetchEventDeepDetails from '../../../utils/graphql/fetches/fetchEventDeepDetails.js';
import ChildrenList from '../../Output/List/ChildrenList'; // Adjust the path accordingly
import "../../../styles/selectable-card.css";

const EventCardDetailedSelectable = ({ eventId, onSelect, isSelected }) => {
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchEventDeepDetails(eventId);
        setEvent(response.data.event[0]);
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };
    fetchData();
  }, [eventId]);

  const formatDate = (dateString) => {
    if (!dateString) {
      return '';
    }

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (timeString) => {
    if (!timeString) {
      return '';
    }

    const options = { hour: 'numeric', minute: 'numeric' };
    return new Date(timeString).toLocaleTimeString(undefined, options);
  };

  const formatDateTime = (dateString) => {
    if (!dateString) {
      return '';
    }

    const date = formatDate(dateString);
    const time = formatTime(dateString);

    return date + ' as ' + time;
  };

  return (
    <div
      className={`card selectable-card ${isSelected ? 'border-primary' : ''}`}
      style={{ backgroundColor: isSelected ? '#cce5ff' : '' }}
      onClick={(e) => onSelect(e)}
    >
      <div className="card-body">
        <div className='row'>
          <h5 className="card-title"><strong>Evento:</strong> {event?.id}</h5>
          <div className='col-5'>
            <p className="card-text">
              <strong>Processo: </strong> {event?.Bom?.Process?.name} <br />
            </p>
            <p className="card-text">
              <strong>Estado:</strong> {event?.State?.state} <br />
              <strong>Criador:</strong> {event?.creator?.firstName} {event?.creator?.lastName} <br />
              <strong>Ator:</strong> {event?.actor?.firstName} {event?.actor?.lastName} <br />
              <strong>Início:</strong> {formatDateTime(event?.initDate)} <br />
              <strong>Fim:</strong> {formatDateTime(event?.endDate)}
            </p>
          </div>
          <div className='col-6'>
            <h6>Entradas:</h6>
            <ChildrenList bomId={event?.Bom?.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCardDetailedSelectable;
