import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../../../../../components/Header";
import Navbar from "../../../../../components/NavBar";
import Footer from "../../../../../components/Footer";
import fetchItemPart from "../../../../../utils/graphql/fetches/fetchItemPart";
import PartCard from "../../../../../components/Output/Card/PartCard";
import EventCardDetailedSelectionList from "../../../../../components/Input/Mixed/EventCardDetailedSelectionList";
import fetchItemAsOutputEvents from "../../../../../utils/graphql/fetches/fetchItemAsOutputEvents";
import config from "../../../../../utils/config/config";

const SelectEventManufacturingPage = () => {
  const { itemId } = useParams();
  const [partId, setPartId] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [itemEvents, setItemEvents] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchItemPart(itemId);
      setPartId(response.data.part.id);
    };
    fetchData();
  }, [itemId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchItemAsOutputEvents(itemId);
      const filteredEvents = response.data.events.filter(
        (event) => event.State.id !== config.fixedIds.state.finalized
      );
      const filteredEventIds = filteredEvents.map((event) => event.id);
      setItemEvents(filteredEventIds);
    };
    fetchData();
  }, [itemId]);

  const handleNavigateWithEvent = () => {
    if (selectedEventId) {
      const nextPageUrl = `/usage/manufacturing/selectItem/selectevent/event/eventpagemanufacturing/${selectedEventId}`;
      navigate(nextPageUrl);
    } else {
      alert("Por favor selecione um evento antes de ir para a próxima página.");
    }
  };

  const handleCreateEvent = () => {
    const nextPageUrl = `/usage/manufacturing/selectItem/selectevent/createeventmanufacturing/${itemId}`;
    navigate(nextPageUrl);
  };

  const handleEventSelection = (eventId, e) => {
    setSelectedEventId(eventId);
  };

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
        <div className="container">
          <div className="mt-5" />        
          <h1>Página de Fabricação</h1>
          <div className="row">
            <div className="col-md-8">
              <div style={{ maxWidth: "450px", marginBottom: "16px" }}>
                <PartCard partId={partId} />
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-md-end">
              <div>
                <button
                  className="btn btn-primary mr-2"
                  onClick={handleNavigateWithEvent}
                >
                  Continuar Evento
                </button>
                <button
                  className="btn btn-success m-2"
                  onClick={handleCreateEvent}
                >
                  Criar Evento
                </button>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-3 rounded bg-light p-4">
            <h2>Selecionar Evento de Fabricação</h2>
            <div className="mb-3">
              <p>
                Se o seu item possui eventos em aberto associados, escolha
                aquele que deseja utilizar para fabrica-lo:
              </p>
              <div style={{ marginBottom: "16px" }}>
                <EventCardDetailedSelectionList
                  eventIds={itemEvents}
                  noEventsMessage="Não há eventos em aguardo ou execução para este item"
                  onSelect={handleEventSelection}
                />
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </div>
  );
};

export default SelectEventManufacturingPage;
