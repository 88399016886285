import TypeHeadGeneric from "./TypeHeadGeneric";
import ItemChildIdTypeHeadFecthOptions from "./ItemChildIdTypeHeadFecthOptions";

const ItemChildIdTypeHead = ({
  typeHeadOptions,
  fetchOptionsArguments,
  changeToClearSelection,
  onChange,
}) => {
  const { id, disabled, placeholder } = typeHeadOptions;

  return (
    <TypeHeadGeneric
      onChange={onChange}
      fetchOptionsFunction={ItemChildIdTypeHeadFecthOptions}
      fetchOptionsArguments={{ childId: fetchOptionsArguments.childId }}
      typeHeadOptions={{
        id: id,
        disabled: disabled || false,
        placeholder: placeholder,
      }}
      changeToClearSelection={changeToClearSelection}
    />
  );
};

export default ItemChildIdTypeHead;
