import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchEventCountByState = async (stateId) => {
    try {
      const query = `{
        countEvent(where: {stateId: "${stateId}"})
      }`;
  
      const response = await fetchGraphQLQuery(query);
      return response;
    } catch (error) {
      console.error("Error fetching count events:", error);
    }
  };
  
export default fetchEventCountByState;
