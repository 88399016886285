import TypeHeadFetchOptions from "./TypeHeadFetchOptions";
import TypeHeadGeneric from "./TypeHeadGeneric";

const TypeHead = ({
  name,
  placeholder,
  table,
  labelColumn,
  valueColumn,
  auxiliarColumn = "",
  changeToClearSelection,
  idSize = 6,
  separator = " - ",
  onChange,
  disabled = false,
  onInputChange = null
}) => {
  return (
    <TypeHeadGeneric
      onChange={onChange}
      fetchOptionsFunction={TypeHeadFetchOptions}
      fetchOptionsArguments={{
        table: table,
        labelColumn: labelColumn,
        valueColumn: valueColumn,
        auxiliarColumn: auxiliarColumn,
        idSize: idSize,
        separator: separator,
      }}
      typeHeadOptions={{
        id: name,
        disabled: disabled,
        placeholder: placeholder,
        onInputChange: onInputChange || (() => {})
      }}
      changeToClearSelection={changeToClearSelection}
    />
  );
};

export default TypeHead;
