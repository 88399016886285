import fetchGraphQLQuery from '../fetchGraphQLQuery';

const fetchPartItems = async (partId, input) => {
  try {
    let query = `{ part`;

    if (partId) {
      query += `(id: "${partId}")`;
    }

    query += ` { Items`;

    if (input) {
      query += `(where: { id: { like: "%${input}%" } })`;
    }

    query += `{
        id
        description
      }`;

    query += `}}`;

    let response = await fetchGraphQLQuery(query);
    
    // Flatten all parts without assuming a specific index
    const flattenedParts = response?.data?.part?.flatMap(part => part.Items) || [];

    response = { ...response, data: { Items: flattenedParts } };

    return response;
  } catch (error) {
    console.error('Error fetching part items:', error);
    return null; // Return null or handle the error appropriately
  }
};

export default fetchPartItems;
