import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchPartAuthorDetails = async (partId) => {
  try {
    const query = `{
      part(id: "${partId}") {
        Person {
          firstName
          lastName
          email
        }
      }
    }`;
      
    const response = await fetchGraphQLQuery(query);
    return response
  } catch(error) {
    console.error('Error fetching part author details:', error);
  }
};

export default fetchPartAuthorDetails;
