import React from 'react';
import '../../styles/error-container.css'
import QueryTables from './Table/QueryTables';

const QueryOutput = ({queryResult, containerTitles, MajorContainerTitle, columnsToRemove = ['__typename', 'createdAt', 'updatedAt', 'deletedAt'], columnsOrder = [] }) => {
  
  if (!queryResult) {
    return <></>;
  }

  const { data, errors } = queryResult;

  if (errors) {
    return (
      <div className="error-container">
        <h2>Erro:</h2>
        {errors.map((error, index) => (
          <div key={index}>
            <p>{`${error.code}: ${error.message}`}</p>
          </div>
        ))}
      </div>
    );
  }


  if (areArraysEmpty(data)) {
    return <></>;
  }

  if (hasAllValuesNull(data)) {
    return <></>;
  }

  return (
    <>
      <div className="container mt-3 mb-3 rounded bg-light p-4" id="tables-container">
        <h2 id="results-header">{MajorContainerTitle}</h2>
        <QueryTables queryData={data} containerTitles={containerTitles} columnsToRemove={columnsToRemove} columnsOrder={columnsOrder}/>
      </div>
    </>
  );
};

function areArraysEmpty(obj) {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      if (obj[key].some(item => item !== null && item !== undefined)) {
        return false;
      }
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (!areArraysEmpty(obj[key])) {
        return false;
      }
    }
  }
  return true;
}

function hasAllValuesNull(obj) {
  for (const key in obj) {
    if (key === '__typename' || key === 'loading' || key === 'networkStatus') {
      continue; // Skip these keys from evaluation
    }
    if (Array.isArray(obj[key])) {
      if (obj[key].some(item => !hasAllValuesNull(item))) {
        return false; // Array contains at least one non-null value
      }
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      // Recursively check nested objects
      if (!hasAllValuesNull(obj[key])) {
        return false; // Nested object contains at least one non-null value
      }
    } else if (obj[key] !== null && obj[key] !== undefined) {
      return false; // Found a non-null value that's not excluded
    }
  }
  return true; // All values (excluding specified keys) are null or undefined
}




export default QueryOutput;
