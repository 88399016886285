import config from '../../config/config';
import fetchController from '../fetchController';

// Function to fetch data from GraphQL API
async function getPartStarmap(partId) {
  try {
    // initialize variables
    const variables = {partId: partId}

    // Make a GET request
    const response = await fetchController(variables, config.graphQLServerHost, config.getPartStarmapUrl);
    return response;

  } catch (error) {
    console.error('Error fetching data from controller GetPartStarMap:', error);
  }
}

export default getPartStarmap;