import React from 'react';
import '../../../styles/error-container.css'
import MultipleBomTables from './MultipleBomTables';

const BomOutput = ({ bomResult}) => {
  if (!bomResult) {
    return (<></>);
  }

  if (bomResult?.data?.[0]?.bom === undefined) {
    return (<></>);
  }

  const { data, errors } = bomResult;
  if (errors) {
    return (
      <div className="error-container">
        <h2>Error:</h2>
        {errors.map((error, index) => (
          <div key={index}>
            <p>{`${error.code}: ${error.message}`}</p>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4 mt-4" id="tables-container">
        <MultipleBomTables multipleBomStructures={data}/>
      </div>
    </div>
  );
};

export default BomOutput;
