import TypeHeadGeneric from "./TypeHeadGeneric";
import BomPartIdTypeHeadFetchOptions from "./BomPartIdTypeHeadFetchOptions";

const BomPartIdTypeHead = ({
  typeHeadOptions,
  fetchOptionsArguments,
  changeToClearSelection,
  onChange,
}) => {
  const { id, disabled, placeholder } = typeHeadOptions;

  return (
    <TypeHeadGeneric
      onChange={onChange}
      fetchOptionsFunction={BomPartIdTypeHeadFetchOptions}
      fetchOptionsArguments={{ partId: fetchOptionsArguments.partId }}
      typeHeadOptions={{
        id: id,
        disabled: disabled || false,
        placeholder: placeholder,
      }}
      changeToClearSelection={changeToClearSelection}
    />
  );
};

export default BomPartIdTypeHead;
