import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchEventInputs = async (eventId) => {
  try {
    const query = `{
        event(id: "${eventId}") {
          id
          initDate
          endDate
          Inputs {
            id
            quantityIndex
            Item {
              Part {
                name
              }
            }
          }
        }
      }`;
      
    const response = await fetchGraphQLQuery(query);
    return response;
  } catch(error) {
    console.error('Error fetching event details:', error);
  }
};

export default fetchEventInputs;
