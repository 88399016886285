import React, { useState, useEffect } from 'react';
import fetchItemHistory from '../../../utils/graphql/fetches/fetchItemHistory'
import QueryOutput from '../QueryOutput'

const ItemHistory = ({ itemId }) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let response = await fetchItemHistory(itemId);
      setResult(response);
    };
    fetchData();
  }, [itemId]);


  if (!itemId) {
    return (<></>);
  }

  return (
    <>
      <QueryOutput
        queryResult={result}
        containerTitles={[]}
        MajorContainerTitle={"Histórico do seu Item"}
      />
    </>
  );
};

export default ItemHistory;