import getPartItemsAvaiableForOutput from "../../../utils/graphql/controllers/getPartItemsAvaiableForOutput";

async function ItemAvaiableForInputByPartTypeheadQrCodeFetchOptions({
  partId,
  input }) {
  try {
    if (!partId) {
      return [];
    }

    const response = await getPartItemsAvaiableForOutput(partId, input);
    let options = [];
    if (response && response.data) {
      options = response.data.map((item) => ({
        label: item.id,
        value: item.id,
        auxLabel: "",
      }));
      return options;
    }
  } catch (error) {
    console.error("Error fetching options:", error);
  }
}

export default ItemAvaiableForInputByPartTypeheadQrCodeFetchOptions;
