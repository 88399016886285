import TypeHeadQRCodeGeneric from "./TypeHeadQRCodeGeneric";
import ItemAvaiableForOutputtByPartTypeheadQrCodeFetchOptions from "./ItemAvaiableForOutputtByPartTypeheadQrCodeFetchOptions";

const ItemAvaiableForOutputByPartTypeheadQrCode = ({
  id,
  placeholder,
  onChange,
  partId = "",
  changeToClearSelection = false,
}) => {
  return (
    <TypeHeadQRCodeGeneric
      onChange={onChange}
      fetchOptionsFunction={
        ItemAvaiableForOutputtByPartTypeheadQrCodeFetchOptions
      }
      fetchOptionsArguments={{ partId: partId }}
      typeHeadOptions={{ id: id, placeholder: placeholder }}
      changeToClearSelection={changeToClearSelection}
    />
  );
};

export default ItemAvaiableForOutputByPartTypeheadQrCode;
