import React, { useState } from 'react';
import EventCardDetailedSelectable from './EventCardDetailedSelectable'; // Adjust the path accordingly

const EventCardDetailedSelectionList = ({ eventIds, onSelect, noEventsMessage=''}) => {
  const [selectedCards, setSelectedCards] = useState([]);

  if (!eventIds || eventIds.length === 0) {
    return <div><strong>{noEventsMessage}</strong></div>;
  }

  const handleCardSelect = (eventId, e) => {
    // Check if the card is already selected
    if (selectedCards.includes(eventId)) {
      setSelectedCards(selectedCards.filter((id) => id !== eventId));
    } else {
      // If not selected, add it to the selected cards
      setSelectedCards([eventId]);
    }
    onSelect(eventId, e);
  };
  
  return (
    <div>
      {eventIds.map((eventId) => (
        <div key={eventId} style={{ marginBottom: '20px' }}>
          <EventCardDetailedSelectable
            eventId={eventId}
            onSelect={(e) => handleCardSelect(eventId, e)}
            isSelected={selectedCards.includes(eventId)}
          />
        </div>
      ))}
    </div>
  );
};

export default EventCardDetailedSelectionList;
