import config from '../../config/config';
import postController from '../postController';

// Function to fetch data from GraphQL API
async function createPurchase(createPurchaseInput) {
  try {
    // initialize variables
    const variables = createPurchaseInput;

    // Make a GET request
    const response = await postController(variables, config.graphQLServerHost, config.createPurchaseUrl);
    return response;

  } catch (error) {
    console.error('Error posting data to controller createPurchase:', error);
  }
}

export default createPurchase;