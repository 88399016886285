import { useState, useEffect } from "react";
import ManufacturingBodyInputElement from "./ManufacturingBodyInputElement";
import getEventChildrenForManufacturingBody from "../../../../../../utils/graphql/controllers/getEventChildrenForManufacturingBody";

const ManufacturingBodyInputTab = ({ eventId, personId, showEditOptions }) => {
  const [children, setChildren] = useState([]);
  const [refatachTrigger, setRefatachTrigger] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setChildren([]);
      try {
        const childrenResponse = await getEventChildrenForManufacturingBody(
          eventId
        );
        setChildren(childrenResponse.data);
      } catch (error) {
        console.error("Error fetching children data:", error);
      }
    };

    fetchData();
  }, [eventId, refatachTrigger]);

  const reload = () => {
    setRefatachTrigger(!refatachTrigger);
  };

  if (children.length < 1) {
    return (
      <div className="m-5 text-center">
        <h3>A Bom Deste Evento Não Possui Entradas</h3>
      </div>
    );
  }

  return (
    <div>
      {children.map((child) => {
        const key = `${child.partId}.${child.index}`;
        return (
          <div className="m-3" key={key + ".div"}>
            <ManufacturingBodyInputElement
              key={key}
              eventId={eventId}
              partId={child.partId}
              quantityIndex={child.index}
              personId={personId}
              showEditOptions={showEditOptions}
              onChangeCurrentInput={reload}
              totalQuantity={child.quantity}
              countable={child.countable}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ManufacturingBodyInputTab;
