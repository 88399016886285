import { useEffect, useState } from "react";
import Head from "../../../components/Header";
import Navbar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import ErrorOutput from "../../../components/Output/ErrorOutput";
import { Badge, Spinner, Carousel } from "react-bootstrap";
import getRobotsStatus from "../../../utils/graphql/controllers/getRobotsStatus";
import config from "../../../utils/config/config";
import "../../../styles/robots-status-page.css";
import "../../../styles/selectable-card.css";

const StatusPage = () => {
  const [error, setError] = useState(null);
  const [robots, setRobots] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError(null);
        const response = await getRobotsStatus();
        setRobots(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message || "Ocorreu um erro.");
      }
    };
    fetchData();
  }, []);

  function organizeRobotsByGrouping() {
    const organizedRobots = {};
    if (!loading) {
      robots.forEach((robot) => {
        const partGrouping = robot.Item.Part.Groupings[0].name;
        if (!organizedRobots[partGrouping]) {
          organizedRobots[partGrouping] = [];
        }
        organizedRobots[partGrouping].push(robot);
      });
    }
    return organizedRobots;
  }

  function organizeRobotsByPartType(groupedRobots) {
    const organizedRobots = {};
    if (!loading) {
      for (const grouping in groupedRobots) {
        organizedRobots[grouping] = {};
        groupedRobots[grouping].forEach((robot) => {
          const partType = robot.Item.Part.Type.subType;
          if (!organizedRobots[grouping][partType]) {
            organizedRobots[grouping][partType] = [];
          }
          organizedRobots[grouping][partType].push(robot);
        });
        for (const partType in organizedRobots[grouping]) {
          organizedRobots[grouping][partType].sort((a, b) => {
            const descriptionA = a.Item.description.toLowerCase();
            const descriptionB = b.Item.description.toLowerCase();
            if (descriptionA < descriptionB) return -1;
            if (descriptionA > descriptionB) return 1;
            return 0;
          });
        }
      }
    }

    return organizedRobots;
  }

  function getStatusVariant(status) {
    switch (status) {
      case config.fixedIds.status.usage:
        return "bg-warning";
      case config.fixedIds.status.test:
        return "bg-warning";
      case config.fixedIds.status.mustTest:
        return "bg-warning";
      case config.fixedIds.status.maintenence:
        return "bg-danger";
      case config.fixedIds.status.mustMaintenence:
        return "bg-danger";
      case config.fixedIds.status.mustManufacture:
        return "bg-danger";
      case config.fixedIds.status.manufacturing:
        return "bg-danger";
      case config.fixedIds.status.storage:
        return "bg-success";
      case config.fixedIds.status.assembly:
        return "bg-warning";
      case config.fixedIds.status.consumed:
        return "bg-danger";
      default:
        return "";
    }
  }

  const groupedRobots = organizeRobotsByGrouping();
  const organizedRobots = organizeRobotsByPartType(groupedRobots);

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div style={{ minHeight: "7vh" }}></div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Carousel
              className="rounded p-4 bg-light bg-gradient"
              style={{
                minHeight: "60vh",
              }}
            >
              {Object.keys(organizedRobots).map((grouping) => (
                <Carousel.Item key={grouping}>
                  <div className="row m-5 justify-content-center opacity">
                    <h1 className="text-center grouping-title">{grouping}</h1>
                    {Object.keys(organizedRobots[grouping]).map((partType) => (
                      <div key={partType}>
                        <h3>{partType}</h3>
                        <div className="row">
                          {organizedRobots[grouping][partType].map((robot) => (
                            <div
                              key={robot.id}
                              className="col-lg-4 col-md-6 mb-3"
                            >
                              <div className="card selectable-card selectable-card-click-animation">
                                <a
                                  href={`/usage/operation/${robot.Item.id}`}
                                  className="card-link"
                                >
                                  <div className="card-body">
                                    <h5 className="card-title">
                                      {robot.Item.description}
                                    </h5>
                                    <p className="card-text">
                                      <strong>Neste Estado desde: </strong>
                                      {new Date(
                                        robot.Item.Histories[0].date
                                      ).toLocaleDateString()}
                                    </p>
                                    <p className="card-text">
                                      <strong>Ultimo Usuário: </strong>
                                      {
                                        robot.Item.Histories[0].Person.firstName
                                      }{" "}
                                      {robot.Item.Histories[0].Person.lastName}
                                    </p>
                                    <p className="card-text">
                                      <strong>Status: </strong>
                                      <Badge
                                        className={`mx-1 ${getStatusVariant(
                                          robot.Item.Histories[0].Status.id
                                        )}`}
                                      >
                                        {robot.Item.Histories[0].Status.name}
                                      </Badge>
                                    </p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </>
        )}
      <div className="mb-3"></div>
      <ErrorOutput error={error} />
      </div>
      <Footer />
    </div>
  );
};

export default StatusPage;
