import React from 'react';
import GraphQLTable from'../Table/GraphQLTable'
import BomTableHeader from './BomTableHeader';
import BomTableBody from './BomTableBody';

const BomTable = ({ bomSingleTableData, sideBySide}) => {

  const isDataValid = (bomSingleTableData) => {
    if (bomSingleTableData && typeof bomSingleTableData === 'object') { 
      if (bomSingleTableData.hasOwnProperty('bom') && bomSingleTableData.hasOwnProperty('bomStructure')) {
        return true;
      }
    }
    return false;
  };

  // check if data is valid before rendering table
  if (!(isDataValid(bomSingleTableData ))) return <></>;
  
  // construct rendering for the bom identifier table
  const bomData = {id: bomSingleTableData['bom']['id'], processId: bomSingleTableData['bom']['processId'], processName: bomSingleTableData['bomStructure']['process']['name']}
  const bomTable = BomTableHeader(bomData);

  // columns not to show
  const columnsToRemove = ['recordType', '__typename', 'createdAt', 'updatedAt',  'deletedAt', 'id', 'bomId', 'supplyId', 'description', 'supplyid', 'typeId', 'peopleId', 'Typetype', 'Typecategory', 'unitId', 'version', 'date', 'Unitname', 'Unitsymbol', 'Unitcountable']
  const columnsOrder = ["partId", "name", "category", "type", "TypesubType", "Supplysupply", "unit", "quantity"]

  // construct rendering for the parent table
  const parentData = bomSingleTableData['bomStructure']['parents'];
  const parentsTable = GraphQLTable({graphQLSingleTableData: parentData, columnsToRemove: columnsToRemove, columnsOrder: columnsOrder});

  // construct rendering for the children table
  const childrenData = bomSingleTableData['bomStructure']['children'];
  const childrenTable = GraphQLTable({graphQLSingleTableData: childrenData, columnsToRemove: columnsToRemove, columnsOrder: columnsOrder});

  // rendres the three tables in a readable manner
  return (
    <div className="mb-4">
      <div className="p-3 rounded bg-white">
        <div className="mb-4">
          {bomTable}
        </div>
        <BomTableBody parentsTable={parentsTable} childrenTable={childrenTable}  sideBySide={sideBySide} />
      </div>
    </div>
  );
};

export default BomTable;
