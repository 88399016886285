import React from 'react';
import { Helmet } from 'react-helmet-async';

const Head = () => {
  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Warthog Robotics Industry 4.0</title>
    </Helmet>
  );
};

export default Head;
