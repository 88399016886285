import QrScanner from "qr-scanner";

const Scanner = {
  scanner: null,

  startScanner: async function (videoRef, onScan, onError) {
    try {
      await await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { ideal: "environment" } },
        audio: false,
      });
      this.scanner = new QrScanner(videoRef.current, onScan, {
        onDecodeError: onError,
        returnDetailedScanResult: true,
        highlightScanRegion: true,
      });
      this.scanner.start();
    } catch (error) {
      onError(
        "Erro acessando camera, certifique-se de que você possui uma câmera conectada e o navegador tem permissão ao seu acesso."
      );
    }
  },

  stopScanner: async function () {
    if (this.scanner) {
      await this.scanner.stop();
      await this.scanner.destroy();
    }
  },
};

export default Scanner;
