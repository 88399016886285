import React from 'react';

const FileSelect = ({ id, label, accept, onFileSelect }) => {
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    onFileSelect(selectedFile);
  };

  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <input
        type="file"
        className="form-control"
        id={id}
        accept={accept}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default FileSelect;
