import fetchGraphQLQuery from "../../../utils/graphql/fetchGraphQLQuery";

async function TypeHeadFetchOptions({
  input,
  table,
  labelColumn,
  valueColumn,
  auxiliarColumn,
  idSize,
  separator,
}) {
  const query = `
  {
    ${table}(
      where: {
        ${labelColumn}: { like: "%${input}%" }
      }
    ) {
      ${labelColumn}
      ${valueColumn}
      ${auxiliarColumn ? auxiliarColumn : ""}
    }
  }
`;
  try {
    const options = await fetchGraphQLQuery(query);

    if (options && options.data && options.data[table]) {
      const optionsArray = options.data[table].map((option) => {
        let label = option[labelColumn];

        if (idSize !== 0 && idSize < option[valueColumn].length) {
          label =
            option[valueColumn].substr(option[valueColumn].length - idSize) +
            separator +
            label;
        }

        if (auxiliarColumn !== "") {
          label = label + separator + option[auxiliarColumn];
        }

        return {
          value: option[valueColumn],
          mainLabel: option[labelColumn],
          auxiliarColumn: option[auxiliarColumn],
          idSize: idSize,
          valueEnd: option[valueColumn].substr(
            option[valueColumn].length - idSize
          ),
          label: label,
        };
      });

      return optionsArray;
    }

    return null;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default TypeHeadFetchOptions;
