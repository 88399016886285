import { useState, useEffect } from "react";
import fetchEventDetails from "../../../utils/graphql/fetches/fetchEventDetails";

const EventCard = ({ eventId }) => {
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchEventDetails(eventId);
        setEvent(response.data.event[0]);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };
    fetchData();
  }, [eventId]);

  if (!event) {
    return <div>Evento Não Encontrado</div>;
  }

  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (timeString) => {
    if (!timeString) {
      return "";
    }
    const options = { hour: "numeric", minute: "numeric" };
    return new Date(timeString).toLocaleTimeString(undefined, options);
  };

  const formatDateTime = (dateString) => {
    if (!dateString) {
      return "";
    }
    const date = formatDate(dateString);
    const time = formatTime(dateString);
    return date + " as " + time;
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{event.id}</h5>
        <p className="card-text">
          <strong>Estado:</strong> {event.State?.state} <br />
          <strong>Criador:</strong> {event.creator?.firstName}{" "}
          {event.creator?.lastName} <br />
          <strong>Ator:</strong> {event.actor?.firstName}{" "}
          {event.actor?.lastName} <br />
          <strong>Início:</strong> {formatDateTime(event.initDate)} <br />
          <strong>Fim:</strong> {formatDateTime(event.endDate)}
        </p>
      </div>
    </div>
  );
};

export default EventCard;
