import React, { useState, useEffect, useRef } from "react";
import TypeHeadGeneric from "../TypeHead/TypeHeadGeneric";

const TypeHeadMultiSelectionGeneric = ({
  name,
  fetchOptionsFunction,
  fetchOptionsArguments = {},
  typeHeadOptions,
  columns,
  onChange,
  changeToClearSelection = false,
}) => {
  const [values, setValues] = useState([]);
  const [items, setItems] = useState([]);
  const [changeToClearTypeHead, setChangeToClearTypeHead] = useState("");
  const prevValues = useRef(values);

  useEffect(() => {
    if (prevValues.current !== values) {
      onChange({ id: name, value: values, label: items });
      prevValues.current = values;
    }
  }, [name, items, values, onChange]);

  useEffect(() => {
      setItems([]);
      setValues([]);
  }, [changeToClearSelection]);

  const addItemToList = (itemToAdd, value) => {
    const itemExists = items.some(
      (item) => JSON.stringify(item) === JSON.stringify(itemToAdd)
    );
    if (!itemExists) {
      setItems([...items, itemToAdd]);
      setValues([...values, value]);
    }
  };

  function handleTypeHeadChange(e) {
    const itemToAdd = {};
    if (e && e && e.mainLabel) {
      let selectedValue = e;
      if (Array.isArray(columns) && columns.length === 1) {
        itemToAdd[columns[0]] = selectedValue["mainLabel"];
      }

      if (Array.isArray(columns) && columns.length === 2) {
        itemToAdd[columns[0]] = selectedValue["valueEnd"];
        itemToAdd[columns[1]] = selectedValue["mainLabel"];
      }

      if (Array.isArray(columns) && columns.length === 3) {
        itemToAdd[columns[0]] = selectedValue["valueEnd"];
        itemToAdd[columns[1]] = selectedValue["mainLabel"];
        itemToAdd[columns[2]] = selectedValue["auxiliarColumn"];
      }

      addItemToList(itemToAdd, selectedValue["value"]);
      setChangeToClearTypeHead(!changeToClearTypeHead);
    }
  }

  const handleDelete = (index, e) => {
    e.preventDefault();
    e.stopPropagation();

    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);

    const updatedValues = values.filter((_, i) => i !== index);
    setValues(updatedValues);
  };

  return (
    <>
      <TypeHeadGeneric
        onChange={handleTypeHeadChange}
        fetchOptionsFunction={fetchOptionsFunction}
        fetchOptionsArguments={fetchOptionsArguments}
        changeToClearSelection={changeToClearTypeHead}
        typeHeadOptions={{ id: name, ...typeHeadOptions }}
      />

      {items.length > 0 && (
        <div className="container">
          <table className="table table-bordered mt-4">
            <thead className="thead-dark">
              <tr>
                {columns.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
                <th>Editar</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  {columns.map((column, colIndex) => (
                    <td key={colIndex}>{item[column]}</td>
                  ))}
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => handleDelete(index, e)}
                    >
                      Remover
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default TypeHeadMultiSelectionGeneric;
