import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchEventDeepDetails = async (eventId) => {
  try {
    const query = `{
        event(id: "${eventId}") {
          id
          initDate
          endDate
          Bom {
            id
            Process {
              id
              name
              description
              Documents {
                id
                title
                url
              }
            }
          }
          creator {
            id
            firstName
            lastName
          }
          actor {
            id
            firstName
            lastName
          }
          State {
            id
            state
          }
        }
      }`;
      
    const response = await fetchGraphQLQuery(query);
    return response;
  } catch(error) {
    console.error('Error fetching event details:', error);
  }
};

export default fetchEventDeepDetails;
