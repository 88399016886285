import { useState, useEffect } from "react";
import UuidText from "../Text/UuidText";
import UuidTextQrCode from "../Text/UuidTextQrCode";
import TypeHead from "../TypeHead/TypeHead";
import BomPartIdTypeHead from "../TypeHead/BomPartIdTypeHead";
import BomInfo from "../../Output/BomInfo/BomInfo";
import fetchPartDetails from "../../../utils/graphql/fetches/fetchPartDetails";
import fetchPersonDetails from "../../../utils/graphql/fetches/fetchPersonDetails";
import testRequired from "./utils/testRequired";

const ItemManufacturingForm = ({ onSubmit, urlPartId, urlPersonId }) => {
  const [resetForm, setResetForm] = useState(false);
  const [resetBomSelection, setResetBomSelection] = useState(false);
  const [urlPartName, SeturlPartName] = useState("");
  const [urlPersonName, SeturlPersonName] = useState("");
  const [manufacturer, setManufacturer] = useState({
    value: "",
    label: "Quem será o responsável por fabricar este item.",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (urlPartId) {
          response = await fetchPartDetails(urlPartId);
          SeturlPartName(
            urlPartId.slice(-6) +
              " - " +
              response.data.part[0].name +
              (response.data.part[0].description
                ? " - " + response.data.part[0].description
                : "")
          );
        }
        if (urlPersonId) {
          response = await fetchPersonDetails(urlPersonId);
          SeturlPersonName(
            response.data.person[0].firstName +
              " " +
              response.data.person[0].lastName
          );
        }
      } catch (error) {
        console.error("Error fetching part name:", error);
      }
    };
    fetchData();
  }, [urlPartId, urlPersonId]);

  const getCurrentDateTime = () => {
    const now = new Date();
    const formattedDateTime = `${now.getFullYear()}-${String(
      now.getMonth() + 1
    ).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}T${String(
      now.getHours()
    ).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}`;
    return formattedDateTime;
  };

  const [formData, setFormData] = useState({
    id: "",
    description: "",
    partId: urlPartId || "",
    eventId: "",
    eventBomId: "",
    eventCreatorId: urlPersonId || "",
    historyId: "",
    historyDate: getCurrentDateTime(),
    historyPersonId: urlPersonId || "",
    historyComment: "Item criado para aguardar fabricação.",
  });

  const clearForm = () => {
    setFormData({
      id: "",
      description: "",
      partId: urlPartId || "",
      eventId: "",
      eventBomId: "",
      eventCreatorId: urlPersonId || "",
      historyId: "",
      historyDate: getCurrentDateTime(),
      historyPersonId: urlPersonId || "",
      historyComment: "Item criado para aguardar fabricação.",
    });
    setManufacturer({
      value: "",
      label: "Quem será o responsável por fabricar este item.",
    });
    setResetForm(!resetForm);
    setResetBomSelection(!resetBomSelection);
  };

  const onChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    if (id === "partId") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        eventBomId: "",
      }));
      setResetBomSelection(!resetBomSelection);
    }
  };

  const innerOnSubmit = (e) => {
    e.preventDefault();
    if (testRequired(formData)) {
      alert("Por favor, Preencha todos os campos.");
      return;
    }
    onSubmit(formData);
    clearForm();
  };

  const onPersonChange = (e) => {
    if (e.value && e.label) {
      setManufacturer({ value: e.value, label: e.label });
      setFormData((prevFormData) => ({
        ...prevFormData,
        eventCreatorId: e.value,
        historyPersonId: e.value,
      }));
    } else {
      setManufacturer({
        value: "",
        label: "Quem será o responsável por fabricar este item.",
      });
      setFormData((prevFormData) => ({
        ...prevFormData,
        eventCreatorId: "",
        historyPersonId: "",
      }));
    }
  };

  return (
    <form onSubmit={innerOnSubmit}>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre o Item</h2>
        <div className="mb-3">
          <strong>Intruções:</strong>
          <p>
            Esta página irá criar um item com a finalidade de que o mesmo entre
            em fabricação.
          </p>
        </div>
        <div className="mb-3">
          <UuidTextQrCode
            id="id"
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Descrição:
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            placeholder="Descreva os atributos que sejam específicos deste item, não do projeto/produto, como: 'pintado de vermelho', 'Peça reserva', 'Item de reposição'"
            value={formData.description}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="mb-3">
          {urlPersonId ? (
            <>
              <label htmlFor="person" className="form-label">
                Responsável:
              </label>
              <input
                name={"person"}
                className="form-control"
                placeholder={urlPersonName}
                disabled={true}
              />
            </>
          ) : (
            <>
              <label className="form-label"> Responsável: </label>
              <TypeHead
                name={"person"}
                placeholder={"Quem será o responsável por fabricar este item."}
                table={"person"}
                labelColumn={"firstName"}
                valueColumn={"id"}
                onChange={(e) => onPersonChange(e)}
                onInputChange={() => onPersonChange({})}
                auxiliarColumn={"lastName"}
                separator={" "}
                idSize={0}
                changeToClearSelection={resetForm}
              />
            </>
          )}
        </div>
        <div className="mb-3">
          {urlPartId ? (
            <>
              <label htmlFor="partId" className="form-label">
                Peça:
              </label>
              <input
                name={"partId"}
                className="form-control"
                placeholder={urlPartName}
                disabled={true}
              />
            </>
          ) : (
            <>
              <label className="form-label"> Produto: </label>
              <TypeHead
                name={"partId"}
                placeholder={"Qual produto representa seu item"}
                table={"part"}
                labelColumn={"name"}
                valueColumn={"id"}
                onChange={(e) => onChange(e.id, e.value)}
                auxiliarColumn={"description"}
                changeToClearSelection={resetForm}
              />
            </>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label"> Bom: </label>
          <BomPartIdTypeHead
            typeHeadOptions={{
              id: "eventBomId",
              placeholder: "Escolha sua BOM",
            }}
            fetchOptionsArguments={{ partId: formData.partId }}
            changeToClearSelection={resetBomSelection}
            onChange={(e) => onChange(e.id, e.value)}
          />
        </div>
        <div className="mb-3">
          <BomInfo bomId={formData.eventBomId} sideBySide={false} />
        </div>
      </div>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre o Histórico</h2>
        <div className="mb-3">
          <strong>Intruções:</strong>
          <p>
            Todo item físico possui uma tabela sobre seu histórico, nesta tabela
            são salvas informações sobre seu status; 'Uso', 'Manutençao',
            'Estoque', 'Consumido' e etc.
          </p>
          <p>
            Como deseja criar este item para manufatura, será criado o evento de
            fabricação.
          </p>
        </div>
        <div className="mb-3">
          <UuidText
            label={"ID da entrada no Histórico"}
            id="historyId"
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="historyStatusId" className="form-label">
            Estado:
          </label>
          <input
            name={"historyStatusId"}
            className="form-control"
            placeholder={"Fabricando"}
            disabled={true}
          />
        </div>
        <div className="mb-3">
          {urlPersonId ? (
            <>
              <label htmlFor="historyPersonId" className="form-label">
                Responsável:
              </label>
              <input
                name={"historyPersonId"}
                className="form-control"
                placeholder={urlPersonName}
                disabled={true}
              />
            </>
          ) : (
            <>
              <label htmlFor="historyPersonId" className="form-label">
                Responsável:
              </label>
              <input
                name={"historyPersonId"}
                className="form-control"
                placeholder={manufacturer.label}
                disabled={true}
              />
            </>
          )}
        </div>
        <div className="mb-3">
          <label>Data de ínicio do Estado: </label>
          <input
            type="datetime-local"
            className="form-control"
            id="date"
            name="historyDate"
            value={getCurrentDateTime()}
            disabled={true}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="historyComment" className="form-label">
            Descrição:
          </label>
          <textarea
            disabled={true}
            className="form-control"
            id="historyComment"
            name="historyComment"
            placeholder="Item criado para aguardar fabricação."
          />
        </div>
      </div>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre o Evento</h2>
        <div className="mb-3">
          <strong>Intruções:</strong>
          <p>
            Todo item físico é oriundo de um evento. Este evento é a instância
            de uma BOM com seu determinado processo, esse evento pode ser tanto
            uma simples compra como ou pode ser uma montagem complexa
          </p>
          <p>
            Como este item não existe no banco de dados iremos também criar seu
            evento de origem.
          </p>
        </div>
        <div className="mb-3">
          <UuidText
            label={"ID do Evento"}
            id="eventId"
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          {urlPersonId ? (
            <>
              <label htmlFor="eventCreatorId" className="form-label">
                Criador do Evento:
              </label>
              <input
                name={"eventCreatorId"}
                className="form-control"
                placeholder={urlPersonName}
                disabled={true}
              />
            </>
          ) : (
            <>
              <label htmlFor="eventCreatorId" className="form-label">
                Criador do Evento:
              </label>
              <input
                name={"eventCreatorId"}
                className="form-control"
                placeholder={manufacturer.label}
                disabled={true}
              />
            </>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="eventStateId" className="form-label">
            Estado:
          </label>
          <input
            name={"eventStateId"}
            className="form-control"
            placeholder={"Aguardando"}
            disabled={true}
          />
        </div>
      </div>
      <div className="m-0 mt-4 mb-4 rounded p-4 row">
        <button type="submit" className="mb-3 btn btn-primary btn-lg">
          Enviar
        </button>
      </div>
    </form>
  );
};

export default ItemManufacturingForm;
