import { useState } from "react";
import Head from "../../../components/Header";
import Navbar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import EventForm from "../../../components/Input/Forms/EventForm";
import ErrorOutput from "../../../components/Output/ErrorOutput";
import SuccessOutput from "../../../components/Output/SuccessOutput";
import CreateEvent from "../../../utils/graphql/crud/event/create";

const CreateEventPage = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const onSubmit = async (input) => {
    setError(null);
    setSuccess(null);
    try {
      const response = await CreateEvent(input);
      setSuccess(`Evento: ${response.data.id} criado.`);
    } catch (error) {
      setError(error.message || "Ocorreu um erro");
    }
  };

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="m-5 text-center">
          <h1>Inserir Evento</h1>
        </div>
        <EventForm onSubmit={onSubmit} />
        <ErrorOutput error={error} />
        <SuccessOutput success={success} />
      </div>
      <Footer />
    </div>
  );
};

export default CreateEventPage;
