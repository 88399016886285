import { useState } from "react";
import Head from "../../../components/Header";
import Navbar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import FileInput from "../../../components/Input/File/FileInput";
import importJson from "../../../utils/graphql/importJson";
import ImportTableSelectionBox from "../../../components/PageElements/directAcess/import/ImportTableSelectionBox";
import ProtectedPage from "../../../components/PageElements/directAcess/ProtectedPage";

const ImportCsvTable = () => {
  const [selectedTable, setSelectedTable] = useState(null);

  const handleTableSelection = (selectedTable) => {
    setSelectedTable(selectedTable.value);
  };

  const handleFileSelection = (content) => {
    importJson(selectedTable, content);
  };

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="m-5 text-center">
          <h1>Importar Tabela</h1>
          <div className="mt-3 mb-3 rounded bg-light p-4 text-start">
            <h2>Selecionar Tabela</h2>
            <strong>Instruções: </strong>
            <p>Utilize apenas para para desenvolvimento.</p>
            <label>tabela: </label>
            <ImportTableSelectionBox
              label="Selecionar Tabela"
              onChange={handleTableSelection}
              name="tableSelection"
            />
            {selectedTable && (
              <div className="mt-5">
                <FileInput
                  label={"Importe um Arquivo CSV"}
                  accept={".csv"}
                  handleFileSelection={handleFileSelection}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

// Wrap MyProtectedPage with withPasswordProtection to protect it.
const ProtectedMyProtectedPage = ProtectedPage(ImportCsvTable);

export default ProtectedMyProtectedPage;