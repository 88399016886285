import React, { useState, useEffect, useRef } from "react";
import TypeHeadGeneric from "../../../../Input/TypeHead/TypeHeadGeneric";
import BomElementSelectorFetchOptions from "./BomElementSelectorFetchOptions";

const BomElementSelector = ({
  name,
  placeholder,
  onChange,
  changeToClearSelection,
}) => {
  const [values, setValues] = useState([]);
  const [items, setItems] = useState([]);
  const [quantities, setQuantities] = useState([]);
  const [changeToClearTypeHead, setChangeToClearTypeHead] = useState("");
  const prevValues = useRef(values);
  const prevQuantities = useRef(quantities);

  useEffect(() => {
    if (
      prevValues.current !== values ||
      prevQuantities.current !== quantities
    ) {
      onChange({ value: [values, quantities], label: items, id: name });
      prevValues.current = values;
      prevQuantities.current = quantities;
    }
  }, [name, items, values, quantities, onChange]);

  useEffect(() => {
    setItems([]);
    setValues([]);
    setQuantities([]);
  }, [changeToClearSelection]);

  const addItemToList = (itemToAdd, value) => {
    const itemExists = items.some(
      (item) => JSON.stringify(item) === JSON.stringify(itemToAdd)
    );
    if (!itemExists) {
      setItems([...items, itemToAdd]);
      setValues([...values, value]);
      setQuantities([...quantities, "1"]);
    }
  };

  function handleTypeHeadChange(e) {
    const itemToAdd = {};
    if (e && e && e.mainLabel) {
      let selectedValue = e;
      itemToAdd["ID"] = selectedValue["valueEnd"];
      itemToAdd["Nome"] = selectedValue["mainLabel"];
      itemToAdd["Descrição"] = selectedValue["auxiliarColumn"];
      itemToAdd["Unidade"] = selectedValue["unit"];
      addItemToList(itemToAdd, selectedValue["value"]);
      setChangeToClearTypeHead(!changeToClearTypeHead);
    }
  }

  const handleDelete = (index, e) => {
    e.preventDefault();
    e.stopPropagation();

    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);

    const updatedValues = values.filter((_, i) => i !== index);
    setValues(updatedValues);

    const updatedQuantities = values.filter((_, i) => i !== index);
    setQuantities(updatedQuantities);
  };

  const handleQuantityChange = async (index, e) => {
    e.preventDefault();
    e.stopPropagation();
    let updatedQuantities = [...quantities];
    updatedQuantities[index] = e.target.value;
    setQuantities(updatedQuantities);
  };

  return (
    <div>
      <TypeHeadGeneric
        onChange={handleTypeHeadChange}
        fetchOptionsFunction={BomElementSelectorFetchOptions}
        changeToClearSelection={changeToClearTypeHead}
        typeHeadOptions={{ placeholder: placeholder }}
      />

      {items.length > 0 && (
        <div className="container">
          <table className="table table-bordered mt-4">
            <thead className="thead-dark">
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Unidade</th>
                <th>Qtd</th>
                <th>Editar</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  {["ID", "Nome", "Descrição", "Unidade"].map(
                    (column, colIndex) => (
                      <td key={colIndex}>{item[column]}</td>
                    )
                  )}
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={quantities[index] || 1}
                      onChange={(e) => handleQuantityChange(index, e)}
                      min={0}
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => handleDelete(index, e)}
                    >
                      Remover
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default BomElementSelector;
