import TypeHeadQRCodeGeneric from "./TypeHeadQRCodeGeneric";
import ItemFartIdTypeHeadFecthOptions from "../TypeHead/ItemPartIdTypeHeadFecthOptions";

const ItemsFromPartIdTypeHeadQrCode = ({
  typeHeadOptions,
  fetchOptionsArguments,
  changeToClearSelection,
  onChange,
}) => {
  const { id, disabled, placeholder } = typeHeadOptions;

  return (
    <TypeHeadQRCodeGeneric
      onChange={onChange}
      fetchOptionsFunction={ItemFartIdTypeHeadFecthOptions}
      fetchOptionsArguments={{ partId: fetchOptionsArguments.partId }}
      typeHeadOptions={{
        id: id,
        disabled: disabled || false,
        placeholder: placeholder,
      }}
      changeToClearSelection={changeToClearSelection}
    />
  );
};

export default ItemsFromPartIdTypeHeadQrCode;

