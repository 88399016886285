import { useState, useEffect } from "react";
import ManufacutingBodyOutputElement from "./ManufacturingBodyOutputElement";
import getEventOutputsForManufacturingEvent from "../../../../../../utils/graphql/controllers/getEventParentsForManufacturingBody";

const ManufacturingBodyOutputTab = ({ eventId, personId, showEditOptions }) => {
  const [output, setOutput] = useState([]);
  const [refatachTrigger, setRefatachTrigger] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setOutput([]);
      try {
        const response = await getEventOutputsForManufacturingEvent(eventId);
        setOutput(response.data);
      } catch (error) {
        console.error("Error fetching output data:", error);
      }
    };

    fetchData();
  }, [eventId, refatachTrigger]);

  const reload = () => {
    setRefatachTrigger(!refatachTrigger);
  };

  if (output.length < 1) {
    return (
      <div className="mt-5 text-center">
        <h3>A BOM Deste Evento Não Possui Saídas</h3>
      </div>
    );
  }

  return (
    <div>
      {output.map((out) => {
        const key = `${out.partId}.${out.index}`;
        return (
          <div className="m-3" key={key + ".div"}>
            <ManufacutingBodyOutputElement
              key={key}
              eventId={eventId}
              partId={out.partId}
              quantityIndex={out.index}
              personId={personId}
              showEditOptions={showEditOptions}
              onChangeCurrentOutput={reload}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ManufacturingBodyOutputTab;
