import { useState } from "react";
import UuidText from "../Text/UuidText";
import testRequired from "./utils/testRequired";

const UnitForm = ({ onSubmit }) => {
  const [resetForm, setResetForm] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    symbol: "",
    countable: false,
  });

  const clearForm = () => {
    setFormData({
      id: "",
      name: "",
      symbol: "",
      countable: false,
    });
    setResetForm(!resetForm);
  };

  const onChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const innerOnSubmit = (e) => {
    e.preventDefault();
    if (testRequired(formData)) {
      alert("Por favor, preencha todos os campos.");
      return;
    }
    onSubmit(formData);
    clearForm();
  };

  return (
    <form onSubmit={innerOnSubmit}>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2 className="mb-4">Sobre a Unidade</h2>
        <div className="mb-3">
          <strong>Intruções:</strong>
          <p>
            Grande parte das unidades já estão disponíveis no banco,
            certifique-se de que não está criando nada repetido com um nome ou
            convenção diferente. Atenha-se à unidade do sistema internacional de
            medidas. Seja conciso no nome e símbolo da unidade. Atenha-se a
            marcar se a unidade é do tipo contável, o banco não tem interesse em
            rastrear cada 'ml' de cola singularmente, por exemplo.
          </p>
        </div>
        <div className="mb-3">
          <UuidText
            id={"id"}
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Nome:
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Nome da Unidade, i.e 'Kilograma'"
            value={formData.name}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="row">
          <div className="mb-3 col-12  col-md-8">
            <label htmlFor="symbol" className="form-label">
              Símbolo:
            </label>
            <input
              type="text"
              className="form-control"
              id="symbol"
              name="symbol"
              placeholder="Símbolo utilizado para a unidade, 'i.e' kg"
              value={formData.symbol}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </div>
          <div className="mb-3 col-md-4 col-12">
            <label htmlFor="countable" className="form-label">
              Contável:
            </label>
            <select
              className="form-select"
              id="countable"
              name="countable"
              value={formData.countable}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            >
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </select>
          </div>
        </div>
        <div className="mt-5 row">
          <button type="submit" className="btn btn-primary btn-lg">
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
};

export default UnitForm;
