import React, { useState, useEffect } from 'react';
import QueryOutput from '../QueryOutput';
import fetchPartDocuments from '../../../utils/graphql/fetches/fetchPartDocuments';

const PartDocumentsTable = ({ partId }) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchPartDocuments(partId);
      setResult(data);
    };

    fetchData();
  }, [partId]);

  return (
    <div>
      <QueryOutput
        queryResult={result}
        containerTitles={[]}
        MajorContainerTitle={"Documentos do Produto"}
      />
    </div>
  );
};

export default PartDocumentsTable;
