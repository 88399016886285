import config from '../../../config/config';
import postController from '../../postController';

// Function to fetch data from GraphQL API
async function createPerson(personInput) {
  try {
    // initialize variables
    const variables = personInput

    // Make a post request
    const response = await postController(variables, config.graphQLServerHost, config.createPersonUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller createPerson:', error.message);
    throw(error)
  }
} 

export default createPerson;
