import { useState, useEffect } from 'react';
import getBomChildren from '../../../utils/graphql/controllers/getBomChildren';

const ChildrenList = ({ bomId }) => {
  const [children, setChildren] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBomChildren(bomId);
        setChildren(response.data);
      } catch (error) {
        console.error('Error fetching children data:', error);
      }
    };
    fetchData();
  }, [bomId]);

  if (!children) {
    return <div></div>;
  }

  return (
    <ul className="list-group">
      {children.map((child, index) => (
        <li key={index} className="list-group-item">
          <div className='row'>
            <div className='col-md-9'>
              <strong>Peça:</strong> {child.name}
            </div>
            <div className='col-md-3'>
              <strong>Qnt:</strong> {child.quantity}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ChildrenList;
