import { useState, useEffect, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

const TypeHeadGeneric = ({
  onChange,
  fetchOptionsFunction,
  fetchOptionsArguments = {},
  typeHeadOptions = {},
  changeToClearSelection = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const typeaheadRef = useRef(null);

  const onSelect = (selected) => {
    if (selected.length > 0) {
      setInputValue(selected[0].label);
      onChange({ ...selected[0], id: typeHeadOptions.id || "typeaheadId" });
    } else {
      setInputValue("");
      typeaheadRef.current.clear();
    }
  };

  useEffect(() => {
    setInputValue("");
    typeaheadRef.current.clear();
  }, [changeToClearSelection]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      const fetchedSuggestions = await fetchOptionsFunction({
        ...fetchOptionsArguments,
        input: inputValue,
      });
      setSuggestions(fetchedSuggestions || []);
    };

    const timeoutId = setTimeout(fetchSuggestions, 300);

    return () => clearTimeout(timeoutId);
  }, [inputValue, fetchOptionsFunction, fetchOptionsArguments]);

  return (
    <Typeahead
      {...typeHeadOptions}
      id={typeHeadOptions?.id || "typeaheadId"}
      options={suggestions}
      onChange={onSelect}
      onInputChange={(input) => setInputValue(input)}
      ref={typeaheadRef}
    />
  );
};

export default TypeHeadGeneric;
