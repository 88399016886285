import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchPartDetails = async (partId) => {
  try {
    const query = `{
        part(id: "${partId}") {
          id
          name
          nickname
          description
          date
          version
          Type {
            category
            type
            subType
          }
          Unit {
            name
            symbol
          }
          Supply {
            supply
          }
        }
      }`;
      
    const response = await fetchGraphQLQuery(query);
    return response;
  } catch(error) {
    console.error('Error fetching part details:', error);
  }
};

export default fetchPartDetails;
