import { useState, useEffect } from "react";
import fetchPartAuthorDetails from "../../../utils/graphql/fetches/fetchPartAuthorDetails";

const AuthorCard = ({ partId }) => {
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchPartAuthorDetails(partId);
        setAuthor(response.data.part[0].Person);
      } catch (error) {
        console.error("Error fetching part data:", error);
      }
    };
    fetchData();
  }, [partId]);

  if (!author) {
    return <>Autor Não Encontrado.</>;
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{author.name}</h5>
        <p className="card-text">
          <strong>Nome:</strong> {author.firstName} {author.lastName} <br />
          <small className="text-muted">
            <strong>ID:</strong> {author.email}
          </small>
        </p>
      </div>
    </div>
  );
};

export default AuthorCard;
