import config from '../../config/config';
import fetchController from '../fetchController';

// Function to fetch data from GraphQL API
async function getPartItemsAvaiableForOutput(partId, input) {
  try {
    // initialize variables
    const variables = {partId: partId, like: input || null}

    // Make a GET request
    const response = await fetchController(variables, config.graphQLServerHost, config.getPartItemsAvaiableForOutputUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller getPartItemsAvaiableForOutput:', error);
  }
}

export default getPartItemsAvaiableForOutput;
