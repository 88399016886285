import React, { useState, useEffect } from 'react';
import fetchItemAsOutputEvents from '../../../utils/graphql/fetches/fetchItemAsOutputEvents'
import fetchItemAsInputEvents from '../../../utils/graphql/fetches/fetchItemAsInputEvents'

import QueryOutput from '../QueryOutput'

const ItemEvent = ({ itemId }) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const asOutput = await fetchItemAsOutputEvents(itemId);
      const asInput = await fetchItemAsInputEvents(itemId);
      setResult({asOutput: asOutput, asInput: asInput});
    };
    fetchData();
  }, [itemId]);


  if (!itemId) {
    return (<></>);
  }

  return (
    <> 
      <QueryOutput
        queryResult={result?.asOutput}
        containerTitles={''}
        MajorContainerTitle={'Seu item foi Saída destes Eventos'}
        columnsToRemove={['__typename', 'creatorid', 'creatorfirstName', 'creatorlastName', 'actorfirstName', 'actorid', 'actorlastName', 'Stateid']}
        columnsOrder={['id', 'BomProcessname', 'initDate', 'endDate', 'creatoremail', 'actoremail', 'statestate']}
      />
      <QueryOutput
        queryResult={result?.asInput}
        containerTitles={''}
        MajorContainerTitle={'Seu item foi Entrada destes Eventos'}
        columnsToRemove={['__typename', 'creatorid', 'creatorfirstName', 'creatorlastName', 'actorfirstName', 'actorid', 'actorlastName', 'Stateid']}
        columnsOrder={['ID', 'BomProcessname']}
      />
    </>
  );
};

export default ItemEvent;