const testRequired = (object, excludeLabels = []) => {
  return Object.entries(object).some(([label, value]) => {
    if (excludeLabels.includes(label)) {
      return false;
    }
    return value === '';
  });
};

export default testRequired;
