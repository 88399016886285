import React, { useEffect, useState } from "react";
import TypeHeadGeneric from "./TypeHeadGeneric";
import TypeHeadFetchOptions from "./TypeHeadFetchOptions";

const UserTypeHead = ({ typeHeadOptions, changeToClearSelection, onChange }) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const [initialValueSet, setInitialValueSet] = useState(false);

  useEffect(() => {
    if (isLoggedIn && !initialValueSet) {
      const typeHeadResponse = {
        auxiliarColumn: localStorage.getItem("userLastName"),
        id: typeHeadOptions.id,
        idSize: 0,
        label: localStorage.getItem("userFirstName") + " " + localStorage.getItem("userLastName"),
        mainLabel: localStorage.getItem("userName"),
        value: localStorage.getItem("userId"),
        valueEnd: "",
      };
      onChange(typeHeadResponse);
      setInitialValueSet(true);
    }
  }, [isLoggedIn, initialValueSet, onChange, typeHeadOptions]);

  useEffect(() => {
    setInitialValueSet(false);
  }, [changeToClearSelection]);

  return (
    <TypeHeadGeneric
      onChange={onChange}
      fetchOptionsFunction={TypeHeadFetchOptions}
      fetchOptionsArguments={{
        table: "person",
        labelColumn: "firstName",
        valueColumn: "id",
        auxiliarColumn: "lastName",
        idSize: 0,
        separator: " ",
      }}
      typeHeadOptions={{
        ...typeHeadOptions,
        disabled: isLoggedIn,
        placeholder: isLoggedIn ? `${localStorage.getItem("userFirstName")} ${localStorage.getItem("userLastName")}` : typeHeadOptions.placeholder,
      }}
      changeToClearSelection={changeToClearSelection}
    />
  );
};

export default UserTypeHead;
