import { useState } from 'react';
import Head from '../../Header';
import Navbar from '../../NavBar';
import Footer from "../../Footer";

// This function now returns another function, making it a true Higher-Order Component
const ProtectedPage = (ProtectedComponent) => {
  return function ProtectedPage(props) {
    const [password, setPassword] = useState('');
    const [accessGranted, setAccessGranted] = useState(false);

    const correctPassword = 'yourPassword'; // Implement a more secure way in real apps

    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };

    const handleLogin = (event) => {
      event.preventDefault();
      if (password === correctPassword) {
        setAccessGranted(true);
      } else {
        alert('Incorrect password');
      }
    };

    if (accessGranted) {
      // Spread props to the protected component
      return <ProtectedComponent {...props} />;
    }

    return (
      <div className="wr-home">
      <Head />
      <Navbar />
      <div style={{ height: "70vh" }} className="container fill-height">
        <div className="text-center mt-4">
          <h1>WR4.0 - Sua interface de produção</h1>
        </div>
        <div className="h-75"></div>
        <div className="mt-3 mb-3 rounded bg-light p-4">
          <div className="text-center">
            <h3>Página protegida para desenvolvedores.</h3>
          </div>
          <div className="mt-3 text-center btn-container">
          <form onSubmit={handleLogin}>
            <label className="form-label" htmlFor="password">Senha:</label>
            <input
              className="form-control"
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
            />
            <button type="submit" className="m-3 btn btn-primary btn-lg">Entrar</button>
          </form>
          </div>
          <div className="m-3" />
        </div>
      </div>
      <Footer />
    </div>
    );
  };
};

export default ProtectedPage;
