import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import fetchPartDetails from "../../../../../../utils/graphql/fetches/fetchPartDetails";
import getCurrentOutputForManufacturingBody from "../../../../../../utils/graphql/controllers/getCurrentOutputForManufacturingBody";
import ItemAvaiableForOutputByPartTypeheadQrCode from "../../../../../Input/TypeHeadQRCode/ItemAvaiableForOutputByPartTypeheadQrCode";
import updateEventOutput from "../../../../../../utils/graphql/crud/output/update";
import deleteOutput from "../../../../../../utils/graphql/crud/output/delete";
import createOutputItem from "../../../../../../utils/graphql/crud/output/createOutputItem";
import getItemLastStatus from "../../../../../../utils/graphql/controllers/getItemLastStatus";
import ErrorOutput from "../../../../../Output/ErrorOutput";
import CreateOutputModal from "../../../../../Input/Modal/CreateOutputModal";
import config from "../../../../../../utils/config/config";

const ManufacutingBodyOutputElement = ({
  eventId,
  partId,
  quantityIndex,
  personId,
  showEditOptions,
  onChangeCurrentOutput,
}) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [part, setPart] = useState(null);
  const [currentOutput, setCurrentOutput] = useState(null);
  const [currentOutputStatus, setCurrentOutputStatus] = useState(null);
  const [triggerCurrentOutputChange, seTtriggerCurrentOutputChange] = useState(false);
  const [newOutput, setNewOutput] = useState(null);
  const [changeToClearSelection, setChangeToClearSelection] = useState(false);
  const [cardBackgroundColor, setCardBackgroundColor] = useState("#ffffff"); // Default color
  const [showAlert, setShowAlert] = useState(false);
  const [showGoToEventButton, setShowGoToEventButton] = useState(false);
  const [consumeThisOutputEventId, setConsumeThisOutputEventId] = useState('');

  // fetch part data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const partResponse = await fetchPartDetails(partId);
        setPart(partResponse.data.part[0]);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };
    fetchData();
  }, [partId]);

  // fetch output data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const outputResponse = await getCurrentOutputForManufacturingBody(
          eventId,
          partId,
          quantityIndex
        );
        if (outputResponse?.data?.currentOutput?.Item) {
          const currentOutputStatusResponse = await getItemLastStatus(
            outputResponse?.data?.currentOutput?.Item?.id
          );
          setCurrentOutput(outputResponse?.data?.currentOutput?.Item);
          setCurrentOutputStatus(currentOutputStatusResponse.data);
        }
        if(outputResponse && outputResponse.data && outputResponse.data.goBackEvent){
          setShowGoToEventButton(true);
          setConsumeThisOutputEventId(outputResponse.data.goBackEvent.id);
        }
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };
    fetchData();
  }, [eventId, partId, quantityIndex, setCurrentOutputStatus]);

  // apply outputbackgroun color
  useEffect(() => {
    const changeBackgroundColor = async () => {
      if (showEditOptions) {
        if (
          currentOutputStatus?.id === config.fixedIds.status.mustManufacture
        ) {
          // is in manufacturing, this is good
          setCardBackgroundColor("#cdffb3");
        } else if (currentOutputStatus?.id === config.fixedIds.status.storage) {
          // already manufactured, something is not good
          setCardBackgroundColor("#f8ffb3");
        } else if (currentOutputStatus === null) {
          // nothing selected
          setCardBackgroundColor("#ffffff");
        } else {
          // something strange
          setCardBackgroundColor("#ffcccd");
        }
      } else {
        if (currentOutputStatus?.id === config.fixedIds.status.manufacturing) {
          // being used
          setCardBackgroundColor("#cccccc");
        } else {
          // others
          setCardBackgroundColor("#ffffff");
        }
      }
    };
    changeBackgroundColor();
  }, [currentOutputStatus, setCurrentOutputStatus, showEditOptions]);


  const applyOutputChange = async () => {
    setError(null);
    if (newOutput === null || newOutput === "") {
      setError("Selecione um item.");
      return;
    }
    if (personId === null || personId === "") {
      setError("Selecione quem você é.");
      return;
    }
    const variables = {
      eventId: eventId,
      quantityIndex: quantityIndex,
      itemId: newOutput,
      personId: personId,
    };
    try {
      await updateEventOutput(variables);
    } catch (error) {
      setError(error.message);
    }
    seTtriggerCurrentOutputChange(!triggerCurrentOutputChange);
    setChangeToClearSelection(!changeToClearSelection);
    setNewOutput(null);
    onChangeCurrentOutput(variables);
  };

  const applyOutputChangeQRCode = async (value) => {
    setError(null);
    if (value === null || value === "") {
      setError("Selecione um item.");
      return;
    }
    if (personId === null || personId === "") {
      setError("Selecione quem você é.");
      return;
    }
    const variables = {
      eventId: eventId,
      quantityIndex: quantityIndex,
      itemId: value,
      personId: personId,
    };
    try {
      await updateEventOutput(variables);
    } catch (error) {
      setError(error.message);
    }
    seTtriggerCurrentOutputChange(!triggerCurrentOutputChange);
    setChangeToClearSelection(!changeToClearSelection);
    setNewOutput(null);
    onChangeCurrentOutput(variables);
  };

  const removeOutput = async () => {
    setError(null);
    if (currentOutput === null || currentOutput === "") {
      setError("Nenhum item esta sendo utilziado como saída neste registro.");
      return;
    }
    if (personId === null || personId === "") {
      setError("Selecione quem você é.");
      return;
    }
    const variables = {
      eventId: eventId,
      quantityIndex: quantityIndex,
      itemId: currentOutput?.id,
      personId: personId,
    };
    try {
      await deleteOutput(variables);
    } catch (error) {
      setError(error.message);
    }
    seTtriggerCurrentOutputChange(!triggerCurrentOutputChange);
    setChangeToClearSelection(!changeToClearSelection);
    setNewOutput(null);
    onChangeCurrentOutput(variables);
  };

  const createOutput = async () => {
    setError(null);
    if (part === null || part === "") {
      setError("Ocorreu um erro.");
      return;
    }
    if (personId === null || personId === "") {
      setError("Selecione quem você é.");
      return;
    }
    setShowAlert(true);
  };

  const goToEvent = async () => {
    const nextPageUrl = `/usage/manufacturing/selectItem/selectevent/event/eventpagemanufacturing/${consumeThisOutputEventId}`;
    navigate(nextPageUrl);
    return;
  }

  const onSubmit = async (formData) => {
    try {
      const variables = {
        itemId: formData["id"],
        itemDescription: formData["description"],
        partId: partId,
        personId: personId,
        eventId: eventId,
        quantityIndex: quantityIndex,
      };

      setShowAlert(false);

      await createOutputItem(variables);

      seTtriggerCurrentOutputChange(!triggerCurrentOutputChange);
      setChangeToClearSelection(!changeToClearSelection);
      setNewOutput(null);
      onChangeCurrentOutput(variables);
    } catch (error) {
      setError(error.message || "Ocorreu um erro");
    }
  };

  return (
    <div className="card" style={{ backgroundColor: cardBackgroundColor }}>
      <div className="card-body">
        <h5 className="card-title">
          {part?.name} - Número {quantityIndex}{" "}
        </h5>
        <p className="card-text">
          <strong>Descrição:</strong> {part?.description} <br />
          <strong>Item Atual:</strong> {currentOutput?.id} <br />
          <strong>Descrição do Item Atual:</strong> {currentOutput?.description}{" "}
          <br />
          <strong>Estado do Item Atual:</strong> {currentOutputStatus?.name}{" "}
          <br />
        </p>
      </div>

      {showEditOptions && (
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-8">
              <strong>Trocar Item:</strong>{" "}
              <ItemAvaiableForOutputByPartTypeheadQrCode
                id={"itemId"}
                placeholder={"Selecione o Item"}
                onChange={(e) => {
                  setNewOutput(e.value);
                  applyOutputChangeQRCode(e.value);
                }}
                partId={part?.id}
                changeToClearSelection={changeToClearSelection}
              />
              <br />
              <ErrorOutput error={error} />
            </div>
            <div className="col-1"></div>
            <div className="col-md-3">
              <div className="row">
                <div className="col-lg-6">
                  <button
                    type={"button"}
                    onClick={applyOutputChange}
                    className="btn btn-sm btn-info mb-2 md-2 w-100"
                  >
                    Trocar
                  </button>
                </div>
                <div className="col-lg-6">
                  <button
                    type={"button"}
                    onClick={removeOutput}
                    className="btn btn-sm btn-danger mb-2 md-2 w-100"
                  >
                    Remover
                  </button>
                </div>
              </div>
              <div>
                <button
                  type={"button"}
                  onClick={createOutput}
                  className="btn btn-sm btn-success mb-2 md-2 w-100"
                >
                  Criar este Item
                </button>
              </div>
              {showGoToEventButton && (
                <>
                  <div>
                    <button
                      type={"button"}
                      onClick={goToEvent}
                      className="btn btn-sm btn-success mb-2 md-2 w-100"
                    >
                      Ir ao evento de consumo
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <>
        {showAlert && showEditOptions && (
          <CreateOutputModal
            showModal={showAlert}
            onSubmit={(e) => onSubmit(e)}
            handleClose={() => {
              setShowAlert(false);
            }}
          />
        )}
      </>
    </div>
  );
};

export default ManufacutingBodyOutputElement;
