import config from '../../config/config';
import fetchController from '../fetchController';

// Function to fetch data from GraphQL API
async function getEventChildrenForManufacturingBody(eventId) {
  try {
    // initialize variables
    const variables = {eventId: eventId}

    // Make a GET request
    const response = await fetchController(variables, config.graphQLServerHost, config.getEventChildrenForManufacturingBodyUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller getEventChildrenForManufacturingBody:', error);
  }
}

export default getEventChildrenForManufacturingBody;
