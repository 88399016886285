import { useState } from 'react';
import Head from '../../../components/Header';
import Navbar from '../../../components/NavBar';
import Footer from '../../../components/Footer';
import UnitForm from '../../../components/Input/Forms/UnitForm';
import ErrorOutput from '../../../components/Output/ErrorOutput';
import SuccessOutput from '../../../components/Output/SuccessOutput';
import CreateUnit from '../../../utils/graphql/crud/unit/create';

const CreateUnitPage = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const onSubmit = async (input) => {
    setError(null);
    setSuccess(null);
    try {
      const response = await CreateUnit(input);
      setSuccess(`Unidade: ${response.data.id} criada.`);
    } catch (error) {
      setError(error.message || 'Ocorreu um erro.');
    }
  };

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="m-5 text-center">
          <h1>Inserir Unidade</h1>
        </div>
        <UnitForm onSubmit={onSubmit} />
        <ErrorOutput error={error} />
        <SuccessOutput success={success} />
      </div>
      <Footer />
    </div>
  );
};

export default CreateUnitPage;
