import GenericSelectionBox from "./GenericSelectionBox";
import getSelectionBoxOptions from "./SelectionBoxFetchOptions";

const SelectionBox = ({
  table,
  labelColumn,
  valueColumn,
  auxiliarColumn = "",
  onChange,
  separator = " - ",
  name,
  changeToClearSelection,
  disabled = false,
  placeholder = "Escolha uma Opção",
}) => {
  return (
    <GenericSelectionBox
      name={name}
      fetchOptionsFunction={getSelectionBoxOptions}
      fetchOptionsArgs={{
        table: table,
        valueColumn: valueColumn,
        labelColumn: labelColumn,
        auxiliarColumn: auxiliarColumn,
      }}
      selectArgs={{ disabled: disabled }}
      optionArgs={{ separator: separator, placeholder: placeholder }}
      onChange={onChange}
      changeToClearSelection={changeToClearSelection}
    />
  );
};

export default SelectionBox;
