import React, { useState, useEffect } from 'react';
import QueryOutput from '../QueryOutput';
import getPartStarmap from '../../../utils/graphql/controllers/getPartStarmap'; 

const TablePartStarmap = ({ partId }) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const getPartStarmapAsync = async () => {
      const data = await getPartStarmap(partId);
      setResult(data);
    };
    getPartStarmapAsync();
  }, [partId]);

  return (
    <div>
      <QueryOutput
        queryResult={result}
        containerTitles={[]}
        MajorContainerTitle={"Constelações do Produto"}
        columnsOrder={['grouping', 'constellation', 'star']}
      />
    </div>
  );
};

export default TablePartStarmap;
