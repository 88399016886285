import { useState, useEffect } from "react";
import fetchPartDetails from "../../../utils/graphql/fetches/fetchPartDetails";

const PartCard = ({ partId }) => {
  const [item, setItem] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchPartDetails(partId);
        setItem(response.data.part[0]);
      } catch (error) {
        console.error("Error fetching part data:", error);
      }
    };
    fetchData();
  }, [partId]);

  if (!item) {
    return <></>;
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{item.name}</h5>
        <p className="card-text">
          <strong>Descrição:</strong> {item.description} <br />
          <small className="text-muted">
            <strong>ID:</strong> {item.id}
          </small>
        </p>
      </div>
    </div>
  );
};

export default PartCard;
