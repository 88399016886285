import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Head from "../../../../components/Header";
import Navbar from "../../../../components/NavBar";
import Footer from "../../../../components/Footer";
import ItemManufacturingForm from "../../../../components/Input/Forms/ItemManufacturingForm";
import CreateItemForManufacturing from "../../../../utils/graphql/crud/item/createForManufacturing";
import ErrorOutput from "../../../../components/Output/ErrorOutput";

const CreateItemManufacturingPage = () => {
  const navigate = useNavigate();
  const { partId } = useParams();
  const [error, setError] = useState(null);
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  const onSubmit = async (input) => {
    setError(null);
    try {
      await CreateItemForManufacturing(input);
      const nextPageUrl = `/usage/manufacturing/selectItem/selectevent/selecteventmanufacturing/${input.id}`;
      navigate(nextPageUrl);
    } catch (error) {
      setError(error.message || "Ocorreu um erro");
    }
  };

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="m-5 text-center">
          <h1>Inserir Item</h1>
        </div>
        {isLoggedIn ? (
          <>
            <ItemManufacturingForm onSubmit={onSubmit} urlPartId={partId} urlPersonId={localStorage.getItem("userId")}/>
          </>
        ) : (
          <>
            <ItemManufacturingForm onSubmit={onSubmit} urlPartId={partId} />
          </>
        )}
        <ErrorOutput error={error} />
      </div>
      <Footer />
    </div>
  );
};

export default CreateItemManufacturingPage;
