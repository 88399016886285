import fetchPartBom from "../../../utils/graphql/controllers/getPartBoms";

const BomPartIdTypeHeadFetchOptions = async ({ partId, input }) => {
  try {
    if (!partId) {
      return [];
    }
    const response = await fetchPartBom(partId, input);
    let options = [];
    if (response && response.data) {
      options = response.data.map((bom) => ({
        label: bom.bom.id,
        value: bom.bom.id,
        auxLabel: "",
      }));
      return options;
    }
  } catch (error) {
    console.error(`Error fetching BOMs for partId: ${partId}`, error);
  }
};

export default BomPartIdTypeHeadFetchOptions;
