import { useRef, useEffect } from "react";
import CameraModalScanner from "../Modal/CameraModalScanner";

const CameraInput = ({ id, showImage, title, onError, onScan }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const innerOnScan = (result) => {
      onScan({ ...result, id: id, value: result?.data });
    };

    const innerOnError = (error) => {
      if (error.toUpperCase().includes("NO QR CODE FOUND")) return;
      onError(error);
    };

    showImage
      ? CameraModalScanner.startScanner(videoRef, innerOnScan, innerOnError)
      : CameraModalScanner.stopScanner();
  }, [id, onScan, onError, showImage]);

  return (
    <div>
      <div>
        <h2>{title}</h2>
      </div>
      <div>
        <video ref={videoRef} style={{ width: "100%" }}></video>
      </div>
    </div>
  );
};

export default CameraInput;
