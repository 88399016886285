import Head from '../../components/Header';
import Navbar from '../../components/NavBar';
import Footer from '../../components/Footer';
import '../../styles/tbd.css';
import '../../styles/contact-page.css'; // Import CSS file for styling

function ContactPage() {
  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div style={{ minHeight: '50vh' }}></div>
      <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="card my-5">
            <div className="card-body">
              <h2 className="card-title text-center">Entre em Contato</h2>
              <p className="card-text text-center">Se você tiver alguema dúvida ou precisar de ajuda entre em contato com:</p>
              <ul className="list-group">
                <li className="list-group-item"><strong>Name:</strong> Arthur Demarchi</li>
                <li className="list-group-item"><strong>Email:</strong> arthur.demarchi@wr.sc.usp.br</li>
                <li className="list-group-item"><strong>Phone:</strong> (14) 99750-xxxx </li>
                <li className="list-group-item"><strong>Address:</strong> Av. Trab. São Carlense, 400 - Parque Arnold Schimidt, São Carlos - SP, 13566-590 </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
      <Footer />
    </div>
  );
}

export default ContactPage;