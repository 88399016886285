import { useState } from "react";
import CameraModal from "../Modal/CameraModal";
import ErrorOutput from "../../Output/ErrorOutput";
import TypeHeadGeneric from "../TypeHead/TypeHeadGeneric";

const TypeHeadQRCodeGeneric = ({ onChange, fetchOptionsFunction, fetchOptionsArguments, typeHeadOptions, changeToClearSelection }) => {
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const innerOnChange = (e) => {
    setError(null);
    onChange(e);
  };

  return (
    <>
      <div className="row">
        <div className="col-8 col-sm-10">
          <TypeHeadGeneric
            onChange = {innerOnChange}
            fetchOptionsFunction = {fetchOptionsFunction}
            fetchOptionsArguments = {fetchOptionsArguments}
            typeHeadOptions = {typeHeadOptions}
            changeToClearSelection = {changeToClearSelection}
          />
        </div>
        <div className="col-4 col-sm-2 d-flex align-items-end">
          <button
            type="button"
            className="btn btn-info"
            onClick={() => {
              setShowModal(true);
            }}
          >
            QRCode
          </button>
        </div>
      </div>

      <CameraModal
        id={typeHeadOptions.id + "CameraModal"}
        onError={(e) => setError(e.message || "Ocorreu um erro na leitura da QRCode")}
        onScan={(e) => {
          setShowModal(false);
          innerOnChange(e)
        }}
        showModal={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        title={"Posicione o QRCode do Item na Tela"}
      />

      <div className="mb-3"/>

      <ErrorOutput error={error} />
    </>
  );
};

export default TypeHeadQRCodeGeneric;
