import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import QueryPage from "./pages/directAccess/query";
import ImportTablePage from "./pages/directAccess/import";
import BackupPage from "./pages/directAccess/backup/";
import CreatePartPage from "./pages/crud/part/create";
import ReadPartPage from "./pages/crud/part/read";
import CreateUnitPage from "./pages/crud/unit/create";
import CreateBomPage from "./pages/crud/bom/create";
import CreateProcessPage from "./pages/crud/process/create";
import ReadBomPage from "./pages/crud/bom/read";
import CreateItemPage from "./pages/crud/item/create";
import CreateDocumentPage from "./pages/crud/document/create";
import CreateEventPage from "./pages/crud/event/create";
import CreatePersonPage from "./pages/crud/person/create";
import ReadItemPage from "./pages/crud/item/read";
import SelectItemManufacturingPage from "./pages/usage/manufacturing/selectItem/selectitemmanufacturing";
import CreateItemManufacturingPage from "./pages/usage/manufacturing/selectItem/createitemmanufacturing";
import SelectEventManufacturingPage from "./pages/usage/manufacturing/selectItem/selectevent/selecteventmanufacturing";
import CreateEventManufacturingPage from "./pages/usage/manufacturing/selectItem/selectevent/createeventmanufacturing";
import EventPageManufacturing from "./pages/usage/manufacturing/selectItem/selectevent/event/eventpagemanufacturing";
import CreateInputItemPage from "./pages/usage/manufacturing/selectItem/selectevent/event/createinputitem";
import SelectItemOperationPage from "./pages/usage/operation/selectItem/selectitemoperation";
import OperationPage from "./pages/usage/operation/operation/operation";
import EventOvervViewPage from "./pages/usage/overview/event";
import StatusPage from "./pages/usage/robots/status";
import PurchasePage from "./pages/usage/purchase/purchase";
import ContactPage from "./pages/contact/contact";
import LoginPage from "./pages/login";
import ToBeDevelopedPage from "./pages/tbd";
import TestPage from "./pages/test";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/contact" element={<ContactPage />}></Route>
        <Route path="/directAccess/query" element={<QueryPage />}></Route>
        <Route path="/directAccess/importCsv" element={<ImportTablePage />}></Route>
        <Route path="/directAccess/backup" element={<BackupPage />}></Route>
        <Route path="/crud/part/create" element={<CreatePartPage />}></Route>
        <Route path="/crud/part/read" element={<ReadPartPage />}></Route>
        <Route path="/crud/unit/create" element={<CreateUnitPage />}></Route>
        <Route path="/crud/bom/create" element={<CreateBomPage />}></Route>
        <Route path="/crud/bom/READ" element={<ReadBomPage />}></Route>
        <Route path="/crud/process/create" element={<CreateProcessPage />}></Route>
        <Route path="/crud/item/create" element={<CreateItemPage />}></Route>
        <Route path="/crud/document/create" element={<CreateDocumentPage />}></Route>
        <Route path="/crud/item/read" element={<ReadItemPage />}></Route>
        <Route path="/crud/event/create" element={<CreateEventPage />}></Route>
        <Route path="/crud/person/create" element={<CreatePersonPage />}></Route>
        <Route path="/usage/manufacturing/selectItem/selectitemmanufacturing" element={<SelectItemManufacturingPage />}></Route>
        <Route path="/usage/manufacturing/selectItem/createitemmanufacturing" element={<CreateItemManufacturingPage />}></Route>
        <Route path="/usage/manufacturing/selectItem/createitemmanufacturing/:partId" element={<CreateItemManufacturingPage />}></Route>
        <Route path="/usage/manufacturing/selectItem/selectevent/selecteventmanufacturing/:itemId" element={<SelectEventManufacturingPage />}></Route>
        <Route  path="/usage/manufacturing/selectItem/selectevent/createeventmanufacturing/:itemId" element={<CreateEventManufacturingPage />}></Route>
        <Route path="/usage/manufacturing/selectItem/selectevent/event/eventpagemanufacturing/:eventId" element={<EventPageManufacturing />}></Route>
        <Route path="/usage/manufacturing/selectItem/selectevent/event/createinputitem/:personId/:eventId/:partId/:quantityIndex" element={<CreateInputItemPage />}></Route>
        <Route path="/usage/operation/selectItem/selectitemoperation" element={<SelectItemOperationPage />}></Route>
        <Route path="/usage/operation/:itemId" element={<OperationPage />}></Route>
        <Route path="/usage/robots/status" element={<StatusPage />}></Route>
        <Route path="/usage/overview/event" element={<EventOvervViewPage />}></Route>
        <Route path="/usage/purchase" element={<PurchasePage />}></Route>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/tbd" element={<ToBeDevelopedPage />}></Route>
        <Route path="/test" element={<TestPage />}></Route>
      </Routes>
    </Router>
  );
};

export default App;
