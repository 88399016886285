import React from 'react';
import { displayHeaders } from '../../../utils/config/config';

const GraphQLTable = ({ graphQLSingleTableData, columnsToRemove = ['__typename', 'createdAt', 'updatedAt', 'deletedAt'], columnsOrder = [] }) => {
  let rows = [];
  let headers = new Set();

  for (let i = 0; i < graphQLSingleTableData.length; i++) {
    const row = {};
    QueryTableFlattenData(headers, row, graphQLSingleTableData[i], columnsToRemove);
    rows.push(row);
  }

  if (headers.has('id')) {
    headers.delete('id');
    headers = new Set(['id', ...headers]);
  }

  columnsToRemove.forEach(column => {
    if (headers.has(column)) {
      headers.delete(column);
    }
  });

  // Reorder headers based on columnsOrder
  const orderedHeaders = columnsOrder.filter(col => headers.has(col));
  const remainingHeaders = Array.from(headers).filter(header => !columnsOrder.includes(header));
  const finalHeaders = orderedHeaders.concat(remainingHeaders);

  return (
    <div className="table-responsive rounded">
      <table className="table table-striped table-bordered rounded">
        <thead className="table-dark">
          <tr>
            {finalHeaders.map((header, index) => (
              <th key={index}>
                {displayHeaders[header] ? displayHeaders[header] : header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {finalHeaders.map((header, columnIndex) => (
                <td key={columnIndex}>{row[header]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

function QueryTableFlattenData(headers, row, data, columnsToRemove, prefix = '') {
  if (typeof data === 'object' && data !== null) {
    for (const key in data) {
      if (data.hasOwnProperty(key) && !columnsToRemove.includes(key)) {
        const newKey = prefix ? `${prefix}${key}` : key;
        if (typeof data[key] === 'object') {
          QueryTableFlattenData(headers, row, data[key], columnsToRemove, newKey);
        } else {
          headers.add(newKey);
          row[newKey] = data[key];
        }
      }
    }
  }
}

export default GraphQLTable;
