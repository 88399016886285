import { useState } from "react";
import Head from "../../../components/Header";
import Navbar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import PartCardDetailed from "../../../components/Output/Card/PartCardDetailed";
import AuthorCard from "../../../components/Output/Card/AuthorCard";
import TablePartStarmap from "../../../components/Output/PartInfo/PartStarmapTable";
import PartDocumentsTable from "../../../components/Output/PartInfo/PartDocumentsTable";
import PartItemsTable from "../../../components/Output/PartInfo/PartItemsTable";
import PartBomsTable from "../../../components/Output/PartInfo/PartBomsTable";
import PartParentsTable from "../../../components/Output/PartInfo/PartParentsTable";

import TypeHead from "../../../components/Input/TypeHead/TypeHead";

const PartsPage = () => {
  const [partId, setPartId] = useState(null);

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="m-5 text-center">
          <h1>Verificar Peça</h1>
        </div>
        <div className="mt-3 mb-3 rounded bg-light p-4">
          <h3>Selecione sua Peça</h3>
          <TypeHead
            name="partTypeHead"
            table="part"
            labelColumn="name"
            placeholder={"Pesquise pelo produto que deseja vizualisar"}
            valueColumn="id"
            auxiliarColumn="description"
            onChange={(e) => {
              setPartId(e.value);
            }}
          />
          <div className="mb-3" />
        </div>
        {partId ? (
          <>
            <div className="mt-3 mb-3 rounded bg-light p-4">
              <h2>Informações da Básicas</h2>
              <div className="m-3" />
              <h4>Informações da Peça</h4>
              <PartCardDetailed partId={partId} />
              <div className="m-3" />
              <h4>Informações do Autor</h4>
              <AuthorCard partId={partId} />
            </div>
            <TablePartStarmap partId={partId} />
            <PartDocumentsTable partId={partId} />
            <PartItemsTable partId={partId} />
            <PartParentsTable partId={partId} />
            <PartBomsTable partId={partId} />
          </>
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default PartsPage;
