import config from '../../../config/config';
import postController from '../../postController';

// Function to fetch data from GraphQL API
async function createForManufacturing(itemInput) {
  try {
    // initialize variables
    const variables = itemInput

    // Make a post request
    const response = await postController(variables, config.graphQLServerHost, config.createItemForManufacturingUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller createForManufacturing:', error.message);
    throw(error)
  }
} 

export default createForManufacturing;
