import Head from "../../components/Header";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";

const TestPage = () => {
  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="mb-4">
          <h1>TESTE</h1>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TestPage;
