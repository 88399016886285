import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Head from "../../../components/Header";
import Navbar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import PurchaseForm from "../../../components/Input/Forms/PurchaseForm";
import ErrorOutput from "../../../components/Output/ErrorOutput";
import CreatePurchase from "../../../utils/graphql/controllers/createPurchase";

const PurchasePage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  
  const onSubmit = async (input) => {
    setError(null);
    try {
      const response = await CreatePurchase(input);
      const nextPageUrl = `/usage/manufacturing/selectItem/selectevent/event/eventpagemanufacturing/${response.data.id}`;
      navigate(nextPageUrl);
    } catch (error) {
      setError(error.message || "Ocorreu um erro");
    }
  };

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="m-5 text-center">
          <h1>Inserir Lista de Compras</h1>
        </div>
        <PurchaseForm onSubmit={onSubmit} />
        <ErrorOutput error={error} />
      </div>
      <Footer />
    </div>
  );
};

export default PurchasePage;
