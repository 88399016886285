import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../styles/navbar.css';

const MyNavbar = () => {
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  return (
    <Navbar bg="body-tertiary" variant="light" expand="lg" className="c-header c-header-light c-header-fixed sticky-top">
      <Navbar.Brand href="https://wr.sc.usp.br/" title="Warthog Robotics" className="mx-2">
        <img className="site-logo" src={process.env.PUBLIC_URL + '/static/imgs/brand/wr_logo_horizontal_128.png'} alt="Warthog Robotics brand" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarSupportedContent" className="mx-2"/>
      <Navbar.Collapse id="navbarSupportedContent" className="mx-2">
        <Nav className="me-auto">
          <Nav.Link href="/" className="c-sidebar-nav-link">Início</Nav.Link>

          <NavDropdown title="Usuário" id="user-dropdown" className="c-sidebar-nav-dropdown">
            <NavDropdown.Item href="/usage/manufacturing/selectItem/selectitemmanufacturing" className="c-sidebar-nav-link">Fabricação</NavDropdown.Item>
            <NavDropdown.Item href="/usage/operation/selectItem/selectitemoperation" className="c-sidebar-nav-link">Operação</NavDropdown.Item>
            <NavDropdown.Item href="/usage/overview/event" className="c-sidebar-nav-link">Acompanhamento de processos</NavDropdown.Item>
            <NavDropdown.Item href="/usage/robots/status" className="c-sidebar-nav-link">Acompanhamento dos robôs</NavDropdown.Item>
            <NavDropdown.Item href="/usage/purchase" className="c-sidebar-nav-link">Compras</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Criar" id="crud-dropdown" className="c-sidebar-nav-dropdown">
              <NavDropdown.Item href="/crud/part/create" className="c-sidebar-nav-link">Criar Peça</NavDropdown.Item>
              <NavDropdown.Item href="/crud/unit/create" className="c-sidebar-nav-link">Criar Unidade</NavDropdown.Item>
              <NavDropdown.Item href="/crud/bom/create" className="c-sidebar-nav-link">Criar BOM</NavDropdown.Item>
              <NavDropdown.Item href="/crud/item/create" className="c-sidebar-nav-link">Criar Item</NavDropdown.Item>
              <NavDropdown.Item href="/crud/event/create" className="c-sidebar-nav-link">Criar Evento</NavDropdown.Item>
              <NavDropdown.Item href="/crud/document/create" className="c-sidebar-nav-link">Criar Documento</NavDropdown.Item>
              <NavDropdown.Item href="/crud/process/create" className="c-sidebar-nav-link">Criar Processo</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Ler" id="crud-dropdown" className="c-sidebar-nav-dropdown">
              <NavDropdown.Item href="/crud/part/read" className="c-sidebar-nav-link">Ler Peça</NavDropdown.Item>
              <NavDropdown.Item href="/crud/item/read" className="c-sidebar-nav-link">Ler Item</NavDropdown.Item>
              <NavDropdown.Item href="/crud/bom/read" className="c-sidebar-nav-link">Ler BOM</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Desenvolvedor" id="table-dropdown" className="c-sidebar-nav-dropdown">
            <NavDropdown.Item href="/directAccess/importCsv" className="c-sidebar-nav-link">Importar CSV</NavDropdown.Item>
            <NavDropdown.Item href="/directAccess/query" className="c-sidebar-nav-link">Executar Query</NavDropdown.Item>
            <NavDropdown.Item href="/directAccess/backup" className="c-sidebar-nav-link">Executar BackUp</NavDropdown.Item>
          </NavDropdown>

          <Nav.Link href="/contact" className="c-sidebar-nav-link">Contato</Nav.Link>
        </Nav>
        <hr className="solid d-lg-none" />
        {isLoggedIn ?
        <>
          <Nav className="ml-auto">
            <Nav.Link href="/login" className="c-header-nav-link">Olá, {localStorage.getItem("userFirstName")}</Nav.Link>
          </Nav>
        </>
        :
        <>
          <Nav className="ml-auto">
            <Nav.Link href="/login" className="c-header-nav-link">Entrar</Nav.Link>
            <span className="c-header-nav-link d-none d-lg-block my-lg-separator"></span>
            <Nav.Link href="/crud/person/create" className="c-header-nav-link">Registrar-se</Nav.Link>
          </Nav>
        </>
        }
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MyNavbar;
