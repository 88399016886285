import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import fetchPartDetails from "../../../../../../utils/graphql/fetches/fetchPartDetails";
import ItemAvaiableForInputByPartTypeheadQrCode from "../../../../../Input/TypeHeadQRCode/ItemAvaiableForInputByPartTypeheadQrCode";
import getCurrentInputForManufacturingBody from "../../../../../../utils/graphql/controllers/getCurrentInputForManufacturingBody";
import updateEventInput from "../../../../../../utils/graphql/crud/input/update";
import deleteInput from "../../../../../../utils/graphql/crud/input/delete";
import generateInputItem from "../../../../../../utils/graphql/crud/input/generateInputItem";
import getItemLastStatus from "../../../../../../utils/graphql/controllers/getItemLastStatus";
import ErrorOutput from "../../../../../Output/ErrorOutput";
import YesNoAlert from "../../../../../Input/Modal/YesNoAlert";
import config from "../../../../../../utils/config/config"

const ManufacutingBodyInputElement = ({
  eventId,
  partId,
  quantityIndex,
  personId,
  showEditOptions,
  onChangeCurrentInput,
  totalQuantity,
  countable
}) => {
  const [error, setError] = useState(null);
  const [part, setPart] = useState(null);
  const [currentInput, setCurrentInput] = useState(null);
  const [currentInputStatus, setCurrentInputStatus] = useState(null);
  const [triggerCurrentInputChange, seTtriggerCurrentInputChange] = useState(false);
  const [newInput, setNewInput] = useState(null);
  const navigate = useNavigate();
  const [changeToClearSelection, setChangeToClearSelection] = useState(false);
  const [cardBackgroundColor, setCardBackgroundColor] = useState("#ffffff");
  const [showAlert, setShowAlert] = useState(false);
  const [showGoToEventButton, setShowGoToEventButton] = useState(false);
  const [manufactureThisInputEventId, setManufactureThisInputEventId] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const partResponse = await fetchPartDetails(partId);
        setPart(partResponse.data.part[0]);
      } catch (error) {
        console.error("Error fetching part data:", error);
      }
    };
    fetchData();
  }, [partId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const inputResponse = await getCurrentInputForManufacturingBody(
          eventId,
          partId,
          quantityIndex
        );
        if (inputResponse?.data?.currentInput?.Item) {
          const currentInputStatusResponse = await getItemLastStatus(
            inputResponse?.data?.currentInput?.Item?.id
          );
          setCurrentInput(inputResponse?.data?.currentInput?.Item);
          setCurrentInputStatus(currentInputStatusResponse.data);
        }
        if(inputResponse && inputResponse.data && inputResponse.data.goNextEvent){
          setShowGoToEventButton(true);
          setManufactureThisInputEventId(inputResponse.data.goNextEvent.id);
        }
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    fetchData();
  }, [eventId, partId, quantityIndex, triggerCurrentInputChange]);

  useEffect(() => {
    const changeBackgroundColor = async () => {
      if (showEditOptions) {
        if (currentInputStatus?.id === config.fixedIds.status.mustManufacture) {
          //needs to wait manufacturing
          setCardBackgroundColor("#f8ffb3");
        } else if (
          currentInputStatus?.id === config.fixedIds.status.storage
        ) {
          // good
          setCardBackgroundColor("#cdffb3");
        } else if (currentInputStatus === null) {
          // nothign selected
          setCardBackgroundColor("#ffffff");
        } else {
          // smoehting strange
          setCardBackgroundColor("#ffcccd");
        }
      } else {
        if (currentInputStatus?.id === config.fixedIds.status.manufacturing) {
          // being used
          setCardBackgroundColor("#cccccc");
        } else {
          // others
          setCardBackgroundColor("#ffffff");
        }
      }
    };

    changeBackgroundColor();
  }, [currentInputStatus, triggerCurrentInputChange, showEditOptions]);

  const applyInputChange = async () => {
    setError(null);
    if (newInput === null || newInput === "") {
      setError("Selecione um item.");
      return;
    }
    if (personId === null || personId === "") {
      setError("Selecione quem você é.");
      return;
    }
    const variables = {
      eventId: eventId,
      quantityIndex: quantityIndex,
      itemId: newInput,
      personId: personId,
    };
    try {
      await updateEventInput(variables);
    } catch (error) {
      setError(error.message);
    }
    seTtriggerCurrentInputChange(!triggerCurrentInputChange);
    setChangeToClearSelection(!changeToClearSelection);
    setNewInput(null);
    onChangeCurrentInput(variables);
  };

  const applyInputChangeQRCode = async (value) => {
    setError(null);
    if (value === null || value === "") {
      setError("Selecione um item.");
      return;
    }
    if (personId === null || personId === "") {
      setError("Selecione quem você é.");
      return;
    }
    const variables = {
      eventId: eventId,
      quantityIndex: quantityIndex,
      itemId: value,
      personId: personId,
    };
    try {
      await updateEventInput(variables);
    } catch (error) {
      setError(error.message);
    }
    seTtriggerCurrentInputChange(!triggerCurrentInputChange);
    setChangeToClearSelection(!changeToClearSelection);
    setNewInput(null);
    onChangeCurrentInput(variables);
  };

  const removeInput = async () => {
    setError(null);
    if (currentInput === null || currentInput === "") {
      setError("Nenhum item esta sendo utilziado como entrada neste registro.");
      return;
    }
    if (personId === null || personId === "") {
      setError("Selecione quem você é.");
      return;
    }
    const variables = {
      eventId: eventId,
      quantityIndex: quantityIndex,
      itemId: currentInput?.id,
      personId: personId,
    };
    try {
      await deleteInput(variables);
    } catch (error) {
      setError(error.message);
    }
    seTtriggerCurrentInputChange(!triggerCurrentInputChange);
    setChangeToClearSelection(!changeToClearSelection);
    setNewInput(null);
    onChangeCurrentInput(variables);
  };

  const generateItem = async () => {
    setError(null);
    if (part === null || part === "") {
      setError("Ocorreu um erro.");
      return;
    }
    if (personId === null || personId === "") {
      setError("Selecione quem você é.");
      return;
    }
    setShowAlert(true);
  };

  const handleGenereItemAlertYes = async () => {
    setError(null);
    const variables = {
      partId: part.id,
      quantity: countable ? 1 : totalQuantity,
      eventId: eventId,
      quantityIndex: quantityIndex,
      personId: personId,
    };
    try {
      await generateInputItem(variables);
    } catch (error) {
      setError(error.message);
    }
    seTtriggerCurrentInputChange(!triggerCurrentInputChange);
    setChangeToClearSelection(!changeToClearSelection);
    setNewInput(null);
    onChangeCurrentInput(variables);
  };

  const goToCreateItemPage = async () => {
    setError(null);
    if (personId === null || personId === "") {
      setError("Selecione quem você é.");
      return;
    }
    const nextPageUrl = `/usage/manufacturing/selectItem/selectevent/event/createinputitem/${personId}/${eventId}/${part.id}/${quantityIndex}`;
    navigate(nextPageUrl);
  };

  const goToEvent = async () => {
    const nextPageUrl = `/usage/manufacturing/selectItem/selectevent/event/eventpagemanufacturing/${manufactureThisInputEventId}`;
    navigate(nextPageUrl);
    return;
  }

  return (
    <div className="card" style={{ backgroundColor: cardBackgroundColor }}>
      <div className="card-body">
        <h5 className="card-title">
          {part?.name} - Número {quantityIndex}{" "}
        </h5>
        <p className="card-text">
          <strong>Descrição:</strong> {part?.description} <br />
          <strong>Item Atual:</strong> {currentInput?.id} <br />
          <strong>Descrição do Item Atual:</strong> {currentInput?.description}{" "}
          <br />
          <strong>Estado do Item Atual:</strong> {currentInputStatus?.name}{" "}
          <br />
        </p>
      </div>

      {showEditOptions && (
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-8">
              <strong>Trocar Item:</strong>{" "}
              <ItemAvaiableForInputByPartTypeheadQrCode
                id={"itemId"}
                placeholder={"Selecione o Item"}
                onChange={(e) => {
                  setNewInput(e.value);
                  applyInputChangeQRCode(e.value);
                }}
                partId={part?.id}
                changeToClearSelection={changeToClearSelection}
              />
              <br />
              <ErrorOutput error={error} />
            </div>
            <div className="col-1"></div>
            <div className="col-md-3">
              <div className="row">
                <div className="col-lg-6">
                  <button
                    type={"button"}
                    onClick={applyInputChange}
                    className="btn btn-sm btn-info mb-2 md-2 w-100"
                  >
                    Trocar
                  </button>
                </div>
                <div className="col-lg-6">
                  <button
                    type={"button"}
                    onClick={removeInput}
                    className="btn btn-sm btn-danger mb-2 md-2 w-100"
                  >
                    Remover
                  </button>
                </div>
              </div>
              <div>
                <button
                  type={"button"}
                  onClick={generateItem}
                  className="btn btn-sm btn-dark mb-2 md-2 w-100"
                >
                  Gerar item
                </button>
              </div>
              <div>
                <button
                  type={"button"}
                  onClick={goToCreateItemPage}
                  className="btn btn-sm btn-success mb-2 md-2 w-100"
                >
                  Criar este Item
                </button>
              </div>
              {showGoToEventButton && (
                <>
                  <div>
                    <button
                      type={"button"}
                      onClick={goToEvent}
                      className="btn btn-sm btn-success mb-2 md-2 w-100"
                    >
                      Ir ao evento de fabricação
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <>
        {showAlert && showEditOptions && (
          <YesNoAlert
            message={
              "Essa instrução irá criar um item novo através de um processo genérico sem entrada com UUID aleatório, o que significa que a montagem deste item, e suas montagens recursivamente, não serão monitorados pleo sistema. Caso possua um item em mãos utilize a caixa ao lado para seleciona-lo e caso queira criar um item e manter seu rastreio use o botão para criar item logo abaixo."
            }
            title={"Atenção!"}
            showModal={showAlert}
            handleYes={handleGenereItemAlertYes}
            handleNo={() => {
              setShowAlert(false);
            }}
            handleClose={() => {
              setShowAlert(false);
            }}
          />
        )}
      </>
    </div>
  );
};

export default ManufacutingBodyInputElement;
