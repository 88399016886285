import React from 'react';
import GraphQLTable from "./GraphQLTable";

const QueryTables = ({ queryData, containerTitles, columnsToRemove = ['__typename', 'createdAt', 'updatedAt', 'deletedAt'], columnsOrder = [] }) => {
  if (!queryData) {
    return null;
  }

  const tableComponents = Object.keys(queryData).map((tableName, index) => {
    return (
      <div key={index}>
        <div className="p-3 rounded">
          <h2>{containerTitles[index]}</h2>
          <GraphQLTable graphQLSingleTableData={queryData[tableName]} columnsToRemove={columnsToRemove} columnsOrder={columnsOrder} />
        </div>
      </div>
    );
  });

  return (
    <div>
      {tableComponents}
    </div>
  );
};

export default QueryTables;
