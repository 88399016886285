import { useState, useEffect } from "react";
import getBomStructure from "../../../utils/graphql/controllers/getBomStructure";
import BomTable from "./BomTable";

const BomInfo = ({ bomId, sideBySide }) => {
  const [result, setResult] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      if (bomId) {
        const data = await getBomStructure(bomId);
        if (data && data.data && data.data.bom) {
          setResult({ bom: data.data.bom, bomStructure: data.data });
        } else {
          setResult();
        }
      } else {
        setResult();
      }
    };
    fetchData();
  }, [bomId]);

  return (
    <>
      <BomTable bomSingleTableData={result} sideBySide={sideBySide} />
    </>
  );
};

export default BomInfo;
