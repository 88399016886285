import { useState, useEffect } from 'react';
import QueryOutput from '../QueryOutput'
import getItemLastAssembly from '../../../utils/graphql/controllers/getItemLastAssembly'

const columnsToRemove = [
  '__typename',
  'Partid',
  'Partdescription',
  'Partdate',
  'Partversion',
  'PartcreatedAt',
  'PartupdatedAt',
  'PartunitId',
  'ParttypeId',
  'PartsupplyId',
  'PartpeopleId',
  'Inputid',
  'InputquantityIndex',
  'InputcreatedAt',
  'InputupdatedAt',
  'InputeventId',
  'InputinputId',
  'InputItemcreatedAt',
  'InputItemupdatedAt',
  'InputItemPartid',
  'InputItempartId',
  'InputItemPartname',
  'InputItemPartdescription',
  'InputItemPartcreatedAt',
  'InputItemPartupdatedAt',
  'InputItemPartunitId',
  'InputItemParttypeId',
  'InputItemPartsupplyId'
];

const ItemEvent = ({ itemId }) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getItemLastAssembly(itemId);
      setResult(response);
    };
    fetchData();
  }, [itemId]);


  if (!itemId) {
    return (<></>);
  }

  return (
    <> 
      <QueryOutput
        queryResult={result}
        containerTitles={''}
        MajorContainerTitle={'Montagem do seu Item'}
        columnsToRemove={columnsToRemove}
      />
    </>
  );
};

export default ItemEvent;