import React, { useState, useEffect } from 'react';
import ParentsOutput from '../ParentsInfo/ParentsOutput';
import getPartParents from '../../../utils/graphql/controllers/getPartParents';

const PartParentsTable = ({ partId }) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const getPartParentsAsync = async () => {
      const data = await getPartParents(partId);
      setResult(data);
    };
    getPartParentsAsync();
  }, [partId]);


  return (
    <div>
      <ParentsOutput parentsResult={result} />
    </div>
  );
};

export default PartParentsTable;
