import React, { useState, useEffect } from 'react';
import BomOutput from '../BomInfo/BomOutput';
import getPartBoms from '../../../utils/graphql/controllers/getPartBoms';

const PartBomsTable = ({ partId }) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const getPartBomsAsync = async () => {
        const data = await getPartBoms(partId);
        setResult(data);
    };
    getPartBomsAsync();
  }, [partId]);


  return (
    <div>
      <BomOutput bomResult={result}/>
    </div>
  );
};

export default PartBomsTable;
