import { useState } from "react";
import UserTypeHead from "../../Input/TypeHead/UserTypeHead";
import TypeHeadGeneric from "../../Input/TypeHead/TypeHeadGeneric";
import testRequired from "../Forms/utils/testRequired";
import ErrorOutput from "../../Output/ErrorOutput";
import DepositProduct from "../../../utils/graphql/controllers/depositProduct";
import config from "../../../utils/config/config";

const DepositModal = ({ itemId, lasStatusId, isOpen, onClose }) => {
  const [error, setError] = useState(null);
  const [resetForm, setResetForm] = useState(false);
  const [formData, setFormData] = useState({
    itemId: itemId,
    personId: "",
    comment: "",
    usage: "",
  });

  const clearForm = () => {
    setFormData({
      itemId: itemId,
      personId: "",
      comment: "",
      usage: "",
    });
    setResetForm(!resetForm);
  };

  const fetchUsageOption = () => {
    if (lasStatusId === config.fixedIds.status.usage) {
      return [
        {
          label: "Retornando ao Estoque",
          value: "storage",
        },
        {
          label: "Precisa Testar",
          value: "mustTest",
        },
        {
          label: "Precisa de Manutenção",
          value: "mustMaintenence",
        },
      ];
    }
    if (lasStatusId === config.fixedIds.status.test) {
      return [
        {
          label: "Retornando ao Estoque",
          value: "storage",
        },
        {
          label: "Precisa de Manutenção",
          value: "mustMaintenence",
        },
      ];
    }
    if (lasStatusId === config.fixedIds.status.maintenence) {
      return [
        {
          label: "Precisa Testar",
          value: "mustTest",
        },
        {
          label: "Precisa de Manutenção",
          value: "mustMaintenence",
        },
      ];
    }
  };

  const onChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const onSubmit = async () => {
    setError(null);
    if (testRequired(formData)) {
      alert("Por favor, Preencha todos os campos.");
      return;
    }
    try {
      await DepositProduct(formData);
      window.location.reload();
      innerOnClose();
    } catch (error) {
      setError(error.message || "Ocorreu um erro.");
      return;
    }
    clearForm();
  };

  const innerOnClose = () => {
    clearForm();
    setError(null);
    onClose();
  };

  return (
    <div
      className={`modal ${
        isOpen ? "show d-flex align-items-center justify-content-center" : ""
      }`}
      tabIndex="-1"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog" style={{ minWidth: "90vw" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Retirar Produto</h5>
            <button
              type="button"
              className="close btn"
              aria-label="Close"
              onClick={innerOnClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form">
              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Quem esta devolvendo o produto?{" "}
                </label>
                <UserTypeHead
                  typeHeadOptions={{
                    id: "personId",
                    placeholder: "Quem é você",
                  }}
                  changeToClearSelection={resetForm}
                  onChange={(e) => {
                    onChange(e.id, e.value);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Como esta a condição de retorno do produo?{" "}
                </label>
                <TypeHeadGeneric
                  fetchOptionsFunction={fetchUsageOption}
                  typeHeadOptions={{
                    id: "usage",
                    placeholder: "Condição do produto.",
                  }}
                  onChange={(e) => {
                    onChange(e.id, e.value);
                  }}
                  changeToClearSelection={resetForm}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="comment" className="form-label">
                  Descrição:
                </label>
                <textarea
                  className="form-control"
                  name="comment"
                  placeholder="Descreva as condições de devolução do produto, qual erro encontrou caso tenha encontrado, como foi utilizado e etc."
                  value={formData.comment}
                  onChange={(e) => {
                    onChange(e.target.name, e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="container">
              <ErrorOutput error={error} />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={onSubmit}
            >
              Devolver
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositModal;
