import TypeHeadGeneric from "./TypeHeadGeneric";
import NextValidStatusTypeHeadFetchOptions from "./NextValidStatusTypeHeadFetchOptions";

const NextValidStatusTypeHead = ({
  typeHeadOptions,
  fetchOptionsArguments,
  changeToClearSelection,
  onChange,
}) => {
  const { id, disabled, placeholder } = typeHeadOptions;

  return (
    <TypeHeadGeneric
      onChange={onChange}
      fetchOptionsFunction={NextValidStatusTypeHeadFetchOptions}
      fetchOptionsArguments={{ ...fetchOptionsArguments, itemId: fetchOptionsArguments.itemId, statusId: fetchOptionsArguments.statusId}}
      typeHeadOptions={{
        id: id,
        disabled: disabled || false,
        placeholder: placeholder,
      }}
      changeToClearSelection={changeToClearSelection}
    />
  );
};

export default NextValidStatusTypeHead;
