import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchPartDocuments = async (partId) => {
  try {
    const query = `{
      part(id: "${partId}") {
        Documents {
          id
          title
          description
          date
          version
          url
        }
      }
    }          
    `;
      
    let response = await fetchGraphQLQuery(query);
    response = {...response, data:{Documents: response.data.part[0].Documents}}
    return response
  } catch(error) {
    console.error('Error fetching part documents:', error);
  }
};

export default fetchPartDocuments;
