async function postController(data, endpoint, url) {
    try {
      const fullUrl = `${endpoint}${url}`;
  
      const response = await fetch(fullUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(data), // Sending data as JSON string
      });

      const responseJson = await response.json();
      if (!response.ok) {
        throw new Error(`Posting to ${endpoint}${url} failed with response status: ${response.status} and message: ${responseJson.status.message}`);
      }

      return await responseJson;
    } catch (error) {
      throw error;
    }
  }
  
  export default postController;
  