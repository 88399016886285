import config from '../../config/config';
import fetchController from '../fetchController';

// Function to fetch data from GraphQL API
async function getItemLastStatus(itemId) {
  try {
    // initialize variables
    const variables = {itemId: itemId}

    // Make a GET request
    const response = await fetchController(variables, config.graphQLServerHost, config.getItemLastStatusUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller getItemLastStatus:', error);
  }
}

export default getItemLastStatus;
