import fetchPartItems from "../../../utils/graphql/fetches/fetchPartItems";

async function ItemFromPartIdTypeHeadFecthOptions({ partId, input }) {
  try {
    const response = await fetchPartItems(partId, input);
    let options = [];
    for (const item of response.data.Items) {
      options.push({
        label: item["id"],
        value: item["id"],
        auxLabel: item["description"],
      });
    }
    return options;
  } catch (error) {
    console.error("Error fetching options:", error);
  }
}

export default ItemFromPartIdTypeHeadFecthOptions;