import React, { useState, useEffect } from "react";
import ItemChildIdTypeHead from "../../../../Input/TypeHead/ItemChildIdTypeHead";

const ChildrenInputRelationList = ({ children, onSelectedValuesChange }) => {
  const [combinedValues, setCombinedValues] = useState({});
  const [prevCombinedValues, setPrevCombinedValues] = useState(combinedValues);

  useEffect(() => {
    if (prevCombinedValues !== combinedValues) {
      onSelectedValuesChange(combinedValues);
      setPrevCombinedValues(combinedValues);
    }
  }, [combinedValues, prevCombinedValues, onSelectedValuesChange]);

  const handleSelection = (childIdQuantity, e) => {
    const [childId, quantity] = childIdQuantity.split("|");
    const newValue = e.value;

    setCombinedValues((prevValues) => ({
      ...prevValues,
      [childId]: {
        ...prevValues[childId],
        selectedValues: {
          ...(prevValues[childId]?.selectedValues || {}),
          [quantity]: newValue,
        },
      },
    }));
  };

  return (
    <>
      {children && (
        <div className="container">
          {children.map((child, index) => {
            const lastSixDigitsId = child.id.slice(-6);
            const selectionBoxes = Array.from(
              { length: child.Unit.countable ? child.quantity : 1 },
              (_, i) => (
                <div
                  key={i}
                  className="form-group mb-0 d-flex align-items-center"
                >
                  <div className="m-2">
                    <ItemChildIdTypeHead
                      typeHeadOptions={{id: child.id, placeholder: "Selecione o item"}}
                      fetchOptionsArguments={{childId: child.id}}
                      onChange={(e) => handleSelection(`${child.id}|${i}`, e)}
                    />
                  </div>
                </div>
              )
            );

            return (
              <div key={index} className="card mb-3">
                <div className="card-body d-flex justify-content-between align-items-center">
                  <div>
                    <p className="mb-1">
                      <strong>Identificação:</strong> {lastSixDigitsId} -{" "}
                      {child.name} | <strong>Quantidade:</strong>{" "}
                      {child.quantity} {child.Unit.symbol}
                    </p>
                    <p className="mb-1">
                      <strong>Descrição:</strong> {child.description}
                    </p>
                  </div>
                  <div className="form-group mb-0">{selectionBoxes}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ChildrenInputRelationList;
