import getNextValidStatus from "../../../utils/graphql/controllers/getNextValidStatus";

const NextValidStatusTypeHeadFetchOptions = async ({ itemId, statusId, input }) => {
  try {
    const response = await getNextValidStatus(itemId, statusId, input);
    let options = [];
    if (response && response.data) {
      options = response.data.map((status) => ({
        label: status.name,
        value: status.id,
        auxLabel: "",
      }));
      return options;
    }
  } catch (error) {
    console.error(`Error fetching next valid status:`, error);
  }
};

export default NextValidStatusTypeHeadFetchOptions;
