import config from '../../../config/config';
import postController from '../../postController';

// Function to fetch data from GraphQL API
async function createPart(partInput) {
  try {
    // initialize variables
    const variables = partInput

    // Make a post request
    const response = await postController(variables, config.graphQLServerHost, config.createPartUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller createPart:', error.message);
    throw(error)
  }
} 

export default createPart;
