import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Head from "../../../../components/Header";
import Navbar from "../../../../components/NavBar";
import Footer from "../../../../components/Footer";
import ItemCardDetailed from "../../../../components/Output/Card/ItemCardDetailed";
import ItemStatusCard from "../../../../components/Output/Card/ItemStatusCard";
import WithdrawModal from "../../../../components/Input/Modal/WithdrawModal";
import DepositModal from "../../../../components/Input/Modal/DepositModal";
import getItemLastStatus from "../../../../utils/graphql/controllers/getItemLastStatus";
import config from "../../../../utils/config/config";
import ErrorOutput from "../../../../components/Output/ErrorOutput";
import "../../../../styles/modalface.css";

const OperationPage = () => {
  const { itemId } = useParams();
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemLastStatusId, setItemLastStatusId] = useState(null);
  const [operationType, setOperationType] = useState(null);

  useEffect(() => {
    setError(null);
    const fetchData = async () => {
      try {
        const response = await getItemLastStatus(itemId);
        setItemLastStatusId(response.data.id);
        switch (response.data.id) {
          case config.fixedIds.status.storage:
            setOperationType("withdraw");
            break;
          case config.fixedIds.status.mustTest:
            setOperationType("withdraw");
            break;
          case config.fixedIds.status.mustMaintenence:
            setOperationType("withdraw");
            break;
          case config.fixedIds.status.usage:
            setOperationType("deposit");
            break;
          case config.fixedIds.status.test:
            setOperationType("deposit");
            break;
          case config.fixedIds.status.maintenence:
            setOperationType("deposit");
            break;
          default:
            setOperationType("none");
            break;
        }
      } catch (error) {
        setError(error.message);
        console.error(error.message);
      }
    };
    fetchData();
  }, [itemId]);

  const onClick = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
  };

  return (
    <>
      <div className={`wr-home ${isModalOpen ? "fade-out" : ""}`}>
        <Head />
        <Navbar />

        <div
          className={`container fill-height ${isModalOpen ? "fade-out" : ""}`}
        >
          <div className="m-5 text-center">
            {operationType === "withdraw" && <h1> Retirada </h1>}
            {operationType === "deposit" && <h1> Devolução </h1>}
            {operationType === "none" && (
              <h1> Item Fora da Cobertura de Operações </h1>
            )}
          </div>

          <div className="mt-3 mb-3 rounded bg-light p-4">
            <h2>Sobre o Item</h2>
            <div className="mb-3">
              <p>Certifique-se de que esta é a peça que você possui em mãos.</p>
            </div>
            <div className="mb-3">
              <ItemCardDetailed itemId={itemId} />
            </div>
          </div>

          <div className="mt-3 mb-3 rounded bg-light p-4">
            <h2>Ultimos Estados</h2>
            <div className="mb-3">
              <p>Descrição básica das últimas troca de estado deste item.</p>
            </div>
            <div className="mb-3">
              <ItemStatusCard itemId={itemId} nRecords={5} />
            </div>
          </div>

          <div className="m-0 mt-4 mb-4 rounded p-4 row">
            {operationType === "withdraw" && (
              <button onClick={onClick} className="mb-3 btn btn-primary btn-lg">
                {" "}
                Retirar Produto{" "}
              </button>
            )}
            {operationType === "deposit" && (
              <button onClick={onClick} className="mb-3 btn btn-primary btn-lg">
                {" "}
                Devolver Produto{" "}
              </button>
            )}
            {operationType === "none" && (
              <button disabled={true} className="mb-3 btn btn-primary btn-lg">
                {" "}
                Retirar Produto{" "}
              </button>
            )}
          </div>

          <ErrorOutput error={error} />
        </div>

        <Footer />
      </div>
      {operationType === "withdraw" && (
        <WithdrawModal
          itemId={itemId}
          lasStatusId={itemLastStatusId}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
      {operationType === "deposit" && (
        <DepositModal
          itemId={itemId}
          lasStatusId={itemLastStatusId}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default OperationPage;
