import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchChildItems = async (childId, input) => {
  try {
    let query = `{
      child(id: "${childId}") {
        Part {
            Items`;
    
    if (input) {
      query += `(where: { id: { like: "%${input}%" } })`;
    }
    
    query += `{
              id
              description
            }
          }
        }
      }`;
        
    let response = await fetchGraphQLQuery(query);
    if (response && response.data && response.data.child[0] && response.data.child[0].Part && response.data.child[0].Part.Items) {
      response = {...response, data:{Items: response.data.child[0].Part.Items}}
    }
    return response;
  } catch(error) {
    console.error('Error fetching part items:', error);
  }
};

export default fetchChildItems;
