import { useState } from "react";
import SelectionBox from "../SelectionBox/SelectionBox";
import TypeHeadMultiSelection from "../TypeHeadMultiSelection/TypeHeadMultiSelection";
import TypeHead from "../TypeHead/TypeHead";
import testRequired from "./utils/testRequired";
import UuidText from "../Text/UuidText";

const PartForm = ({ onSubmit }) => {
  const [resetForm, setResetForm] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    nickname: "",
    description: "",
    date: "",
    version: "",
    unitId: "",
    typeId: "",
    supplyId: "",
    peopleId: "",
    star: [],
    constellation: [],
    grouping: [],
    documents: [],
  });

  const clearForm = () => {
    setFormData({
      id: "",
      name: "",
      nickname: "",
      description: "",
      date: "",
      version: "",
      unitId: "",
      typeId: "",
      supplyId: "",
      peopleId: "",
      star: [],
      constellation: [],
      grouping: [],
      documents: [],
    });
    setResetForm(!resetForm);
  };

  const onChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const innerOnSubmit = (e) => {
    e.preventDefault();
    if (
      testRequired(formData, [
        "nickname",
        "date",
        "version",
        "peopleId",
        "star",
        "constellation",
        "grouping",
        "documents",
      ])
    ) {
      alert("Por favor, preencha todos os campos obrigatórios.");
      return;
    }
    onSubmit(formData);
    clearForm();
  };

  return (
    <form onSubmit={innerOnSubmit}>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2 className="mb-4">Sobre a Peça</h2>
        <div className="mb-3">
          <strong>Intruções:</strong>
          <p>
            Esta página tem objetivo de possibilitar a inserção de novos
            projetos, peças ou sistemas ao banco de dados. Apelido, data de
            Publicação do Projeto, Versionamento, Responsável, posição no
            organograma do grupo e documentos relacionados não são campos
            obrigatórios.
          </p>
        </div>
        <div className="mb-3">
          <UuidText
            id={"id"}
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Nome:
          </label>
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder="Nome da peça, lembre-se de ser descritivo e preciso evitando nomes como 'chapa lateral'."
            value={formData.name}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="nickname" className="form-label">
            Apelido:
          </label>
          <input
            type="text"
            className="form-control"
            name="nickname"
            placeholder="Forma informal com a qual os membros se referem a peça."
            value={formData.nickname}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Descrição:
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            placeholder="Descreva os atributos de sua peça de forma que qualquer um possa identifica-la."
            value={formData.description}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="date" className="form-label">
            Data de Publicação:
          </label>
          <input
            type="date"
            className="form-control"
            id="date"
            name="date"
            value={formData.date}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="version" className="form-label">
            Versão:
          </label>
          <input
            type="text"
            className="form-control"
            id="version"
            name="version"
            placeholder="Versionamento da peça i.e '1.2.1'."
            value={formData.version}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="version" className="form-label">
            Unidade:
          </label>
          <SelectionBox
            name="unitId"
            label="Unidade:"
            table="unit"
            labelColumn="name"
            valueColumn="id"
            auxiliarColumn="symbol"
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="typeId" className="form-label">
            Tipo:
          </label>
          <TypeHead
            name="typeId"
            label="Tipo:"
            placeholder="Categoria e sub-categoria em que se encaixa esta peça."
            table="type"
            labelColumn="type"
            auxiliarColumn="subType"
            valueColumn="id"
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="supplyId" className="form-label">
            Fornecimento:
          </label>
          <SelectionBox
            name="supplyId"
            label="Fornecimento:"
            table="supply"
            labelColumn="supply"
            valueColumn="id"
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="version" className="form-label">
            Responsável:
          </label>
          <TypeHead
            name="peopleId"
            idSize={0}
            placeholder="Responsável pelo projeto."
            table="person"
            labelColumn="firstName"
            valueColumn="id"
            auxiliarColumn="lastName"
            separator=" "
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="grouping" className="form-label">
            Agrupamento de Constelações:
          </label>
          <TypeHeadMultiSelection
            name="grouping"
            placeholder="Agrupamentos de constelação os quais pertence o projeto."
            table="grouping"
            labelColumn="name"
            valueColumn="id"
            columns={["UUID", "Nome"]}
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="constellation" className="form-label">
            Constelações:
          </label>
          <TypeHeadMultiSelection
            name="constellation"
            placeholder="Constelações as quais pertence o projeto."
            table="constellation"
            labelColumn="name"
            valueColumn="id"
            columns={["UUID", "Nome"]}
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="star" className="form-label">
            Estrelas:
          </label>
          <TypeHeadMultiSelection
            name="star"
            placeholder="Estrelas as quais pertence o projeto."
            table="star"
            labelColumn="name"
            valueColumn="id"
            columns={["UUID", "Nome"]}
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="documents" className="form-label">
            Documentos Relacionados:
          </label>
          <TypeHeadMultiSelection
            name="documents"
            placeholder="Documentos relacionados a peça."
            table="document"
            labelColumn="title"
            auxiliarColumn="version"
            valueColumn="id"
            columns={["UUID", "Título", "Versão"]}
            onChange={(e) => {
              onChange(e.id, e.value);
            }}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mt-5 row">
          <button type="submit" className="btn btn-primary btn-lg">
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
};

export default PartForm;
