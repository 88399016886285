import fetchGraphQLQuery from "../fetchGraphQLQuery";

const fetchPartBomIds = async (partId) => {
  try {
    if (!partId) {
      return null;
    }
    const query = `{
      part (id: "${partId}") {
        Parents {
          Bom {
            id
          }
        }
      }
    }`;
    const response = await fetchGraphQLQuery(query);
    const bomIds = response.data.part[0].Parents.map((parent) => parent.Bom.id);
    return bomIds;
  } catch (error) {
    console.error("Error fetching bom data:", error);
  }
};

export default fetchPartBomIds;
