import { useState } from "react";
import UuidTextQrCode from "../Text/UuidTextQrCode";

const CreateOutputModal = ({ showModal, handleClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    id: "",
    description: "",
  });

  const innerOnSubmit = async () => {
    onSubmit(formData);
  };

  const onChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  return (
    <div>
      {showModal && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog" role="document" style={{ maxWidth: "80vw" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">Gerando Item de Saída</h2>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="mt-3 mb-3 rounded p-4 col-10">
                  <div className="mb-3">
                    <UuidTextQrCode
                      id="id"
                      onChange={(e) => onChange(e.id, e.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Descrição:
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      placeholder="Descreva os atributos que sejam específicos deste item."
                      value={formData.description}
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={innerOnSubmit}
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateOutputModal;
