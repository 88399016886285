import React, { useState } from "react";
import UserTypeHead from "../TypeHead/UserTypeHead";
import testRequired from "./utils/testRequired";
import BomElementSelector from "../../PageElements/crud/bom/create/BomElementSelector";

const PurchaseForm = ({ onSubmit }) => {
  const [resetForm, setResetForm] = useState(false);
  const [formData, setFormData] = useState({
    personId: "",
    initDate: "",
    endDate: "",
  });

  const clearForm = () => {
    setFormData({
      personId: "",
      initDate: "",
      endDate: "",
    });
    setResetForm(!resetForm);
  };

  const onChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const innerOnSubmit = (e) => {
    e.preventDefault();
    let notRequired = [];
    if (formData.createEmptyBom) {
      notRequired = [];
    }
    if (testRequired(formData, notRequired)) {
      alert("Por favor, preencha todos os campos.");
      return;
    }
    onSubmit(formData);
    clearForm();
  };

  return (
    <form onSubmit={innerOnSubmit}>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2>Sobre as Compras</h2>
        <div className="mb-3">
          <strong>Intruções:</strong>
          <p>
            Compra são tratadas como eventos quaisquer do banco de dados. Dessa
            forma, será criada uma BOM utilizando o processo de compra pré
            definido, com esta BOM será criado um evento ja finalizado no qual
            as saídas são a sua lista de compras.
          </p>
          <p>
            Esse processo ir determinar um ID para todos os items comprados,
            portanto, caso queira rastrear ou etiquetar algum item procure no
            evento criado os UUID gerados para cada um. Você será redirecionado
            à página do evento caso queira notar quaisquer valores.
          </p>
          <p>
            Todos os items também terão sua primeira entrada na tabela de
            histórico adicionada, apresentando-se como disponíveis para uso.
          </p>
        </div>

        <div className="mb-3">
          <label className="form-label">Responsável: </label>
          <UserTypeHead
            typeHeadOptions={{
              id: "personId",
              placeholder: "Responsável pela Compra",
            }}
            changeToClearSelection={resetForm}
            onChange={(e) => onChange(e.id, e.value)}
          />
        </div>
        <div className="mb-3 row ">
          <div className="col-md-2 mx-0">
            <label>Data do Pedido: </label>
          </div>
          <div className="col-md-3 mx-0">
            <input
              type="datetime-local"
              className="form-control"
              id="date"
              name="initDate"
              value={formData.initDate}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </div>
          <div className="col-md-2" />
          <div className="col-md-2 mx-0">
            <label>Data da Efetuação: </label>
          </div>
          <div className="col-md-3 mx-0">
            <input
              type="datetime-local"
              className="form-control"
              id="date"
              name="endDate"
              value={formData.endDate}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </div>
        </div>
        <div className="mb-3">
          <label>List de Compras: </label>
        </div>
        <BomElementSelector
          name={"items"}
          placeholder={"Adicione as partes e edite as quantidades"}
          onChange={(e) => onChange(e.id, e.value)}
          changeToClearSelection={resetForm}
        />
        <div className="mt-5 row">
          <button type="submit" className="mb-3 btn btn-primary btn-lg">
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
};

export default PurchaseForm;
