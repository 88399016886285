import { client } from './client';
import { gql } from '@apollo/client';

export const fetchGraphQLQuery = async (query, variables = {}) => {

  // convert query to gql instace if its not already in this format
  if (!query) {
    return { data: null, errors: [{message: "Cant fetch empty query"}] };
  }

  let gqlQuery;
  try {
    if (!(query instanceof Object) || !('kind' in query && query.kind === 'Document')) {
      gqlQuery = gql`${query}`;
    } else {
      gqlQuery = query;
    }
  } catch (error) {
    return { data: null, errors: [error] };
  }
  
  // make the qrequest
  try {
    const response = await client.query({
      query: gqlQuery,
      variables,
    });
    return response;
  } catch (error) {
    return { data: null, errors: [error] };
  }
};

export default fetchGraphQLQuery