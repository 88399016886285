import React, { useState } from "react";
import Head from "../../../components/Header";
import Navbar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import QueryForm from "../../../components/Input/Forms/QueryForms";
import QueryOutput from "../../../components/Output/QueryOutput";
import ProtectedPage from "../../../components/PageElements/directAcess/ProtectedPage";

const QueryPage = () => {
  const [queryResult, setQueryResult] = useState(null);

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="m-5 text-center">
          <h1>Página de Acesso Direto</h1>
        </div>
        <div className="mt-3 mb-3 rounded bg-light p-4">
          <h2>Queries Diretas</h2>
          <QueryForm onSubmit={setQueryResult} />
          <QueryOutput
            queryResult={queryResult}
            containerTitles={[]}
            MajorContainerTitle={"Resultado"}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

// Wrap MyProtectedPage with withPasswordProtection to protect it.
const ProtectedMyProtectedPage = ProtectedPage(QueryPage);

export default ProtectedMyProtectedPage;