import React from 'react';
import Head from '../../components/Header';
import Navbar from '../../components/NavBar';
import Footer from '../../components/Footer';
import '../../styles/tbd.css';

const ToBeDevelopedPage = () => {
  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div style={{ height: '70vh' }} className="container fill-height">
        <div className="h-75"></div>
        <div className="text-center">
          <div className="feature-coming-soon-content">
            <h2>Recurso em Desenvolvimento</h2>
            <p>Estamos trabalhando para trazer este recurso para você. Por favor, verifique novamente mais tarde para atualizações.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ToBeDevelopedPage;
