import config from '../../config/config';
import fetchController from '../fetchController';

// Function to fetch data from GraphQL API
async function getPartItemsAvaiableForInput(partId, input) {
  try {
    // initialize variables
    const variables = {partId: partId, like: input || null}

    // Make a GET request
    const response = await fetchController(variables, config.graphQLServerHost, config.getPartItemsAvaiableForInputUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller getPartItemsAvaiableForInput:', error);
  }
}

export default getPartItemsAvaiableForInput;
