import { useState } from "react";
// import SelectionBox from "../SelectionBox/SelectionBox";
// import TypeHeadMultiSelection from "../TypeHeadMultiSelection/TypeHeadMultiSelection";
// import TypeHead from "../TypeHead/TypeHead";
import testRequired from "./utils/testRequired";
import UuidTextQrCode from "../Text/UuidTextQrCode";

const PersonForm = ({ onSubmit }) => {
  const [resetForm, setResetForm] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: ""
  });

  const clearForm = () => {
    setFormData({
      id: "",
      firstName: "",
      lastName: "",
      email: ""
    });
    setResetForm(!resetForm);
  };

  const onChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const innerOnSubmit = (e) => {
    e.preventDefault();
    if (
      testRequired(formData)
    ) {
      alert("Por favor, preencha todos os campos.");
      return;
    }
    onSubmit(formData);
    clearForm();
  };

  return (
    <form onSubmit={innerOnSubmit}>
      <div className="mt-3 mb-3 rounded bg-light p-4">
        <h2 className="mb-4">Sobre a Pessoa</h2>
        <div className="mb-3">
          <strong>Intruções:</strong>
          <p>
            Esta página tem objetivo de possibilitar a inserção de novos membros ao banco de dados. Insira seu nome e último nome e email (do warthog).
          </p>
        </div>
        <div className="mb-3">
          <UuidTextQrCode
            id={"id"}
            onChange={(e) => onChange(e.id, e.value)}
            changeToClearSelection={resetForm}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">
            Primeiro Nome:
          </label>
          <input
            type="text"
            className="form-control"
            name="firstName"
            placeholder="Seu primeiro nome"
            value={formData.firstName}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="lastName" className="form-label">
            Sobrenome:
          </label>
          <input
            type="text"
            className="form-control"
            name="lastName"
            placeholder="Seu sobrenome"
            value={formData.lastName}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email:
          </label>
          <input
            type="text"
            className="form-control"
            name="email"
            placeholder="Seu email institucional do grupo, 'nome.sobrenome@wr.sc.usp.br'"
            value={formData.email}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
          />
        </div>
        <div className="mt-5 row">
          <button type="submit" className="btn btn-primary btn-lg">
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
};

export default PersonForm;
