import config from '../../../config/config';
import postController from '../../postController';

// Function to fetch data from GraphQL API
async function updateEventInput(updateEventInputVariables) {
  try {
    // initialize variables
    const variables = updateEventInputVariables

    // Make a post request
    const response = await postController(variables, config.graphQLServerHost, config.updateEventInputUrl);
    return response;
  } catch (error) {
    console.error('Error fetching data from controller updateEventInputVariables:', error.message);
    throw(error)
  }
} 

export default updateEventInput;
