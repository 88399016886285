import { useNavigate } from "react-router-dom";
import Head from "../../../../components/Header";
import Navbar from "../../../../components/NavBar";
import Footer from "../../../../components/Footer";
import ItemsFromPartIdTypeHeadQrCode from "../../../../components/Input/TypeHeadQRCode/ItemsFromPartIdTypeHeadQrCode";
import TypeHead from "../../../../components/Input/TypeHead/TypeHead";
import { useState } from "react";

const SelectItemOperationPage = () => {
  const [partId, setPartId] = useState(null);
  const navigate = useNavigate();

  return (
    <>
      <Head />
      <Navbar />
      <div className="wr-home container-fluid d-flex align-items-center justify-content-center">
        <div className="mt-4">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card w-100">
                <div className="text-center card-body">
                  <h1 className="mb-4">
                    {" "}
                    Selecione o UUID do seu Item , ou utilize o QRCode{" "}
                  </h1>
                  <label className="form-label"> Filtre por Peça: </label>
                  <TypeHead
                    name="partId"
                    table="part"
                    placeholder={"Filtre os items pela peça"}
                    labelColumn="name"
                    valueColumn="id"
                    auxiliarColumn="description"
                    onChange={(e) => {
                      setPartId(e.value);
                    }}
                  />
                  <div className="m-3"></div>
                  <ItemsFromPartIdTypeHeadQrCode
                    typeHeadOptions={{
                      id: "qrCodeSelection",
                      placeholder: "UUID do item que utilizará em sua montagem",
                    }}
                    fetchOptionsArguments={{ partId: partId }}
                    changeToClearSelection={{}}
                    onChange={(e) => {
                      if (e && e.value !== "" && e.value !== null) {
                        navigate(
                          `/usage/operation/${e.value}`
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SelectItemOperationPage;
