import { useState, useEffect } from "react";
import getBomStructure from "../../../../../utils/graphql/controllers/getBomStructure";
import { Card } from "react-bootstrap";

const LinkableBom = ({ bomId, onSelect }) => {
  const [bom, setBom] = useState(null);
  const [clicked, setClicked] = useState(null);
  const [hovered, setHovered] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getBomStructure(bomId);
      setBom(response.data);
    };
    fetchData();
  }, [bomId]);

  const handleClick = (part) => {
    setClicked(part);
    onSelect(part.partId);
  };

  const handleHover = (part) => {
    setHovered(part);
  };
  return (
    <>
      <Card className="m-3">
        <Card.Body>
          <Card.Title>Informações Básicas</Card.Title>
          <Card.Text>
            <strong>ID:</strong> {bom?.bom?.id}
            <br />
            <strong>Nome Do Processo:</strong> {bom?.process?.name}
            <br />
            <strong>Descrição do Processo:</strong> {bom?.process?.description}
            <br />
          </Card.Text>
        </Card.Body>
      </Card>

      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h5 className="mb-0">Lista de Saídas</h5>
          </div>
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th>Fornecimento</th>
                  <th>Unidade</th>
                  <th>Quantidade</th>
                </tr>
              </thead>
              <tbody>
                {bom?.parents.map((parent, index) => (
                  <tr
                    key={index}
                    className={
                      (clicked === parent ? "table-success" : "") +
                      (hovered === parent ? " table-info" : "")
                    }
                    onClick={() => handleClick(parent)}
                    onMouseEnter={() => handleHover(parent)}
                    onMouseLeave={() => setHovered(null)}
                  >
                    <td>{parent.id}</td>
                    <td>{parent.name}</td>
                    <td>{parent.description}</td>
                    <td>{parent.Supply.supply}</td>
                    <td>{parent.Unit.symbol}</td>
                    <td>{parent.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h5 className="mb-0">Lista de Entradas</h5>
          </div>
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th>Fornecimento</th>
                  <th>Unidade</th>
                  <th>Quantidade</th>
                </tr>
              </thead>
              <tbody>
                {bom?.children.map((child, index) => (
                  <tr
                    key={index}
                    className={
                      (clicked === child ? "table-success" : "") +
                      (hovered === child ? " table-info" : "")
                    }
                    onClick={() => handleClick(child)}
                    onMouseEnter={() => handleHover(child)}
                    onMouseLeave={() => setHovered(null)}
                  >
                    <td>{child.id}</td>
                    <td>{child.name}</td>
                    <td>{child.description}</td>
                    <td>{child.Supply.supply}</td>
                    <td>{child.Unit.symbol}</td>
                    <td>{child.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkableBom;
