import { useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CameraModalScanner from "./CameraModalScanner";

const CameraModal = ({ id, showModal, onHide, title, onError, onScan }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const innerOnScan = (result) => {
      onScan({ ...result, id: id, value: result?.data });
    };

    const innerOnError = (error) => {
      if (error.toUpperCase().includes("NO QR CODE FOUND")) return;
      onError(error);
    };

    showModal
      ? CameraModalScanner.startScanner(videoRef, innerOnScan, innerOnError)
      : CameraModalScanner.stopScanner();
  }, [id, onScan, onError, showModal]);

  return (
    <Modal show={showModal} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <video ref={videoRef} style={{ width: "100%" }}></video>
      </Modal.Body>
    </Modal>
  );
};

export default CameraModal;
