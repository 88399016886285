import React, { useState, useEffect } from 'react';
import fetchItemDetails from '../../../utils/graphql/fetches/fetchItemDetails'
import QueryOutput from '../QueryOutput'

const ItemInfo = ({ itemId }) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchItemDetails(itemId);
      setResult(response);
    };
    fetchData();
  }, [itemId]);


  if (!itemId) {
    return (<></>);
  }

  return (
    <>
      <QueryOutput
        queryResult={result}
        containerTitles={[]}
        MajorContainerTitle={"Informações do Item e seu Projeto"}
      />
    </>
  );
};

export default ItemInfo;