import { useState, useEffect } from "react";
import fetchInputDetails from "../../../../../../utils/graphql/fetches/fetchInputDetails";

const ManufacturingBodyExecutionElement = ({ inputId, onChange }) => {
  const [input, setInput] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);
  const [prevInputId, setPrevInputId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchInputDetails(inputId);
        setInput(response.data.input[0]);
        if (response.data.input[0].Item.Part.Unit.countable) {
          setDefaultValue("consume");
          onChange({ target: { inputId: inputId, value: "consume" } });
        } else {
          setDefaultValue("assembly");
          onChange({ target: { inputId: inputId, value: "assembly" } });
        }
      } catch (error) {
        console.error("Error fetching input data:", error);
      }
    };
    if (inputId !== null && inputId !== undefined && prevInputId !== inputId) {
      setPrevInputId(inputId);
      fetchData();
    }
  }, [onChange, inputId, prevInputId]);

  if (!input) {
    return null;
  }

  const handleChange = (e) => {
    e.target.inputId = inputId;
    onChange(e);
  };

  return (
    <div>
      <div className="row">
        <div className="card col-8">
          <div className="card-body">
            <h5 className="card-title">
              {input.Item.Part.name} - Número {input.quantityIndex}
            </h5>
            <p className="card-text">
              <strong>ID:</strong> {input.Item.id} <br />
            </p>
          </div>
        </div>

        <div className="col-4">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name={inputId}
              id={inputId + "option1"}
              value="assembly"
              defaultChecked={defaultValue === "assembly"}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor={inputId + "option1"}>
              Montagem
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name={inputId}
              id={inputId + "option2"}
              value="consume"
              defaultChecked={defaultValue === "consume"}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor={inputId + "option2"}>
              Consumir
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name={inputId}
              id={inputId + "option3"}
              value="storage"
              defaultChecked={defaultValue === "storage"}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor={inputId + "option3"}>
              Estoque
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManufacturingBodyExecutionElement;
