import { useState, useEffect } from "react";
import fetchDocumentDetails from "../../../utils/graphql/fetches/fetchDocumentDetails";

const EventCard = ({ documentId }) => {
  const [document, setDocument] = useState(null);
  const [authorsString, setAuthorsString] = useState([]);
  const [processesString, setProcessesString] = useState([]);
  const [partsString, setPartsString] = useState([]);
  const [documentUrl, setDocumentUrl] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDocumentDetails(documentId);
        setDocument(response.data.document[0]);
        if (response.data.document[0].url) {
          let link = response.data.document[0].url;
          if (!link.startsWith("http://") && !link.startsWith("https://")) {
            link = "http://" + link;
          }
          setDocumentUrl(link);
        }

        let parts = "";
        for (const part of response.data.document[0].Parts) {
          parts = parts + part.name + ", ";
        }
        setPartsString(parts.slice(0, -2) + ".");

        let authors = "";
        for (const author of response.data.document[0].People) {
          authors = authors + author.firstName + " " + author.lastName + ", ";
        }
        setAuthorsString(authors.slice(0, -2) + ".");

        let processes = "";
        for (const process of response.data.document[0].Processes) {
          processes = processes + process.name + ", ";
        }
        setProcessesString(processes.slice(0, -2) + ".");
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };
    fetchData();
  }, [documentId]);

  if (!document) {
    return <div>Documento Não Encontrado</div>;
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{document.title}</h5>
        <p className="card-text">
          <strong>Descrição:</strong> {document.description} <br />
          <strong>Link de Acesso:</strong>{" "}
          <a href={documentUrl}>{documentUrl}</a> <br />
          <strong>Autores:</strong> {authorsString} <br />
          <strong>Peças Relacionadas:</strong> {processesString} <br />
          <strong>Processos Relacionados:</strong> {partsString} <br />
        </p>
      </div>
    </div>
  );
};

export default EventCard;
