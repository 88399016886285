import createUnit from './crud/unit/create';
import createPart from './crud/part/create';
import createItem from './crud/item/create';

const importJson = async (tableName, jsonData) => {
  switch (tableName) {
    case 'unit':
      await callMutation(createUnit, jsonData);
      break;
    case 'part':
      await callMutation(createPart, jsonData);
      break;
    case 'createItem':
      await callMutation(createItem, jsonData);
      break;
    default:
      console.error('Table not found or not supported.');
      break;
  }
};

const callMutation = async (mutation, jsonData) => {
  for (const input of jsonData) {
    try {
      const result = await mutation(input);
      console.log("Mutation Called:", result);
    } catch (error) {
      console.error(error.message || 'An error occurred');
    }
  }
};

export default importJson;
