import React from 'react';
import GraphQLTable from'../Table/GraphQLTable'

const ParentsTable = ({ parentsData, title = 'Utilizado Em'}) => {
  const parentsTable = [];
  const columnsToRemove = [
    '__typename',
    'createdAt',
    'updatedAt',
    'deletedAt',
    'unitId',
    'typeId',
    'peopleId',
    'TypeId',
    'Unitid',
    'Unitname',
    'unit',
    'date',
    'supplyId',
    'quantity',
    'Typeid',
    'category',
    'type',
    'subType',
    'Unitcountable'
  ]

  const columnsOrder = [
    'bomId',
    'id',
    'name',
    'description',
    'version',
    'Unitsymbol',
    'outputQuantity'
  ]

  parentsData.forEach(parent => {
    const parentsRow = { ...parent['part'] };
    parentsRow['bomId'] = parent['parent']['bomId'];
    parentsRow['outputQuantity'] = parent['parent']['quantity'];
    parentsTable.push(parentsRow);
  });
  
  return (
  <div className="p-3 mb-4 rounded bg-light">
    <h2>{title}</h2>
    <GraphQLTable graphQLSingleTableData={parentsTable} columnsToRemove={columnsToRemove} columnsOrder={columnsOrder}/>
  </div>
  );
};

export default ParentsTable;
