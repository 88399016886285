// api settings
const config = {
  graphQLEndpoint: 'https://dev.api.pakita.wr.sc.usp.br:443/graphql',
  graphQLServerHost: 'https://dev.api.pakita.wr.sc.usp.br:443',
  getPartStarmapUrl: '/getPartStarmap/',
  getItemLastStatusUrl: '/getItemLastStatus/',
  getItemLastAssemblyUrl: '/getItemLastAssembly/',
  getItemHistoryUrl: '/getItemHistory/', 
  getItemLastUsageInAssemblyUrl: '/getItemLastUsageInAssembly/',
  getPartItemsAvaiableForInputUrl: '/getPartItemsAvaiableForInput/',
  getPartItemsAvaiableForOutputUrl: '/getPartItemsAvaiableForOutput/',
  getPartBomsUrl: '/getPartBomsStructures/',
  getBomStructureUrl: '/getBomStructure/',
  getBomChildrenUrl: '/getBomChildren/',
  getNextValidStatusUrl: '/getNextValidStatus/',
  getEventChildrenForManufacturingBodyUrl: '/getEventChildrenForManufacturingBody/',
  getEventParentsForManufacturingBodyUrl: '/getEventParentsForManufacturingBody/',
  getCurrentInputForManufacturingBodyUrl: '/getCurrentInputForManufacturingBody/',
  getCurrentOutputForManufacturingBodyUrl: '/getCurrentOutputForManufacturingBody/',
  getPartItemInStorageUrl: '/getPartItemInStorage/',
  getPartParentsUrl: '/getPartParents/',
  getRobotsStatusUrl: '/getRobotsStatus/',
  createPartUrl: '/createPart/',
  createItemUrl: '/createItem/',
  createItemForManufacturingUrl: '/createItemForManufacturing/',
  createItemForInputUrl: '/createInputItem/',
  createOutputItemUrl: '/createOutputItem/',
  createEventUrl: '/createEvent/',
  createPurchaseUrl: '/createPurchase/',
  deleteInputUrl: '/deleteInput/',
  deleteOutputUrl: '/deleteOutput/',
  generateInputItemUrl: '/generateInputItem/',
  updateEventInputUrl:'/updateEventInput/',
  updateEventOutputUrl: '/updateEventOutput/',
  createEventWithOutputUrl: '/createEventWithOutput/',
  createDocumentUrl: '/createDocument/',
  backupDataUrl: '/backupData/',
  initiateEventUrl: '/initiateEvent/',
  endEventUrl: '/endEvent/',
  createBomUrl: '/createBom/',
  createUnitUrl: '/createUnit/',
  createPersonUrl: '/createPerson/',
  createProcessUrl: '/createProcess/',
  withdrawProductUrl: '/withdrawProduct/',
  depositProductUrl: '/depositProduct/',
  version: process.env.APP_VERSION || null,
  env: process.env.NODE_ENV || 'development',
  port: parseInt(process.env.API_PORT) || 3000,
  fixedIds: {
    status: {
      usage: '7c29ba30-d033-4f6b-bee1-77c25efaba42',
      test: 'a60938d5-5744-4b82-b355-83fbb2080bfc',
      mustTest: '62305487-42a7-4545-a567-0c51b432b0f1',
      maintenence: '2114b25d-4aee-4f3a-b1fb-e682006d576f',
      mustMaintenence: 'b2999ba6-08e5-4340-9f54-585ef5866bf5',
      mustManufacture: '5dba7f93-05e5-4559-98f0-115bef692965',
      manufacturing: '2ed25afc-67c4-47f3-a38c-024bfffcb0d0',
      storage: '03b5ab4c-48f9-4e0d-bc22-647b673b28d3',
      assembly: '45bd1156-a600-47b0-8b98-c956d185398a',
      consumed: '9a66e777-2422-4188-904a-04bb4149a13f',
    },
    state: {
      idle: 'b7d104e6-dae3-44f0-b7a7-052d22091e50',
      finalized: 'd4fec5f1-8e5f-45a4-ad1f-da3cf8cdf151',
      executing: 'ffe7a74d-bccf-4174-bc81-d0249fe5fdff',
    },
  },
  displayHeaders: {
    id: 'ID',
    Id: 'ID',
    name: 'Nome',
    nickname: 'Apelido',
    description: 'Descrição',
    date: 'Data',
    typecategory: 'Categoria do Tipo',
    category: 'Categoria',
    typetype: 'Sub-Categoria do Tipo',
    unitsymbol: 'Símbolo da Unidade',
    version: 'Versão',
    typeId: 'ID do Tipo',
    unitId: 'ID da Unidade',
    type: 'Tipo',
    unit: 'Unidade',
    bomId: 'ID da BOM',
    star: 'Estrela',
    constellation: 'Constelação',
    grouping: 'Agrupamento de Constelações',
    authorfirstName: 'Nome do Autor',
    authorlastName:'Sobrenome do Autor',
    authoremail: 'Email do Autor',
    title: 'Título',
    url: 'Link de Acesso',
    processId: 'ID do Processo',
    partId: 'ID da peça',
    partid: 'ID da peça',
    partname: 'Nome da Peça',
    partdescription: 'Descrição da Peça',
    parttypecategory: 'Caterogia da Pela',
    parttypetype: 'Tipo da Peça',
    parttypesubType: 'Sub Tipo da Peça',
    partunitsymbol: 'Unidade',
    peopleId: 'ID do Autor',
    quantity: 'Qtd.',
    outputQuantity: 'Qtd.',
    comment: 'Comentário',
    statusname: 'Estado',
    Statusname: 'Estado',
    personemail: 'Email',
    Personemail: 'Email',
    supplysupply: 'Fornecimento',
    Partid: 'ID da peça',
    Partname: 'Nome da Peça',
    Partdescription: 'Descrição da Peça',
    PartTypecategory: 'Caterogia da Pela',
    PartTypetype: 'Tipo da Peça',
    PartTypesubType: 'Sub Tipo da Peça',
    PartUnitsymbol: 'Unidade',
    Unitname: 'Unidade',
    Unitsymbol: 'Unidade',
    TypesubType: 'Sub-Tipo',
    Supplysupply: 'Fornecimento',
    processName: 'Nome do Processo',
    creatoremail: 'Email do Responsável',
    actoremail: 'Email do Executor',
    Statestate: 'Estado do Evento',
    initDate: 'Início',
    endDate: 'Fim',
    Partdate: 'Data do Projeto',
    Partversion: 'Versão',
    InputItemid: 'ID do Item Utilizado',
    InputItemdescription: 'Descrição do Item Utilizado',
    InputItemPartdate: 'Data do Projeto',
    InputItemPartversion: 'Versão da Peça',
    InputItemPartpeopleId: 'Autor do Projeto',
    OutputItemid: 'ID do Item Montado',
    OutputItemdescription:  'Descrição do Item Montado',
    OutputItemPartdate: 'Data do Projeto',
    OutputItemPartversion: 'Versão da Peça', 
    OutputItemPartpeopleId: 'Autor do Projeto', 
    BomProcessname: 'Processo da Bom',
    Typecategory: 'Categoria',
    Typetype: 'Tipo',
    Typesubtype: 'Subtipo',
    PersonfirstName: 'Primeiro Nome',
    PersonlastName: 'Sobrenome'
  }
};

module.exports = config;