import { useState } from "react";
import Head from "../../../components/Header";
import Navbar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import TypeHead from "../../../components/Input/TypeHead/TypeHead";
import ItemInfo from "../../../components/Output/ItemInfo/ItemInfo";
import ItemHistory from "../../../components/Output/ItemInfo/ItemHistory";
import ItemEvent from "../../../components/Output/ItemInfo/ItemEvent";
import ItemAssembly from "../../../components/Output/ItemInfo/ItemAssembly";
import ItemAssemblyUsage from "../../../components/Output/ItemInfo/ItemAssemblyUsage";
import ItemsFromPartIdTypeHeadQrCode from "../../../components/Input/TypeHeadQRCode/ItemsFromPartIdTypeHeadQrCode";

const PartsPage = () => {
  const [partId, setPartId] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [changeToClearSelection, setChangeToClearSelection] = useState(null);
  const [changeToClearSelectionFilter, setChangeToClearSelectionFilter] = useState(null);

  return (
    <div className="wr-home">
      <Head />
      <Navbar />
      <div className="container fill-height">
        <div className="m-5 text-center">
          <h1>Verificar Item</h1>
        </div>
        <div className="mt-3 mb-3 rounded bg-light p-4">
          <h3>Selecione seu Item</h3>
          <label className="form-label"> Filtre por Peça: </label>
          <TypeHead
            name="partId"
            table="part"
            placeholder={"Filtre os itens pela parte"}
            labelColumn="name"
            valueColumn="id"
            auxiliarColumn="description"
            changeToClearSelection={changeToClearSelectionFilter}
            onChange={(e) => {
              setPartId(e.value);
              setItemId("");
              setChangeToClearSelection(!changeToClearSelection);
            }}
          />
          <div className=" row m-2"></div>
          <ItemsFromPartIdTypeHeadQrCode
            typeHeadOptions = {{id: "itemId", placeholder: "UUID do item que utilizará em sua montagem"}}
            fetchOptionsArguments = {{partId: partId}}
            changeToClearSelection={changeToClearSelection}
            onChange = {(e) => {
              setItemId(e.value);
              setPartId("");
              setChangeToClearSelectionFilter(!changeToClearSelectionFilter);
            }}
          />
        </div>

        {itemId ? (
          <>
            <ItemInfo itemId={itemId} />
            <ItemHistory itemId={itemId} />
            <ItemEvent itemId={itemId} />
            <ItemAssembly itemId={itemId} />
            <ItemAssemblyUsage itemId={itemId} />
          </>
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default PartsPage;
