async function fetchController(variables, endpoint, url) {
  try {

    const queryParams = new URLSearchParams(variables).toString();
    const fullUrl = `${endpoint}${url}?${queryParams}`;

    const response = await fetch(fullUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    });

    if (!response.ok) {
      throw new Error(`Fetching ${endpoint}${url} failed with response status: ${response.status} and message: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
}

export default fetchController;
