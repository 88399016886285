import fetchGraphQLQuery from '../fetchGraphQLQuery'

const fetchPersonDetails = async (personId) => {
  try {
    const query = `{
        person(id: "${personId}") {
          id
          firstName
          lastName
          email
        }
      }`;
      
    const response = await fetchGraphQLQuery(query);
    return response;
  } catch(error) {
    console.error('Error fetching person details:', error);
  }
};

export default fetchPersonDetails;
