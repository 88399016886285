import fetchGraphQLQuery from "../../../utils/graphql/fetchGraphQLQuery";

async function getSelectionBoxOptions({
  table,
  labelColumn,
  valueColumn,
  auxiliarColumn,
}) {
  const query = `
    {
      ${table} {
        ${labelColumn}
        ${valueColumn}
        ${auxiliarColumn ? auxiliarColumn : ""}
      }
    }
  `;

  try {
    const options = await fetchGraphQLQuery(query);
    const optionsArray = options.data[table].map((option) => ({
      value: option[valueColumn],
      auxLabel: option[auxiliarColumn],
      label: option[labelColumn],
    }));

    return optionsArray;
  } catch (error) {
    console.error("Error receiving options for the selection box: ", error);
    throw error;
  }
}

export default getSelectionBoxOptions;
